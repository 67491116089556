import {MaterialType} from '@hconnect/common/types'
import {dataTestId} from '@hconnect/uikit'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'

import {LayoutSettings, PageLayout, SettingsHelmet} from '../../common/components'
import {SettingsSkeleton} from '../../common/components/skeletons'
import {useMaterialsQuery} from '../../materials/hooks'
import {useSearchParam, RouteName} from '../../routing'
import {useMaterialStorageListQuery} from '../hooks'

import {StorageList} from './list'
import {SelectedStorageProvider} from './SelectedStorageProvider'
import {StorageDetails} from './StorageDetails'

export const PageStorageSettings = () => {
  const {t} = useTranslation()
  const {data: materialStorage} = useMaterialStorageListQuery()
  const {data: materials} = useMaterialsQuery()

  const [selectedStorageMaterialType, setInitialStorageMaterialType] = useSearchParam(
    'materialType',
    false
  )

  useEffect(() => {
    if (selectedStorageMaterialType) return
    setInitialStorageMaterialType(MaterialType.Cement)
  }, [setInitialStorageMaterialType, selectedStorageMaterialType])

  if (!materialStorage || !materials || !selectedStorageMaterialType) {
    return <SettingsSkeleton numberOfDetailsCards={3} />
  }

  return (
    <PageLayout
      title={t('storagesSettings.storages')}
      {...dataTestId(`page_${RouteName.STORAGES_SETTINGS}`)}
    >
      <SettingsHelmet title={t('storagesSettings.pageTitle')} />
      <SelectedStorageProvider>
        <LayoutSettings list={<StorageList />} details={<StorageDetails />} />
      </SelectedStorageProvider>
    </PageLayout>
  )
}
