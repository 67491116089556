import {Feature} from '@hconnect/common/enums'

import {useLatestQuery} from './queries'

export const useSettingsFeature = (feature: Feature) => {
  const {data: latestData} = useLatestQuery()
  const availableFeatures = latestData?.available_features || []

  return availableFeatures.includes(feature)
}
