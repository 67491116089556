import {dataTestId} from '@hconnect/uikit'
import {CardTitle, LoadingButton} from '@hconnect/uikit/src/lib2'
import {Close} from '@mui/icons-material'
import {Dialog, Box, Button, DialogContent, Stack} from '@mui/material'
import {Moment} from 'moment-timezone'
import {useMemo} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {EditDialogNextOccurencesPreview} from './EditDialogNextOccurencesPreview'
import {
  StandardOperationTimeAddOrEditDialogContent,
  StandardOperationTimeAddOrEditDialogEditingProps
} from './StandardOperationTimeEditDialogContent'
import {StandardOperationTimeSchedulingConflictDialogContent} from './StandardOperationTimeSchedulingConflictDialogContent'

import {
  AssetStandardOperationTimeConflictResolutionAction,
  AssetStandardOperationTimeValidationData,
  NewAssetStandardOperationTimeData
} from '@settings/modules/assets/types'
import {
  StandartOperationTimeEnds,
  StandartOperationTimeRepetition
} from '@settings/modules/assets/types/standardOperationTimes'
import {useCurrentTime} from '@settings/modules/common/hooks/useCurrentTime'
import {useLatestData} from '@settings/modules/common/hooks/useLatestData'

export type StandardOperationTimeValidationError = {
  data: NewAssetStandardOperationTimeData
  validateResult: AssetStandardOperationTimeValidationData
}

type StandardOperationTimeEditDialogProps = {
  assetId: number
  isLoading: boolean
  validateError: StandardOperationTimeValidationError | undefined
  onClose: () => void
  onSubmit: (
    operationTime: NewAssetStandardOperationTimeData,
    conflictResolution: AssetStandardOperationTimeConflictResolutionAction
  ) => void
  onCancelConflictDialog: () => void
  onOverwriteConflicts: () => void
  onSkipConflicts: () => void
} & StandardOperationTimeAddOrEditDialogEditingProps

const getDefaultData = (time: Moment) =>
  ({
    calendarEvent: {
      start: time.clone().startOf('hour').add(1, 'hour'),
      end: time.clone().startOf('hour').add(2, 'hour'),
      recurrenceRule: {
        freq: StandartOperationTimeRepetition.DAILY,
        interval: 1,
        ends: StandartOperationTimeEnds.after,
        count: 1
      }
    },
    isFixed: true
  }) as Pick<NewAssetStandardOperationTimeData, 'calendarEvent' | 'isFixed'>

export const StandardOperationTimeAddOrEditDialog: React.FC<
  StandardOperationTimeEditDialogProps
> = ({
  isLoading,
  validateError,
  onClose,
  onSubmit,
  onCancelConflictDialog,
  onOverwriteConflicts,
  onSkipConflicts,
  ...dialogProps
}) => {
  const {t} = useTranslation()
  const {timezone_id} = useLatestData()

  const time = useCurrentTime({timezoneId: timezone_id})
  const defaultData = useMemo(() => getDefaultData(time), [time])

  const {handleSubmit, control, formState, trigger, watch} =
    useForm<NewAssetStandardOperationTimeData>({
      mode: 'all',
      shouldFocusError: false,
      defaultValues: dialogProps.type === 'edit' ? dialogProps.standardOperationTime : defaultData
    })

  const isFormValid = Object.values(formState.errors).flat().length === 0

  const submit = handleSubmit((data) => {
    if (!isFormValid) return

    onSubmit(data, AssetStandardOperationTimeConflictResolutionAction.Error)
  })

  return (
    <Dialog
      open={true}
      onClose={(event, reason) => {
        if (reason === 'backdropClick') {
          return
        }
        onClose()
      }}
      fullWidth
    >
      {validateError ? (
        <StandardOperationTimeSchedulingConflictDialogContent
          {...validateError}
          operationModes={dialogProps.operationModes}
          isLoading={isLoading}
          onCancel={onCancelConflictDialog}
          onOverwriteConflicts={onOverwriteConflicts}
          onSkipConflicts={onSkipConflicts}
        />
      ) : (
        <DialogContent>
          <CardTitle>
            {t(
              `assetsSettings.standardOperationTimes.${
                dialogProps.type === 'edit' ? 'editOperationTime' : 'addOperationTime'
              }`
            )}
          </CardTitle>
          <form {...dataTestId('standard_operation_times_form')}>
            <Stack spacing={2}>
              <StandardOperationTimeAddOrEditDialogContent
                control={control}
                watch={watch}
                trigger={trigger}
                {...dialogProps}
              />
              <Box sx={{minHeight: (theme) => theme.spacing(4)}}>
                <EditDialogNextOccurencesPreview isValid={formState.isValid} control={control} />
              </Box>
              <Box sx={{display: 'flex', mt: 2, justifyContent: 'flex-end'}}>
                <Button
                  color="primary"
                  variant="text"
                  {...dataTestId('standard_operation_times_dialog_cancel_button')}
                  sx={{
                    minWidth: (theme) => theme.spacing(15)
                  }}
                  onClick={onClose}
                  startIcon={<Close />}
                >
                  {t('common.cancel')}
                </Button>

                <LoadingButton
                  variant="contained"
                  {...dataTestId('standard_operation_times_dialog_main_action_button')}
                  disabled={!isFormValid || isLoading}
                  onClick={() => {
                    void submit()
                  }}
                  color="primary"
                  isLoading={isLoading}
                  sx={{ml: 1, minWidth: (theme) => theme.spacing(15)}}
                >
                  {t('common.save')}
                </LoadingButton>
              </Box>
            </Stack>
          </form>
        </DialogContent>
      )}
    </Dialog>
  )
}
