import {Feature} from '@hconnect/common/enums'
import React from 'react'
import {Outlet, Navigate} from 'react-router-dom'

import {useLatestQuery} from '../../common/hooks'
import {getUrl} from '../helpers'
import {ROUTES} from '../routes'

interface FeatureGuardedRouteProps {
  feature: Feature
  shouldBypassFeature?: boolean
  shouldInvert?: boolean
}

export const FeatureGuardedRoute: React.FC<FeatureGuardedRouteProps> = ({
  feature,
  shouldBypassFeature = false,
  shouldInvert = false
}) => {
  const {data: latest} = useLatestQuery()

  const isFeatureAvailable = latest?.available_features?.includes(feature)

  const shouldNavigate = isFeatureAvailable !== shouldInvert || shouldBypassFeature

  if (shouldNavigate) {
    return <Outlet />
  }

  return <Navigate to={getUrl(ROUTES.BASE.NOT_FOUND.path, {})} replace />
}
