import type {OperationMode} from '@hconnect/common/types'
import {roundTo15MinIntervalEnd} from '@hconnect/common/utils'
import {dataTestId} from '@hconnect/uikit'
import {DateTimePicker} from '@hconnect/uikit/src/lib2'
import {MenuItem, Stack, Box, Typography, TextField, Button} from '@mui/material'
import {LocalizationProvider} from '@mui/x-date-pickers'
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment'
import {DatePicker} from '@mui/x-date-pickers/DatePicker'
import moment, {Moment} from 'moment-timezone'
import React, {useCallback, useEffect, useMemo} from 'react'
import {Controller, UseFormReturn} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {
  AssetStandardOperationTimeData,
  NewAssetStandardOperationTimeData
} from '@settings/modules/assets/types'
import {
  StandartOperationTimeEnds,
  StandartOperationTimeRepetition
} from '@settings/modules/assets/types/standardOperationTimes'
import {getDayOfWeek} from '@settings/modules/assets/utils/getDayOfWeek'
import {NumberInput, Switch} from '@settings/modules/common/components'
import {useCurrentTime} from '@settings/modules/common/hooks/useCurrentTime'
import {useLatestData} from '@settings/modules/common/hooks/useLatestData'
import {minValidator, requiredValidator} from '@settings/modules/common/utils'

export type StandardOperationTimeAddOrEditDialogEditingProps = {
  operationModes: OperationMode[]
} & (
  | {
      type: 'add'
    }
  | {type: 'edit'; standardOperationTime: AssetStandardOperationTimeData}
)

export type StandardOperationTimeAddOrEditDialogContentProps =
  StandardOperationTimeAddOrEditDialogEditingProps &
    Pick<
      UseFormReturn<NewAssetStandardOperationTimeData, void, undefined>,
      'watch' | 'control' | 'trigger'
    >

export const StandardOperationTimeAddOrEditDialogContent: React.FC<
  StandardOperationTimeAddOrEditDialogContentProps
> = ({type, operationModes, trigger, watch, control}) => {
  const {t} = useTranslation()
  const {timezone_id: timezoneId} = useLatestData()

  const currentTime = useCurrentTime({timezoneId})
  const currentTimeRoundedToNearest15Min = useMemo(
    () => roundTo15MinIntervalEnd(currentTime).toISOString(),
    [currentTime]
  )

  const assetOperationModesIds = useMemo(
    () => operationModes.map((operationMode) => operationMode.id),
    [operationModes]
  )

  const validateStartEnd = useCallback(
    ({start, end}: {end: Moment; start: Moment}) => {
      return end.isAfter(start) ? undefined : t('common.validation.endAfterStart')
    },
    [t]
  )

  const freq = watch('calendarEvent.recurrenceRule.freq')
  const ends = watch('calendarEvent.recurrenceRule.ends')

  const start = watch('calendarEvent.start')
  const end = watch('calendarEvent.end')

  useEffect(() => {
    void trigger()
  }, [start, end, trigger])

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Box>
        <Stack direction="column" spacing={2}>
          <Controller
            name="operationModeId"
            control={control}
            rules={requiredValidator(t)}
            render={({field: {ref, value, onChange}, fieldState: {error}}) => (
              <TextField
                select
                label={t('assetsSettings.operationMode')}
                fullWidth
                inputRef={ref}
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error?.message}
                {...dataTestId('operation_mode_select')}
              >
                {assetOperationModesIds.map((id) => (
                  <MenuItem key={id} value={id} {...dataTestId('operation_mode_select_item')}>
                    {operationModes.find((operationMode) => operationMode.id === id)?.name}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
          <Controller
            name="calendarEvent.start"
            control={control}
            rules={{
              ...requiredValidator(t),
              validate: (value, otherFields) => {
                const validateStartEndError = validateStartEnd({
                  start: value,
                  end: otherFields.calendarEvent.end
                })
                if (validateStartEndError) {
                  return validateStartEndError
                }
                if (type !== 'edit' && value.isBefore(currentTimeRoundedToNearest15Min)) {
                  return t('common.validation.startMustBeAfterCurrentTime')
                }
                return undefined
              }
            }}
            render={({field: {ref, value, onChange}, fieldState: {error}}) => (
              <Box pb={1} {...dataTestId('standard_operation_time_start')}>
                <DateTimePicker
                  ref={ref}
                  onChange={onChange}
                  value={value}
                  dateLabel={t('datePicker.startDate')}
                  timeLabel={t('datePicker.startTime')}
                  minDate={moment().tz(timezoneId).startOf('day')}
                  maxDate={moment().tz(timezoneId).add(1, 'years').endOf('year')}
                  error={error}
                  timezoneId={timezoneId}
                />
              </Box>
            )}
          />
          <Controller
            name="calendarEvent.end"
            control={control}
            rules={{
              ...requiredValidator(t),
              validate: (value, otherFields) =>
                validateStartEnd({end: value, start: otherFields.calendarEvent.start})
            }}
            render={({field: {ref, value, onChange}, fieldState: {error}}) => (
              <Box pb={1} {...dataTestId('standard_operation_time_end')}>
                <DateTimePicker
                  ref={ref}
                  onChange={onChange}
                  value={value}
                  dateLabel={t('datePicker.endDate')}
                  timeLabel={t('datePicker.endTime')}
                  minDate={moment().tz(timezoneId).startOf('day')}
                  maxDate={moment().tz(timezoneId).add(1, 'years').endOf('year')}
                  error={error}
                  timezoneId={timezoneId}
                />
              </Box>
            )}
          />
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography>{t('common.interval')}</Typography>
            <Controller
              name="calendarEvent.recurrenceRule.interval"
              control={control}
              rules={{...requiredValidator(t), ...minValidator(t, 1)}}
              render={({field: {ref, value, onChange}, fieldState: {error}}) => (
                <NumberInput
                  inputRef={ref}
                  step={1}
                  min={1}
                  onChange={onChange}
                  value={value}
                  sx={{
                    width: ({spacing}) => spacing(8)
                  }}
                  error={error?.message}
                  dataTestId={'standart_operation_time_internal_input'}
                />
              )}
            />
            <Controller
              name="calendarEvent.recurrenceRule.freq"
              control={control}
              rules={requiredValidator(t)}
              render={({field: {ref, value, onChange}, fieldState: {error}}) => (
                <TextField
                  sx={{width: ({spacing}) => spacing(30)}}
                  select
                  label={t('assetsSettings.standardOperationTimes.frequency')}
                  inputRef={ref}
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error?.message}
                  {...dataTestId('standard_operation_time_frequency_select')}
                >
                  {[
                    StandartOperationTimeRepetition.DAILY,
                    StandartOperationTimeRepetition.WEEKLY,
                    StandartOperationTimeRepetition.MONTHLY
                  ].map((period) => (
                    <MenuItem
                      key={period}
                      value={period}
                      {...dataTestId('standard_operation_time_frequency_select_item')}
                    >
                      {t(`assetsSettings.standardOperationTimes.schedulingPeriod.${period}`)}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Stack>
          {freq === StandartOperationTimeRepetition.WEEKLY && (
            <Stack direction="row" spacing={2} alignItems="center">
              {new Array(7).fill(0).map((_, index) => (
                <Controller
                  key={index}
                  name="calendarEvent.recurrenceRule.daysOfWeek"
                  control={control}
                  render={({field: {ref, value, onChange}}) => {
                    const currentValue = value || {}
                    return (
                      <Button
                        color="primary"
                        ref={ref}
                        variant={(currentValue || {})[index] ? 'contained' : 'outlined'}
                        {...dataTestId(`standard_operation_time_weekly_day_${index}_button`)}
                        onClick={() => {
                          onChange({...currentValue, [index]: !(currentValue || {})[index]})
                        }}
                      >
                        {getDayOfWeek(index)}
                      </Button>
                    )
                  }}
                />
              ))}
            </Stack>
          )}
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography>{t('assetsSettings.standardOperationTimes.ends')}</Typography>
            <Controller
              name="calendarEvent.recurrenceRule.ends"
              control={control}
              render={({field: {ref, value, onChange}, fieldState: {error}}) => (
                <TextField
                  sx={{width: ({spacing}) => spacing(30)}}
                  select
                  inputRef={ref}
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error?.message}
                  {...dataTestId('standard_operation_time_ends_select')}
                >
                  {[StandartOperationTimeEnds.after, StandartOperationTimeEnds.on].map((value) => (
                    <MenuItem
                      key={value}
                      value={value}
                      {...dataTestId('standard_operation_time_ends_select_item')}
                    >
                      {t(`assetsSettings.standardOperationTimes.endsValues.${value}`)}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
            {ends === StandartOperationTimeEnds.after && (
              <>
                <Controller
                  name="calendarEvent.recurrenceRule.count"
                  control={control}
                  rules={{...requiredValidator(t), ...minValidator(t, 1)}}
                  render={({field: {ref, value, onChange}, fieldState: {error}}) => (
                    <NumberInput
                      inputRef={ref}
                      step={1}
                      onChange={onChange}
                      value={value}
                      sx={{
                        width: ({spacing}) => spacing(8)
                      }}
                      error={error?.message}
                      dataTestId={'standart_operation_time_count_input'}
                    />
                  )}
                />
                <Typography variant="captionBold">
                  {t('assetsSettings.standardOperationTimes.occurrences')}
                </Typography>
              </>
            )}
            {ends === StandartOperationTimeEnds.on && (
              <>
                <Controller
                  name="calendarEvent.recurrenceRule.until"
                  control={control}
                  rules={{
                    ...requiredValidator(t),
                    validate: (value, otherFields) =>
                      value.isSameOrBefore(otherFields.calendarEvent.start)
                        ? t('common.validation.untilMustBeAfterStart')
                        : undefined
                  }}
                  render={({field: {ref, value, onChange}, fieldState: {error}}) => (
                    <DatePicker
                      value={value}
                      onChange={(e: any) => {
                        onChange((e as Moment).clone().endOf('day') as any)
                      }}
                      minDate={start.clone().startOf('day')}
                      maxDate={moment().tz(timezoneId).add(1, 'years').endOf('year')}
                      inputRef={ref}
                      renderInput={(props) => (
                        <TextField
                          {...props}
                          error={!!error}
                          helperText={error?.message}
                          inputProps={{...props.inputProps, readOnly: true}}
                        />
                      )}
                      label={t('common.date')}
                      {...dataTestId('standard_operation_time_until_select')}
                    />
                  )}
                />
              </>
            )}
          </Stack>
          <Controller
            name="isFixed"
            control={control}
            render={({field: {ref, value, onChange}}) => (
              <Switch
                value={value}
                inputRef={ref}
                label={t('assetsSettings.standardOperationTimes.fixedForOptimizer')}
                onChange={onChange}
                {...dataTestId('standard_operation_time_is_fixed_switch')}
              />
            )}
          />
        </Stack>
      </Box>
    </LocalizationProvider>
  )
}
