import {Feature} from '@hconnect/common/enums'
import {useCallback, useRef} from 'react'
import {visitParents} from 'unist-util-visit-parents'

import {removeProductionLinesFromTree, getEnrichedPlantStructure} from '../../helpers'
import {PlantNode, StructureNodeUnist, StructureNodeUnistWithAncestors, TreeNode} from '../../types'
import {useSettingsFeature} from '../useSettingsFeature'

import {useAssetsQuery} from '@settings/modules/assets'
import {useLoginDetails, useSettingsQuery} from '@settings/modules/common/providers'
import {useUrlParam} from '@settings/modules/routing'
import {useMaterialStorageListQuery} from '@settings/modules/storages/hooks'

const getUnistNodes = (
  node: PlantNode
): {tree: StructureNodeUnist; flatNodes: StructureNodeUnistWithAncestors[]} => {
  const flatNodes: StructureNodeUnistWithAncestors[] = []

  const transformToUnistNode = (node: TreeNode): StructureNodeUnist => {
    const name = (node as PlantNode)?.plantName ?? node.text ?? node.type?.name

    return {
      type: node.itemType,
      data: {
        id: node.upmId,
        upmId: node.upmId,
        name,
        signalCount: 0,
        entityProperties: node.type,
        entityLabel: name
      },
      children: node.children ? node.children.map(transformToUnistNode) : [],
      entityType: node.itemType
    }
  }

  const tree = transformToUnistNode(node)

  visitParents(tree, (node, ancestors) => {
    flatNodes.push({
      ...node,
      ancestors
    })
  })

  return {
    tree,
    flatNodes
  }
}

export const useGetUnistPlantStructure = () => {
  // we use the full structure
  const {data} = useGetPlantStructure({includeEquipment: true, product: undefined})
  const unistStructureRef = useRef<ReturnType<typeof getUnistNodes> | undefined>()

  // this operation is so heavy that it must NEVER run after initial load as it can crash the browser
  // the structure has potentially hundreds of thousands of nodes so we must never traverse it on client side
  // TODO: extend BE structure endpoint to return also flat structure so we dont have to do this here
  if (!unistStructureRef.current && data) {
    unistStructureRef.current = getUnistNodes(data)
  }

  return unistStructureRef.current
}

export const useGetPlantStructure = ({
  includeEquipment,
  product
}: {
  includeEquipment: boolean
  product?: string
}) => {
  const plantCode = useUrlParam('plantCode')
  const {loginDetails} = useLoginDetails()

  // Feature toggle which controls if we display the full plant structure or only the plant node
  const isUpmStructureEnabled = useSettingsFeature(Feature.UpmAssetsAndStorages)

  // The boolean used to bypass the upm feature toggle for users with ASSET_CONFIGURATOR or JANUS_DEVELOPER role
  // and display full plant structure even for the plants without upm feature enabled
  const shouldBypassFeatureToggle = !!loginDetails?.hasRoles.some((role: string) =>
    ['ASSET_CONFIGURATOR', 'JANUS_DEVELOPER'].includes(role)
  )

  const {data: assets} = useAssetsQuery()
  const {data: materialStorages} = useMaterialStorageListQuery()

  if (!assets || !materialStorages)
    throw new Error('Assets and material storages must be loaded before plant structure')

  const transformPlantStructure = useCallback(
    (data: PlantNode) => {
      const enrichedPlantStructure = removeProductionLinesFromTree(
        getEnrichedPlantStructure(data, assets, materialStorages)
      )

      if (!isUpmStructureEnabled && !shouldBypassFeatureToggle) {
        return {...enrichedPlantStructure, children: []}
      }

      return enrichedPlantStructure
    },
    [assets, materialStorages, isUpmStructureEnabled, shouldBypassFeatureToggle]
  )

  return useSettingsQuery('upmStructure', [plantCode, includeEquipment, product], {
    staleTime: Infinity,
    keepPreviousData: true,
    select: transformPlantStructure
  })
}
