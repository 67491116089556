import plantsFromJson from '@hconnect/common/hproduce/plants.json'
import {PlantJsonItem} from '@hconnect/common/hproduce/types'
import {useMemo} from 'react'

import {useUrlParam} from '../../routing'

export type PlantData = {
  plantName: string
  countryId: string
}

export const usePlantDataFromJson = (): PlantData | undefined => {
  const plantCode = useUrlParam('plantCode')

  return useMemo(() => {
    const plantFromJson: PlantJsonItem | undefined = plantsFromJson[plantCode]
    return plantFromJson
      ? {
          plantName: plantsFromJson[plantCode].name,
          countryId: plantsFromJson[plantCode].countryId
        }
      : undefined
  }, [plantCode])
}
