import {AdditivesForClinkerClassificationInputNames} from '@hconnect/common/enums'
import {MaterialType} from '@hconnect/common/types'
import {Box} from '@mui/material'

import {useMaterialsClassificationMetadata} from '../../../hooks/queries'

import {ClassificationAutocomplete} from './inputs/ClassificationAutocomplete'
import {MaterialsGridItem} from '../components/MaterialsGridItem'
import {MaterialsGrid} from '../components/MaterialsGrid'

interface AdditivesForClinkerClassificationProps {
  isDisabled: boolean
}

export const AdditivesForClinkerClassification: React.FC<
  AdditivesForClinkerClassificationProps
> = ({isDisabled}) => {
  const {data: additivesForClinkerClassificationMetadata} = useMaterialsClassificationMetadata(
    MaterialType.AdditivesForClinker
  )
  if (!additivesForClinkerClassificationMetadata) {
    throw new Error(
      'BUG: classification metadata should be loaded before rendering classification tab'
    )
  }

  return (
    <MaterialsGrid>
      <MaterialsGridItem>
        <ClassificationAutocomplete
          materialType={MaterialType.AdditivesForClinker}
          inputName={AdditivesForClinkerClassificationInputNames.Category}
          metadata={additivesForClinkerClassificationMetadata}
          isDisabled={isDisabled}
        />
      </MaterialsGridItem>
    </MaterialsGrid>
  )
}
