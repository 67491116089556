import {dataTestId} from '@hconnect/uikit'
import {customThemeConstants} from '@hconnect/uikit/src/lib2'
import Grid from '@mui/material/Unstable_Grid2'
import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {useSearchParams} from 'react-router-dom'

import {LayoutSettings, PageLayout, SettingsHelmet, SettingsSkeleton} from '../../common/components'
import {useKpis, useSensors, useFunctions} from '../../kpi-calculation/hooks'
import {RouteName} from '../../routing'
import {KPI_PARAM_NAME} from '../consts'
import {KpiWithValue} from '../types'

import {FormulaCard, KpiCard, VariableCard, ThresholdCard} from './kpi'
import {PrePopulatedKpiContainer} from './KpiList'
import {PrePopulatedProcessStages} from './PrePopulatedProcessStages'

export const KpiCalculationSettings = () => {
  const {t} = useTranslation()
  const {data, isLoading: isLoadingKpis} = useKpis()

  const [searchParams, setSearchParam] = useSearchParams()
  const tagname = searchParams.get(KPI_PARAM_NAME)

  const {data: sensors = [], isLoading: isSensorsLoading} = useSensors(tagname !== null)
  const {data: functions, isLoading: isFunctionsLoading} = useFunctions(tagname !== null)

  // TODO: move away from index matching approach
  const [selectedProcessStage, setSelectedProcessStage] = React.useState<number>(0)

  const selectedKpi: KpiWithValue | undefined = useMemo(() => {
    if (tagname !== null) {
      for (const processStage of data?.processStages || []) {
        const matchedKpi = processStage.definitionsWithResults.find(
          (kpi) => kpi.definition.tagname === tagname
        )
        if (matchedKpi) return matchedKpi
      }
    }
    return undefined
  }, [data?.processStages, tagname])

  const functionsVariables = useMemo(() => {
    return functions
      ? functions.functionDefinitions.map(({name, description}) => ({name, description}))
      : []
  }, [functions])

  if (isLoadingKpis || isSensorsLoading || isFunctionsLoading) {
    return <SettingsSkeleton />
  }

  const handleSetSelectedProcessStage = (processStage: number) => {
    setSearchParam({})
    setSelectedProcessStage(processStage)
  }

  return (
    <PageLayout
      title={t('kpiCalculationSettings.kpiCalculation')}
      {...dataTestId(`page_${RouteName.KPI_CALCULATION}`)}
    >
      <SettingsHelmet title={t('kpiCalculationSettings.pageTitle')} />
      <Grid container spacing={2} mt={2}>
        <LayoutSettings
          list={
            <PrePopulatedProcessStages
              data={data?.processStages || []}
              selectedProcessStage={selectedProcessStage}
              setSelectedProcessStage={handleSetSelectedProcessStage}
            />
          }
          details={
            !selectedKpi ? (
              <PrePopulatedKpiContainer
                data={data?.processStages || []}
                selectedProcessStage={selectedProcessStage}
              />
            ) : (
              <Grid container spacing={2}>
                <Grid xs={12}>
                  <KpiCard kpi={selectedKpi?.definition} />
                </Grid>

                <Grid xs={12}>
                  <FormulaCard
                    formula={selectedKpi.definition.formula}
                    kpiId={selectedKpi.definition.kpiDefinitionId}
                    lastResult={selectedKpi.lastResult}
                    frequency={selectedKpi.definition.frequency}
                  />
                </Grid>

                <Grid xs={12} md={6}>
                  <VariableCard
                    variables={sensors}
                    label={t('kpiCalculationSettings.label.insertSensor')}
                    nameSx={{color: 'primary.light'}}
                    {...dataTestId('sensor_card')}
                  />
                </Grid>
                <Grid xs={12} md={6}>
                  <VariableCard
                    variables={functionsVariables}
                    label={t('kpiCalculationSettings.label.insertFunction')}
                    nameSx={{color: customThemeConstants().palette.lightChart1}}
                    {...dataTestId('function_card')}
                  />
                </Grid>

                <Grid xs={12}>
                  <ThresholdCard
                    thresholds={{
                      highHigh: selectedKpi.definition.highHigh,
                      high: selectedKpi.definition.high,
                      low: selectedKpi.definition.low,
                      lowLow: selectedKpi.definition.lowLow
                    }}
                    unit={selectedKpi.definition.unit}
                    kpiId={selectedKpi.definition.kpiDefinitionId}
                  />
                </Grid>
              </Grid>
            )
          }
        />
      </Grid>
    </PageLayout>
  )
}
