import {useState} from 'react'

import {EditSignal} from '../../../upm/components/SignalForm/EditSignal'
import {NewSignal} from '../../../upm/components/SignalForm/NewSignal'
import {SignalList} from '../../../upm/components/SignalList/SignalList'
import {ItemDetailsLayout} from '../../plant-setup-item-layout'

import {TreeNode} from '@settings/modules/common/types'

type SensorView = {
  type: SensorViewType
  signalId: string | null
}

type SensorViewType = 'list' | 'new' | 'edit'

interface SensorsProps {
  selectedItem: TreeNode
}

export const Sensors: React.FC<SensorsProps> = ({selectedItem}) => {
  const [view, setView] = useState<SensorView>({
    type: 'list',
    signalId: null
  })

  return (
    <>
      {view.type === 'list' && (
        <ItemDetailsLayout>
          <SignalList
            selectedItem={selectedItem}
            onAddClick={() => setView({type: 'new', signalId: null})}
            onDetailClick={(signalId) => setView({type: 'edit', signalId})}
          />
        </ItemDetailsLayout>
      )}
      {view.type === 'new' && (
        <NewSignal
          selectedItem={selectedItem}
          onCreated={(signalId) => setView({type: 'edit', signalId})}
          onGoBack={() => setView({type: 'list', signalId: null})}
        />
      )}
      {view.type === 'edit' && (
        <EditSignal
          signalId={view.signalId}
          onGoBack={() => setView({type: 'list', signalId: null})}
        />
      )}
    </>
  )
}
