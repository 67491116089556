import {Material, MaterialType} from '@hconnect/common/types'
import {dataTestId} from '@hconnect/uikit'
import {FormHelperText} from '@material-ui/core'
import ClearIcon from '@mui/icons-material/Clear'
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Box,
  Button
} from '@mui/material'
import {Controller, Control, Path, FieldValues} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {ChipInput} from './ChipInput'
import {useMemo} from 'react'

interface MaterialSelectionProps<T extends FieldValues> {
  control: Control<T>
  materials: Material[]
  selectedItem?: {upmId: string}
  name: Path<T>
  label: string
  testId?: string
  materialType?: MaterialType
}

const filterAndMapFuels = (materialType?: MaterialType, data?: Material[]): Material[] => {
  if (!data || !materialType) return []
  return data.filter((item) => item.type === materialType)
}

export const MaterialSelection = <T extends FieldValues>({
  control,
  materials,
  label,
  testId,
  name,
  materialType
}: MaterialSelectionProps<T>): JSX.Element => {
  const {t} = useTranslation()

  const filteredMaterials = useMemo(
    () => filterAndMapFuels(materialType, materials),
    [materials, materialType]
  )

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: t('error.required')
      }}
      render={({field: {ref, value, onChange}, fieldState: {error}}) => {
        const helperText = (error && error.message) || ''
        return (
          <FormControl error={!!error}>
            <InputLabel id={`${label}-label`}>{t(label)}</InputLabel>
            <Select
              multiple
              labelId={`${label}-label`}
              label={t(label)}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 420
                  }
                }
              }}
              inputRef={ref}
              onChange={(e) => {
                const selectedValues = e.target.value
                onChange(selectedValues.join('";"'))
              }}
              {...dataTestId(testId || '')}
              value={value ? value.split('";"') : []}
              renderValue={(selected) => (
                <ChipInput
                  options={filteredMaterials
                    .filter((option) => selected.includes(option.id.toString()))
                    .map((option) => ({id: option.id.toString(), name: option.name}))}
                  onRemove={(option) =>
                    onChange(selected.filter((item) => item !== option).join('";"'))
                  }
                  key={`${label}-selected-materials`}
                />
              )}
            >
              {filteredMaterials.map((option) => {
                const currentValue = value ? value.split('";"') : []
                return (
                  <MenuItem key={`${label}-${option.id}`} value={option.id.toString()}>
                    <Checkbox checked={currentValue.includes(option.id.toString())} />
                    <ListItemText primary={option.name} />
                  </MenuItem>
                )
              })}
              <Box display="flex" alignItems="center" justifyContent="center" paddingTop={2}>
                <Button sx={{width: '90%'}} onClick={() => onChange('')} color="secondary">
                  <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
                    <ClearIcon color="secondary" />
                    {t('janusConfig.plantSetup.clearAll')}
                  </Box>
                </Button>
              </Box>
            </Select>
            <FormHelperText error={!!error}>{helperText}</FormHelperText>
          </FormControl>
        )
      }}
    />
  )
}
