import {PlantStructureTabs} from '@hconnect/common/enums'
import {useMemo} from 'react'

import {
  General,
  Kpis,
  Layout,
  OperationModes,
  Sensors,
  StandardTimes,
  Configuration,
  Connection,
  Optimizer,
  BaseLoad
} from './plant-setup-tabs'
import {useItemDetails, useSelectedItem} from './providers'

export const ItemDetails = () => {
  const {selectedItem} = useSelectedItem()
  const {selectedTab} = useItemDetails()

  const TabContentComponent = useMemo(() => {
    if (!selectedTab) return () => null

    switch (selectedTab.value as PlantStructureTabs) {
      case PlantStructureTabs.General:
        return General
      case PlantStructureTabs.Configuration:
        return Configuration
      case PlantStructureTabs.Layout:
        return Layout
      case PlantStructureTabs.Sensors:
        return Sensors
      case PlantStructureTabs.Kpis:
        return Kpis
      case PlantStructureTabs.BaseLoad:
        return BaseLoad
      case PlantStructureTabs.OperationModes:
        return OperationModes
      case PlantStructureTabs.StandardTimes:
        return StandardTimes
      case PlantStructureTabs.Optimizer:
        return Optimizer
      case PlantStructureTabs.Connection:
        return Connection
      default:
        throw new Error(`BUG: Unknown tab value: ${selectedTab.value}`)
    }
  }, [selectedTab])

  return selectedItem ? (
    <TabContentComponent key={selectedItem.upmId} selectedItem={selectedItem} />
  ) : null
}
