import {OperationModeType} from '@hconnect/common/types'
import type {OperationMode, AssetTransition, Asset} from '@hconnect/common/types'
import {dataTestId} from '@hconnect/uikit'
import {Card, CardTitle} from '@hconnect/uikit/src/lib2'
import {Box, TextField, MenuItem, Stack, Typography} from '@mui/material'
import {useState, useEffect, useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {SwitchMatrixForm} from './SwitchMatrixForm'

import {useAssetTransitionsQuery} from '@settings/modules/assets'

const getProductionOperationModes = (operationModes: OperationMode[]) =>
  operationModes.filter((operationMode) => operationMode.type !== OperationModeType.Maintenance)

const getTransitionsFromOperationMode = (
  operationModeId: number,
  transitions?: AssetTransition[]
) => transitions?.filter(({from}) => from === operationModeId) ?? []

interface SwitchMatrixCardProps {
  asset: Asset
  isReadOnly: boolean
}

export const SwitchMatrixCard = ({asset, isReadOnly}: SwitchMatrixCardProps) => {
  const {t} = useTranslation()

  const {data: assetTransitions} = useAssetTransitionsQuery(asset.id)

  const {operationModes} = asset
  const productionOperationModes = useMemo(
    () => getProductionOperationModes(operationModes),
    [operationModes]
  )

  const [selectedOperationModeId, setSelectedOperationModeId] = useState<OperationMode['id']>()

  const selectedTransitions =
    selectedOperationModeId !== undefined
      ? getTransitionsFromOperationMode(selectedOperationModeId, assetTransitions)
      : undefined

  // Effect to set initial value of selected operation mode
  useEffect(() => {
    const firstOperationMode =
      productionOperationModes.length > 0 ? productionOperationModes[0] : undefined
    setSelectedOperationModeId(firstOperationMode?.id)
  }, [productionOperationModes])

  return (
    <Card
      {...dataTestId('switch_matrix_card')}
      headerContent={
        <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <Stack>
            <CardTitle sx={{mb: 0}}>{t('assetsSettings.switchMatrix')}</CardTitle>
            <Typography variant="caption">{t('assetsSettings.switchTimeMinutes')}</Typography>
          </Stack>
          {productionOperationModes.length > 0 && (
            <TextField
              select
              sx={{width: ({spacing}) => spacing(30)}}
              label={t('assetsSettings.switchFrom')}
              value={selectedOperationModeId ?? ''}
              onChange={({target: {value}}) =>
                setSelectedOperationModeId(value === '' ? undefined : parseInt(value))
              }
              {...dataTestId('operation_mode_select')}
            >
              {productionOperationModes.map((operationMode) => (
                <MenuItem
                  key={operationMode.id}
                  value={operationMode.id}
                  {...dataTestId('operation_mode_select_option')}
                >
                  {operationMode.name}
                </MenuItem>
              ))}
            </TextField>
          )}
        </Box>
      }
    >
      {selectedTransitions && (
        <SwitchMatrixForm
          asset={asset}
          transitions={selectedTransitions}
          operationModes={productionOperationModes}
          isReadOnly={isReadOnly}
        />
      )}
    </Card>
  )
}
