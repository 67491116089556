import {AssetType} from '@hconnect/common/types'
import {Stack} from '@mui/material'

import {usePermission} from '../../../permissions'
import {isDepartmentOrEquipmentNode, isAssetEnabledForPlanning} from '../../helpers'
import {ItemDetailsLayout} from '../../plant-setup-item-layout'
import {useSelectedItem} from '../../providers'

import {AttachedSilosCard} from './attachedSilos'
import {SwitchMatrixCard} from './switchMatrix'

export const Optimizer: React.FC = () => {
  const {selectedItem} = useSelectedItem()
  const canChangeAssets = usePermission('CHANGE_ASSETS')

  if (
    !isDepartmentOrEquipmentNode(selectedItem) ||
    !isAssetEnabledForPlanning(selectedItem.enabledForPlanningItem)
  )
    return null

  const asset = selectedItem.enabledForPlanningItem

  return (
    <ItemDetailsLayout>
      <Stack spacing={3}>
        {asset.type === AssetType.CementMill && (
          <SwitchMatrixCard asset={asset} isReadOnly={!canChangeAssets} />
        )}
        <AttachedSilosCard asset={asset} isReadOnly={!canChangeAssets} />
      </Stack>
    </ItemDetailsLayout>
  )
}
