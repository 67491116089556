import {useNotification} from '@hconnect/uikit/src/common'
import {AxiosError} from 'axios'
import {useCallback} from 'react'
import {useErrorHandler} from 'react-error-boundary'
import {useTranslation} from 'react-i18next'
import {useMutation} from 'react-query'

import {mutations} from '../../../../../api/mutations'
import {invalidateSettingsQuery} from '../../../../common/providers/ApiInterface'
import {notifyIfErrorMessage} from '../../../../common/utils/errorHandling'
import {
  AssetStandardOperationTimeConflictResolutionAction,
  AssetStandardOperationTimeValidationError,
  NewAssetStandardOperationTimeData
} from '../../../types'
import {generateICalString} from '../../../utils'

type UpdateStandardOperationTimeParams = {
  onSuccess: () => void
}

export const useUpdateStandardOperationTime = ({onSuccess}: UpdateStandardOperationTimeParams) => {
  const {t} = useTranslation()
  const {notify} = useNotification()
  const raiseError = useErrorHandler()

  const {mutate, isLoading} = useMutation<
    void,
    AxiosError<AssetStandardOperationTimeValidationError>,
    Parameters<typeof mutations.updateStandardOperationTime>[0]
  >(mutations.updateStandardOperationTime, {
    onError: (error) => {
      notifyIfErrorMessage(error.response?.data.detail, raiseError, notify)
    },
    onSuccess: () => {
      notify('success', t('success.editStandardOperationTime'))
      onSuccess && onSuccess()
    },
    onSettled: () => {
      invalidateSettingsQuery('assetsStandardOperationTimes')
    }
  })

  const mutateFn = useCallback(
    (
      data: {
        plantCode: string
        plantTimeZone: string
        assetId: number
        defaultOperationTimeId: number
        onConflict: AssetStandardOperationTimeConflictResolutionAction
      } & NewAssetStandardOperationTimeData,
      {onError}: {onError: (error: AxiosError<AssetStandardOperationTimeValidationError>) => void}
    ) =>
      mutate(
        {
          onConflict: data.onConflict,
          plantCode: data.plantCode,
          defaultOperationTimeId: data.defaultOperationTimeId,
          AssetStandardOperationTimeDto: {
            assetId: data.assetId,
            isFixed: data.isFixed,
            calendarEvent: generateICalString(data.calendarEvent, data.plantTimeZone),
            operationModeId: data.operationModeId
          }
        },
        {onError}
      ),
    [mutate]
  )

  return {
    isLoading,
    mutate: mutateFn
  }
}
