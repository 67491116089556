import React, {useContext, useState, useMemo} from 'react'

import {TreeNode} from '../../common/types'

interface SelectedItemState {
  selectedItem: TreeNode | undefined
  setSelectedItem: React.Dispatch<React.SetStateAction<TreeNode | undefined>>
}

const SelectedItemContext = React.createContext<SelectedItemState | undefined>(undefined)

export const useSelectedItem = () => {
  const context = useContext(SelectedItemContext)
  if (!context) throw new Error('Cannot use selected item context outside of a context provider')
  return context
}

export const SelectedItemProvider = ({children}: {children: React.ReactNode}) => {
  const [selectedItem, setSelectedItem] = useState<TreeNode | undefined>(undefined)

  const memoizedState: SelectedItemState = useMemo(
    () => ({
      selectedItem,
      setSelectedItem
    }),
    [selectedItem, setSelectedItem]
  )

  return (
    <SelectedItemContext.Provider value={memoizedState}>{children}</SelectedItemContext.Provider>
  )
}
