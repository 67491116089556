import {Stack} from '@mui/material'

import {Skeleton} from './Skeleton'

interface SettingsDetailsSkeletonProps {
  numberOfCards?: number
}

export const SettingsDetailsSkeleton: React.FC<SettingsDetailsSkeletonProps> = ({
  numberOfCards = 1
}) => {
  return (
    <Stack spacing={2}>
      {Array.from({length: numberOfCards}).map((_, index) => (
        <Skeleton key={index} height={`${55 / numberOfCards}vh`} />
      ))}
    </Stack>
  )
}
