import {DatamartSyncDetails, MaterialStorage} from '@hconnect/common/types'
import {dataTestId} from '@hconnect/uikit'
import {DeleteOutlined} from '@mui/icons-material'
import {Stack, TextField} from '@mui/material'
import {useForm, Controller} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {DeleteButton, NumberInput} from '@settings/modules/common/components'
import {useConfirmDialog} from '@settings/modules/common/providers'
import {
  minValidator,
  requiredValidator,
  submitOnBlurAndEnterProps
} from '@settings/modules/common/utils'
import {useUrlParam} from '@settings/modules/routing'
import {
  useAddDatamartConnection,
  useDeleteDataConnection,
  useEditDatamartConnection
} from '@settings/modules/storages/hooks'

interface DataMartConnectionFormProps {
  storage: MaterialStorage
  isReadOnly: boolean
  dataMartConnection: DatamartSyncDetails
  setDataMartConnection: (value: DatamartSyncDetails | null) => void
}

export const DataMartConnectionForm: React.FC<DataMartConnectionFormProps> = ({
  storage,
  isReadOnly,
  dataMartConnection,
  setDataMartConnection
}) => {
  const {t} = useTranslation()
  const plantCode = useUrlParam('plantCode')
  const {openDialog} = useConfirmDialog()

  const {mutate: addDatamartConnection, isLoading: isConnectionAdding} = useAddDatamartConnection()
  const {mutate: editDatamartConnection, isLoading: isConnectionEditing} =
    useEditDatamartConnection()
  const {mutate: deleteDataConnection, isLoading: isConnectionDeleting} = useDeleteDataConnection()

  const isNewDataMartConnection = !storage.datamartSyncDetails

  const isFormDisabled =
    isReadOnly || isConnectionAdding || isConnectionDeleting || isConnectionEditing

  const {
    handleSubmit,
    control,
    reset,
    formState: {isDirty, dirtyFields}
  } = useForm<DatamartSyncDetails>({
    mode: 'onChange',
    shouldFocusError: false,
    defaultValues: dataMartConnection
  })

  const submit = handleSubmit((values) => {
    if (!isDirty) return

    if (isNewDataMartConnection) {
      return addDatamartConnection(
        {plantCode, storageId: storage.id, dto: values},
        {onSuccess: () => setDataMartConnection(values)}
      )
    }

    Object.keys(dirtyFields).forEach((key) => {
      editDatamartConnection(
        {
          plantCode,
          storageId: storage.id,
          dto: values,
          key: key as keyof DatamartSyncDetails
        },
        {
          onSuccess: () => {
            reset(values)
            setDataMartConnection(values)
          }
        }
      )
    })
  })

  const deleteConnection = () => {
    if (isNewDataMartConnection) {
      return setDataMartConnection(null)
    }

    openDialog({
      title: t('storagesSettings.deleteDataMartConnection'),
      description: t('storagesSettings.deleteDataMartConnectionDescription'),
      mainAction: {
        text: t('common.delete'),
        color: 'error',
        icon: <DeleteOutlined />,
        onAction: () => {
          deleteDataConnection({plantCode, storageId: storage.id, type: 'datamart'})
          setDataMartConnection(null)
        }
      },
      testId: 'delete_datamart_connection_dialog'
    })
  }

  return (
    <Stack
      spacing={2}
      direction="row"
      alignItems="center"
      {...dataTestId('datamart_connection_form')}
    >
      <Controller
        control={control}
        name="tagName"
        rules={requiredValidator(t)}
        render={({field: {ref, value, onChange}, fieldState: {error}}) => (
          <TextField
            sx={{width: ({spacing}) => spacing(27)}}
            variant="outlined"
            label={t('storagesSettings.tagName')}
            InputLabelProps={{shrink: true}}
            inputRef={ref}
            value={value}
            onChange={onChange}
            helperText={error?.message}
            error={Boolean(error?.message)}
            disabled={isFormDisabled}
            {...submitOnBlurAndEnterProps(submit)}
            {...dataTestId('tag_name_input')}
          />
        )}
      />
      <Controller
        control={control}
        name="tonneConversionFactor"
        rules={{...requiredValidator(t), ...minValidator(t, 0)}}
        render={({field: {ref, value, onChange}, fieldState: {error}}) => (
          <NumberInput
            sx={{width: ({spacing}) => spacing(27)}}
            label={t('storagesSettings.tonneConversionFactor')}
            min={0}
            inputRef={ref}
            value={value}
            onChange={onChange}
            error={error?.message}
            disabled={isFormDisabled}
            dataTestId="tonne_conversion_factor_input"
            {...submitOnBlurAndEnterProps(submit)}
          />
        )}
      />
      <div>
        <DeleteButton
          onClick={deleteConnection}
          disabled={isFormDisabled}
          {...dataTestId('delete_datamart_connection_button')}
        />
      </div>
    </Stack>
  )
}
