import React from 'react'
import {UseFormReturn, useWatch} from 'react-hook-form'

import {NextOccurencesPreview} from './NextOccurencesPreview'

import {NewAssetStandardOperationTimeData} from '@settings/modules/assets/types'
import {StandardOperationTime} from '@settings/modules/assets/types/standardOperationTimes'

type EditDialogNextOccurencesPreviewProps = {
  isValid: boolean
} & Pick<UseFormReturn<NewAssetStandardOperationTimeData, void, undefined>, 'control'>

export const EditDialogNextOccurencesPreview: React.FC<EditDialogNextOccurencesPreviewProps> = ({
  isValid,
  control
}) => {
  const standardOperationTime = useWatch<NewAssetStandardOperationTimeData>({control})
  if (
    standardOperationTime === undefined ||
    standardOperationTime.calendarEvent === undefined ||
    !isValid
  ) {
    return null
  }
  const event: StandardOperationTime = standardOperationTime.calendarEvent as StandardOperationTime
  return <NextOccurencesPreview standardOperationTime={event} />
}
