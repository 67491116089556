import {MaterialType, MaterialStorage} from '@hconnect/common/types'
import {dataTestId} from '@hconnect/uikit'
import type {MenuAction} from '@hconnect/uikit/src/lib2'
import {DeleteOutlined, DeleteOutline, DriveFileMoveOutlined} from '@mui/icons-material'
import {MenuItem, TextField} from '@mui/material'
import React, {useRef} from 'react'
import {useTranslation, Trans} from 'react-i18next'

import {useConfirmDialog} from '../../../common/providers'
import {usePermission} from '../../../permissions/hooks'
import {useSearchParam, useUrlParam} from '../../../routing'
import {useDeleteStorage, useEditStorage} from '../../hooks'
import {useSelectedStorage} from '../SelectedStorageProvider'

export const useStorageListActions = () => {
  const plantCode = useUrlParam('plantCode')
  const {setSelectedStorage} = useSelectedStorage()

  const [, setMaterialType] = useSearchParam('materialType')
  const {t} = useTranslation()
  const canChangeMaterialStorage = usePermission('CHANGE_MATERIAL_STORAGE')
  const {openDialog} = useConfirmDialog()

  const {mutate: editStorage, isLoading: isStorageEditing} = useEditStorage()
  const {mutate: deleteStorage, isLoading: isStorageDeleting} = useDeleteStorage()

  const moveStorageSelectRef = useRef<HTMLSelectElement>()

  const handleMoveStorage = (storage: MaterialStorage) => {
    if (moveStorageSelectRef.current?.value) {
      const newMaterialType = moveStorageSelectRef.current.value as MaterialType
      editStorage(
        {
          plantCode,
          id: storage.id,
          key: 'materialType',
          dto: {...storage, materialType: newMaterialType}
        },
        {
          onSuccess: () => setMaterialType(newMaterialType)
        }
      )
    }
  }

  const handleDeleteStorage = (storage: MaterialStorage) => {
    setSelectedStorage(undefined)
    deleteStorage(
      {plantCode, id: storage.id},
      {onError: () => setSelectedStorage({id: storage.id, status: storage.status})}
    )
  }

  const getStorageListActions = (storage: MaterialStorage) => {
    const onMove = () => {
      openDialog({
        testId: 'move_storage_dialog',
        title: t('storagesSettings.moveStorage'),
        description: t('storagesSettings.moveStorageDescription'),
        additionalContent: (
          <TextField
            inputRef={moveStorageSelectRef}
            variant="outlined"
            fullWidth
            select
            label={t('assetsSettings.assetType')}
            defaultValue={Object.values(MaterialType).find((type) => type !== storage.materialType)}
            {...dataTestId('move_storage_type_select')}
          >
            {Object.values(MaterialType).map((type) => (
              <MenuItem key={type} value={type} {...dataTestId(`${type}_select_item`)}>
                {t(`materialsSettings.materialTypes.${type}`)}
              </MenuItem>
            ))}
          </TextField>
        ),
        mainAction: {
          text: t('common.move'),
          icon: <DeleteOutline />,
          onAction: () => handleMoveStorage(storage)
        }
      })
    }

    const onDelete = () => {
      openDialog({
        testId: 'delete_storage_dialog',
        title: t('storagesSettings.deleteStorage'),
        additionalContent: (
          <Trans
            i18nKey="storagesSettings.deleteStorageDescription"
            values={{name: storage.name}}
          />
        ),
        mainAction: {
          color: 'error',
          text: t('common.delete'),
          icon: <DeleteOutline />,
          onAction: () => handleDeleteStorage(storage)
        }
      })
    }

    const actions: MenuAction[] = [
      {
        icon: <DriveFileMoveOutlined fontSize="small" />,
        title: t('common.move'),
        isDisabled: !canChangeMaterialStorage || isStorageEditing,
        testId: 'move_storage',
        onClick: onMove
      },
      {
        icon: <DeleteOutlined fontSize="small" color="error" />,
        title: t('common.delete'),
        isDisabled: !canChangeMaterialStorage || isStorageDeleting,
        testId: 'delete_storage',
        onClick: onDelete
      }
    ]

    return actions
  }

  return {getStorageListActions}
}
