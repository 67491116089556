import React from 'react'

import {useSelectedItem} from '../../../providers'

import {EnabledForPlanningAsset} from './EnabledForPlanningAsset'
import {EnabledForPlanningStorage} from './EnabledForPlanningStorage'

import {usePermission} from '@settings/modules/permissions'
import {
  isAssetEnabledForPlanning,
  isDepartmentOrEquipmentNode
} from '@settings/modules/plant-setup/helpers'

export const EnabledForPlanningItemData: React.FC = () => {
  const {selectedItem} = useSelectedItem()
  const canChangeAssets = usePermission('CHANGE_ASSETS')
  const cnaChangeStorages = usePermission('CHANGE_MATERIAL_STORAGE')

  if (!isDepartmentOrEquipmentNode(selectedItem) || !selectedItem.enabledForPlanningItem)
    return null

  if (isAssetEnabledForPlanning(selectedItem.enabledForPlanningItem)) {
    const asset = selectedItem.enabledForPlanningItem
    return <EnabledForPlanningAsset asset={asset} isReadOnly={!canChangeAssets} />
  }

  const storage = selectedItem.enabledForPlanningItem
  return <EnabledForPlanningStorage storage={storage} isReadOnly={!cnaChangeStorages} />
}
