import {useCallback} from 'react'
import {NodeApi} from 'react-arborist'

import {isAssetEnabledForPlanning, isDepartmentOrEquipmentNode} from '../../helpers'

import {PlantStructureFilterOptions} from '@settings/modules/common/enums'
import {TreeNode, UpmDepartmentOrEquipmentType} from '@settings/modules/common/types'

export const useHandleTreeFiltering = (
  selectedFilterOption: PlantStructureFilterOptions,
  filterText: string
) => {
  const textFilter = filterText.trim().length > 0 ? filterText : undefined

  const quickFilter =
    selectedFilterOption === PlantStructureFilterOptions.Storages ||
    selectedFilterOption === PlantStructureFilterOptions.Planner
      ? selectedFilterOption
      : undefined

  const searchTerm = textFilter || quickFilter

  const handleTreeFiltering = useCallback(
    (node: NodeApi<TreeNode>) => {
      const nodeName = node.data.text ?? ''

      const filters = {
        textFilter: textFilter ? nodeName.toLowerCase().includes(textFilter.toLowerCase()) : true,
        storagesFilter:
          quickFilter === PlantStructureFilterOptions.Storages
            ? isDepartmentOrEquipmentNode(node.data) &&
              node.data.departmentOrEquipmentType === UpmDepartmentOrEquipmentType.MaterialStorage
            : true,
        plannerFilter:
          quickFilter === PlantStructureFilterOptions.Planner
            ? isDepartmentOrEquipmentNode(node.data) &&
              isAssetEnabledForPlanning(node.data.enabledForPlanningItem)
            : true
      }

      return (
        Object.values(filters).every((shouldDisplayNode) => shouldDisplayNode === true) ||
        node.level === 0
      )
    },
    [quickFilter, textFilter]
  )

  return {
    searchTerm,
    handleTreeFiltering
  }
}
