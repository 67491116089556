import {dataTestId} from '@hconnect/uikit'
import {InfoOutlined} from '@mui/icons-material'
import {SxProps, Tab, TabScrollButton, Tabs, Theme, Typography, styled} from '@mui/material'
import {SyntheticEvent, useCallback} from 'react'

export interface HeaderTab {
  value: string
  label: string
  disabled?: boolean
  hasError?: boolean
}

interface HeaderTabsProps {
  tabs: HeaderTab[]
  selectedTab: HeaderTab
  onTabChange: (tab: HeaderTab) => void
  testIdPrefix?: string
  sxTabs?: SxProps<Theme>
  sxTab?: SxProps<Theme>
}

const HeaderTabsScrollButton = styled(TabScrollButton)(({theme}) => ({
  '&.Mui-disabled': {
    width: 0
  },
  overflow: 'hidden',
  transition: 'width 0.5s',
  width: theme.spacing(3)
}))

export const HeaderTabs: React.FC<HeaderTabsProps> = ({
  tabs,
  selectedTab,
  onTabChange,
  testIdPrefix = 'header',
  sxTabs,
  sxTab
}) => {
  const handleTabChange = useCallback(
    (e: SyntheticEvent, tabValue: string) => {
      const selectedTab = tabs.find((tab) => tab.value === tabValue)
      if (!selectedTab) {
        throw new Error(`Tab with value ${tabValue} not found`)
      }
      onTabChange(selectedTab)
    },
    [onTabChange, tabs]
  )

  return (
    <Tabs
      variant="scrollable"
      scrollButtons="auto"
      ScrollButtonComponent={HeaderTabsScrollButton}
      allowScrollButtonsMobile
      value={selectedTab.value}
      onChange={handleTabChange}
      sx={sxTabs}
      {...dataTestId(`${testIdPrefix}-tabs`)}
    >
      {tabs.map((tab) => (
        <Tab
          key={tab.value}
          value={tab.value}
          disabled={!!tab.disabled}
          label={<Typography>{tab.label}</Typography>}
          sx={{
            ...sxTab,
            ...(tab.hasError
              ? {color: ({palette}) => palette.error.light, minHeight: ({spacing}) => spacing(6)}
              : {})
          }}
          {...(tab.hasError ? {icon: <InfoOutlined />, iconPosition: 'start'} : {})}
          {...dataTestId(`${testIdPrefix}-tab-${tab.value}`)}
        />
      ))}
    </Tabs>
  )
}
