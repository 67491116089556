import {usePermission} from '../../../permissions'
import {isAssetEnabledForPlanning, isDepartmentOrEquipmentNode} from '../../helpers'
import {ItemDetailsLayout} from '../../plant-setup-item-layout'
import {useSelectedItem} from '../../providers'

import {OperationModesCard} from './OperationModesCard'

export const OperationModes: React.FC = () => {
  const {selectedItem} = useSelectedItem()
  const canChangeAssets = usePermission('CHANGE_ASSETS')

  if (
    !isDepartmentOrEquipmentNode(selectedItem) ||
    !isAssetEnabledForPlanning(selectedItem.enabledForPlanningItem)
  )
    return null

  const asset = selectedItem.enabledForPlanningItem

  return (
    <ItemDetailsLayout>
      <OperationModesCard asset={asset} isReadOnly={!canChangeAssets} />
    </ItemDetailsLayout>
  )
}
