import {DatamartSyncDetails, MaterialStorage} from '@hconnect/common/types'
import {dataTestId} from '@hconnect/uikit'
import {Card, CardTitle} from '@hconnect/uikit/src/lib2'
import {Add} from '@mui/icons-material'
import {Stack, Button} from '@mui/material'
import {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {DataMartConnectionForm} from './DataMartConnectionForm'

interface DataMartCardProps {
  storage: MaterialStorage
  isReadOnly: boolean
}

export const DataMartCard: React.FC<DataMartCardProps> = ({storage, isReadOnly}) => {
  const {t} = useTranslation()
  const [dataMartConnection, setDataMartConnection] = useState<DatamartSyncDetails | null>(
    storage.datamartSyncDetails
  )
  const isAddConnectionButtonDisabled = isReadOnly || !!dataMartConnection

  return (
    <Card
      headerContent={
        <Stack direction="row" justifyContent="space-between">
          <CardTitle>{t('storagesSettings.dataMartConnection')}</CardTitle>
          <Button
            variant="text"
            startIcon={<Add />}
            disabled={isAddConnectionButtonDisabled}
            onClick={() => setDataMartConnection({tagName: '', tonneConversionFactor: 1})}
            {...dataTestId('add_datamart_connection')}
          >
            {t('storagesSettings.addConnection')}
          </Button>
        </Stack>
      }
      {...dataTestId('datamart_card')}
    >
      {dataMartConnection && (
        <DataMartConnectionForm
          key={storage.id}
          storage={storage}
          isReadOnly={isReadOnly}
          dataMartConnection={dataMartConnection}
          setDataMartConnection={setDataMartConnection}
        />
      )}
    </Card>
  )
}
