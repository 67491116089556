import {dataTestId} from '@hconnect/uikit'
import {CardBox} from '@hconnect/uikit/src/lib2'
import {InfoOutlined} from '@mui/icons-material'
import {useTranslation} from 'react-i18next'

import {MeasurementTypesCard} from '../components/MeasurementTypesCard'
import {useExtendedMeasurementTypesCategories} from '../hooks/useExtendedMeasurementTypesCategories'
import {useMeasurementTypesCategoriesListItems} from '../hooks/useMeasurementTypesCategoriesListItems'

import {
  LayoutSettings,
  PageLayout,
  SettingsHelmet,
  SettingsList,
  SettingsSkeleton
} from '@settings/modules/common/components'
import {RouteName} from '@settings/modules/routing'

export function PageMeasurementTypesSettings(): JSX.Element {
  const {t} = useTranslation()

  const {
    extendedMeasurementTypesCategories,
    unmappedLimsMeasurementTypes,
    isPageLoading,
    limsMeasurementTypes
  } = useExtendedMeasurementTypesCategories()

  const {measurementTypesCategoriesListItems, selectedMeasurementTypesCategory} =
    useMeasurementTypesCategoriesListItems(extendedMeasurementTypesCategories)

  if (isPageLoading || limsMeasurementTypes === undefined) {
    return <SettingsSkeleton />
  }

  if (limsMeasurementTypes.length === 0) {
    return (
      <CardBox sx={{textAlign: 'center'}}>
        <InfoOutlined
          sx={{
            mr: 1,
            verticalAlign: 'middle'
          }}
        />
        {t('measurementsSettings.noLimsMeasurementTypes')}
      </CardBox>
    )
  }

  return (
    <PageLayout
      title={t('measurementsSettings.qualityMeasurements')}
      {...dataTestId(`page_${RouteName.MEASUREMENTS}`)}
    >
      <SettingsHelmet title={t('measurementsSettings.pageTitle')} />
      <LayoutSettings
        list={
          <SettingsList
            items={measurementTypesCategoriesListItems}
            testId="measurement_types_categories_list"
          />
        }
        details={
          selectedMeasurementTypesCategory !== undefined ? (
            <MeasurementTypesCard
              selectedMeasurementTypesCategory={selectedMeasurementTypesCategory}
              unmappedLimsMeasurementTypes={unmappedLimsMeasurementTypes}
            />
          ) : (
            <></>
          )
        }
      />
    </PageLayout>
  )
}
