import {IntermediateAndFinishedProductsClassificationInputNames} from '@hconnect/common/enums'
import {MaterialType} from '@hconnect/common/types'
import {dataTestId} from '@hconnect/uikit/src/common/utils/testingUtils'
import {Stack} from '@mui/material'
import {useFormContext} from 'react-hook-form'

import {MaterialFormDefaultValues} from '../../materials-details/ExistingMaterialDetails/hooks'

import {ClassificationAutocomplete} from './inputs/ClassificationAutocomplete'
import {ClassificationCheckBox} from './inputs/ClassificationCheckbox'

import {useMaterialsClassificationMetadata} from '../../../hooks'
import {MaterialsGrid} from '../components/MaterialsGrid'
import {MaterialsGridItem} from '../components/MaterialsGridItem'

interface IntermediateAndFinishedProductsClassificationProps {
  isDisabled: boolean
}

export const IntermediateAndFinishedProductsClassification: React.FC<
  IntermediateAndFinishedProductsClassificationProps
> = ({isDisabled}) => {
  const {data: metadata} = useMaterialsClassificationMetadata(
    MaterialType.IntermediateAndFinishedProducts
  )

  const {setValue, getValues, clearErrors} = useFormContext<MaterialFormDefaultValues>()

  if (!metadata) {
    throw new Error(
      'BUG: classification metadata should be loaded before rendering classification tab'
    )
  }

  return (
    <MaterialsGrid {...dataTestId('intermediate_and_finished_products_classification_form')}>
      <MaterialsGridItem>
        <ClassificationAutocomplete
          materialType={MaterialType.IntermediateAndFinishedProducts}
          inputName={IntermediateAndFinishedProductsClassificationInputNames.Selection}
          metadata={metadata}
          isDisabled={isDisabled}
          onChangeCb={() => {
            const classificationValues = getValues('classification')
            setValue('classification', {
              ...classificationValues,
              [IntermediateAndFinishedProductsClassificationInputNames.Category]: null,
              [IntermediateAndFinishedProductsClassificationInputNames.MethodOfFinenessDetermination]:
                null,
              [IntermediateAndFinishedProductsClassificationInputNames.IntermediateMillProduct]:
                classificationValues[
                  IntermediateAndFinishedProductsClassificationInputNames.Selection
                ] === 'Finished Product'
                  ? 'No'
                  : null,
              [IntermediateAndFinishedProductsClassificationInputNames.ReferenceMeshSizeForResidue]:
                null
            })
            clearErrors([
              `classification.${IntermediateAndFinishedProductsClassificationInputNames.Category}`,
              `classification.${IntermediateAndFinishedProductsClassificationInputNames.MethodOfFinenessDetermination}`,
              `classification.${IntermediateAndFinishedProductsClassificationInputNames.ReferenceMeshSizeForResidue}`,
              `classification.${IntermediateAndFinishedProductsClassificationInputNames.IntermediateMillProduct}`
            ])
          }}
        />
      </MaterialsGridItem>
      <MaterialsGridItem>
        <ClassificationAutocomplete
          materialType={MaterialType.IntermediateAndFinishedProducts}
          inputName={IntermediateAndFinishedProductsClassificationInputNames.Category}
          metadata={metadata}
          isDisabled={isDisabled}
        />
      </MaterialsGridItem>
      <MaterialsGridItem>
        <ClassificationAutocomplete
          materialType={MaterialType.IntermediateAndFinishedProducts}
          inputName={
            IntermediateAndFinishedProductsClassificationInputNames.MethodOfFinenessDetermination
          }
          metadata={metadata}
          isDisabled={isDisabled}
        />
      </MaterialsGridItem>
      <MaterialsGridItem>
        <ClassificationCheckBox
          materialType={MaterialType.IntermediateAndFinishedProducts}
          inputName={
            IntermediateAndFinishedProductsClassificationInputNames.IntermediateMillProduct
          }
          customValues={{trueValue: 'Yes', falseValue: 'No'}}
          metadata={metadata}
          isDisabled={isDisabled}
        />
      </MaterialsGridItem>
      <MaterialsGridItem>
        <ClassificationAutocomplete
          materialType={MaterialType.IntermediateAndFinishedProducts}
          inputName={
            IntermediateAndFinishedProductsClassificationInputNames.ReferenceMeshSizeForResidue
          }
          metadata={metadata}
          isDisabled={isDisabled}
        />
      </MaterialsGridItem>
    </MaterialsGrid>
  )
}
