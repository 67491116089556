import {Skeleton as MUISkeleton, SkeletonProps as MUISkeletonProps, Paper} from '@mui/material'

export const Skeleton: React.FC<MUISkeletonProps> = (props) => {
  return (
    <MUISkeleton
      variant="rectangular"
      component={Paper}
      sx={{
        backgroundColor: ({palette}) => palette.background.paper
      }}
      {...props}
    />
  )
}
