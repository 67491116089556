import type {Asset} from '@hconnect/common/types'
import {useNotification} from '@hconnect/uikit/src/common'
import {AxiosError} from 'axios'
import {useErrorHandler} from 'react-error-boundary'
import {useMutation} from 'react-query'

import {mutations} from '../../../../api/mutations'
import {getSettingsQueryData, setSettingsQueryData} from '../../../common/providers/ApiInterface'
import type {ErrorResponse} from '../../../common/types'
import {notifyIfErrorMessage} from '../../../common/utils/errorHandling'

export const useAddAsset = (successMessage: string) => {
  const {notify} = useNotification()
  const raiseError = useErrorHandler()

  return useMutation<Asset, AxiosError<ErrorResponse>, Parameters<typeof mutations.addAsset>[0]>(
    mutations.addAsset,
    {
      onError: (error) => {
        notifyIfErrorMessage(error.response?.data.detail, raiseError, notify)
      },
      onSuccess: (asset, variables) => {
        notify('success', successMessage)
        const prevAssets = getSettingsQueryData('assets', [variables.plantCode])
        if (prevAssets) {
          const newAssets = [...prevAssets, asset]
          setSettingsQueryData('assets', [variables.plantCode], newAssets)
        }
      }
    }
  )
}
