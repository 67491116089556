import {
  DepartmentNode,
  EquipmentNode,
  StorageDepartmentNodeKey,
  StorageEquipmentNodeKey,
  TreeNode,
  UpmDepartmentOrEquipmentType,
  UpmEntityType
} from '@settings/modules/common/types'

export const isDepartmentOrEquipmentNode = (
  node?: TreeNode
): node is DepartmentNode | EquipmentNode => {
  return !!node && [UpmEntityType.Department, UpmEntityType.Equipment].includes(node.itemType)
}

/**
 * This helper function is used to determine if the provided department or equipment node is of a storage or an asset type.
 * @param node - UPM tree node
 * @returns UpmDepartmentOrEquipmentType
 */
export const getDepartmentOrEquipmentType = (node: DepartmentNode | EquipmentNode) => {
  const relevantStorageKeyEnum =
    node.itemType === UpmEntityType.Department ? StorageDepartmentNodeKey : StorageEquipmentNodeKey

  const isStorageNode = Object.values(relevantStorageKeyEnum).includes(node.type?.key)

  return isStorageNode
    ? UpmDepartmentOrEquipmentType.MaterialStorage
    : UpmDepartmentOrEquipmentType.Asset
}
