import {MaterialType} from '@hconnect/common/types'
import type {Asset, MaterialWithRecipes, AutocompleteOption} from '@hconnect/common/types'
import {dataTestId} from '@hconnect/uikit'
import {InfoOutlined} from '@mui/icons-material'
import React from 'react'
import {useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useIsMutating} from 'react-query'

import {ControlledAutocomplete, ControlledTextField} from '../../../../common/components'
import {requiredValidator} from '../../../../common/utils'
import {MaterialFormDefaultValues} from '../../materials-details/ExistingMaterialDetails/hooks'
import {MaterialsGrid} from '../components/MaterialsGrid'
import {MaterialsGridItem} from '../components/MaterialsGridItem'

import {MaterialSourceInputs} from './MaterialSourceInputs'

import {Schedule} from '@settings/modules/common/types'

interface MaterialFormProps {
  material: MaterialWithRecipes | undefined
  isReadOnly: boolean
  schedule: Schedule
  assets: Asset[]
  globalMaterialOptions: AutocompleteOption[]
}

export const MaterialForm: React.FC<MaterialFormProps> = ({
  material,
  isReadOnly,
  schedule,
  assets,
  globalMaterialOptions
}) => {
  const {t} = useTranslation()

  const {control} = useFormContext<MaterialFormDefaultValues>()
  const runningMutations = useIsMutating(['editMaterial'])
  const isMaterialSaving = Boolean(runningMutations)

  const isNewMaterial = material === undefined

  return (
    <MaterialsGrid {...dataTestId('add_edit_material_form')}>
      <MaterialsGridItem>
        <ControlledAutocomplete
          control={control}
          formDataName="type"
          options={Object.values(MaterialType)}
          translateOption={(option) => t(`materialsSettings.materialTypes.${option}`)}
          label={t('materialsSettings.type')}
          rules={requiredValidator(t)}
          disabled={isReadOnly || !isNewMaterial || isMaterialSaving}
          {...dataTestId('material_type_input')}
        />
      </MaterialsGridItem>
      {isNewMaterial && (
        <MaterialsGridItem
          direction="row"
          sx={{
            fontSize: '12px',
            lineHeight: '16px',
            color: (theme) => theme.palette.text.secondary
          }}
          {...dataTestId('material_form_tooltip')}
        >
          <InfoOutlined sx={{mr: 1, height: 16, width: 16}} />
          {t('materialsSettings.typeTooltip')}
        </MaterialsGridItem>
      )}
      <MaterialsGridItem>
        <ControlledTextField
          control={control}
          formDataName="name"
          rules={requiredValidator(t)}
          label={t('materialsSettings.localName')}
          TextFieldProps={{
            disabled: isReadOnly || isMaterialSaving,
            sx: ({spacing, palette}) => ({
              '& .MuiInputBase-root': {
                height: spacing(7),
                borderRadius: spacing(1),
                boxShadow: 'none',
                background: palette.background.paper
              }
            })
          }}
          {...dataTestId('material_name_input')}
        />
      </MaterialsGridItem>
      <MaterialsGridItem>
        <ControlledTextField
          control={control}
          formDataName="description"
          label={t('materialsSettings.descritpion')}
          TextFieldProps={{
            disabled: isReadOnly || isMaterialSaving,
            sx: ({spacing, palette}) => ({
              '& .MuiInputBase-root': {
                height: spacing(7),
                borderRadius: spacing(1),
                boxShadow: 'none',
                background: palette.background.paper
              }
            })
          }}
          {...dataTestId('material_description_input')}
        />
      </MaterialsGridItem>
      {!isNewMaterial && (
        <MaterialsGridItem>
          <ControlledAutocomplete
            control={control}
            formDataName="globalMaterial"
            options={globalMaterialOptions}
            label={t('materialsSettings.globalMaterial')}
            disableClearable={false}
            disabled={isReadOnly || isMaterialSaving}
            {...dataTestId('material_global_material_input')}
          />
        </MaterialsGridItem>
      )}
      <MaterialSourceInputs
        material={material}
        schedule={schedule}
        assets={assets}
        disabled={isReadOnly || isMaterialSaving}
      />
    </MaterialsGrid>
  )
}
