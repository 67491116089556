import {AssetType} from '@hconnect/common/types'
import {dataTestId} from '@hconnect/uikit'
import {CheckOutlined} from '@mui/icons-material'
import {MenuItem, TextField} from '@mui/material'
import {useCallback, useRef} from 'react'
import {useTranslation} from 'react-i18next'

import {useAddAsset} from '@settings/modules/assets'
import {useConfirmDialog} from '@settings/modules/common/providers'
import {useSelectedItem} from '@settings/modules/plant-setup/providers'
import {useUrlParam} from '@settings/modules/routing'

export const useHandleEnableForPlanning = () => {
  const {t} = useTranslation()
  const {openDialog} = useConfirmDialog()
  const plantCode = useUrlParam('plantCode')

  const {selectedItem} = useSelectedItem()

  const {mutate: addAsset, isLoading: isAddingAsset} = useAddAsset(
    t('success.enabledAssetForPlanning', {name: selectedItem?.text ?? ''})
  )

  const assetTypeSelector = useRef<HTMLSelectElement>()

  const isEnableForPlanningAllowed = !!selectedItem

  return {
    isAddingAsset,
    handleEnableForPlanning: useCallback(() => {
      if (!isEnableForPlanningAllowed) throw new Error('Enable for planning is not allowed')

      openDialog({
        title: t('janusConfig.plantSetup.enableForPlanning'),
        description: t('janusConfig.plantSetup.enableForPlanningDialog'),
        mainAction: {
          text: t('common.enable'),
          icon: <CheckOutlined />,
          onAction: () => {
            addAsset({
              plantCode,
              name: selectedItem.text ?? '',
              type: assetTypeSelector.current?.value as AssetType,
              upmId: selectedItem.upmId
            })
          }
        },
        additionalContent: (
          <TextField
            inputRef={assetTypeSelector}
            variant="outlined"
            fullWidth
            select
            label={t('assetsSettings.assetType')}
            defaultValue={AssetType.CementMill}
            {...dataTestId('plant-structure-details-asset-type-selector')}
          >
            {Object.values(AssetType)
              .filter((type) => type !== AssetType.BaseLoad)
              .map((type) => (
                <MenuItem
                  key={type}
                  value={type}
                  {...dataTestId(`plant-structure-details-asset-type-selector-${type}`)}
                >
                  {t(`assetsSettings.assetTypes.${type}`)}
                </MenuItem>
              ))}
          </TextField>
        )
      })
    }, [
      isEnableForPlanningAllowed,
      selectedItem?.text,
      selectedItem?.upmId,
      plantCode,
      t,
      openDialog,
      addAsset
    ])
  }
}
