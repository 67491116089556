import {HttpError} from '@hconnect/common/types'
import {AxiosError} from 'axios'
import {useQuery, UseQueryOptions} from 'react-query'

import {Api} from '../../../../../api'
import {SignalListData} from '../../../types/signal'

import {UpmEntityType} from '@settings/modules/common/types'

export const QueryKey = 'signals'

type ApiEntityType = 'plant' | 'area' | 'production-line' | 'department' | 'equipment'

const upmEntityToApiMapping: Record<UpmEntityType, ApiEntityType> = {
  [UpmEntityType.Plant]: 'plant',
  [UpmEntityType.Area]: 'area',
  [UpmEntityType.ProductionLine]: 'production-line',
  [UpmEntityType.Department]: 'department',
  [UpmEntityType.Equipment]: 'equipment'
}

type UseGetSignalsParams = {
  entityType: UpmEntityType
  entityId: string
  searchTerm?: string | null
}

export const useGetSignals = (
  params: UseGetSignalsParams,
  options?: UseQueryOptions<SignalListData[], AxiosError<HttpError>>
) => {
  const {entityType, entityId, searchTerm} = params
  const apiEntityType = upmEntityToApiMapping[entityType]
  return useQuery<SignalListData[], AxiosError<HttpError>>(
    [QueryKey, apiEntityType, entityId, searchTerm],
    async () => {
      const path = `/upm/signal/${apiEntityType}/${entityId}?pageSize=1000${
        searchTerm ? '&Term=' + searchTerm : ''
      }`
      return (await Api.axiosInstance.get(path)).data
    },
    {
      keepPreviousData: true,
      ...options
    }
  )
}
