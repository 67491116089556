import {MaterialType} from '@hconnect/common/types'

import {
  useMaterialsClassificationMetadata,
  useMaterialWithRecipesQuery,
  useMaterialsProductsQuery,
  useLimsMaterialsQuery
} from '../../../hooks'

import {ExistingMaterialDetailsCard} from './ExistingMaterialDetailsCard'

import {SettingsDetailsSkeleton} from '@settings/modules/common/components/skeletons'
import {useUrlParam} from '@settings/modules/routing'

export const PageExistingMaterialDetails: React.FC = () => {
  const materialId = Number(useUrlParam('materialId'))

  const {data: material} = useMaterialWithRecipesQuery(materialId)

  // Calling endpoint to prefetch classification metadata. Material type doesn't matter
  // since later for each material type we call this hook with the relevant material type
  const {data: classificationMetadata} = useMaterialsClassificationMetadata(MaterialType.Cement)

  // Calling endpoint to prefetch material products. Material source doesn't matter
  // since later for each material we call this hook with the relevant material source
  const {data: materialsProducts} = useMaterialsProductsQuery()

  const {data: limsMaterials} = useLimsMaterialsQuery()

  if (!material || !classificationMetadata || !materialsProducts || !limsMaterials) {
    return <SettingsDetailsSkeleton />
  }

  return <ExistingMaterialDetailsCard key={material.id} material={material} />
}
