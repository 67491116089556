import {Stack} from '@mui/material'

import {isDepartmentOrEquipmentNode, isStorageEnabledForPlanning} from '../../helpers'
import {ItemDetailsLayout} from '../../plant-setup-item-layout'
import {useSelectedItem} from '../../providers'

import {DataMartCard} from './dataMart'
import {EmailParserCard} from './emailParser'

import {usePermission} from '@settings/modules/permissions'

export const Connection: React.FC = () => {
  const {selectedItem} = useSelectedItem()
  const canChangeStorages = usePermission('CHANGE_MATERIAL_STORAGE')

  if (
    !isDepartmentOrEquipmentNode(selectedItem) ||
    !isStorageEnabledForPlanning(selectedItem.enabledForPlanningItem)
  )
    return null

  const storage = selectedItem.enabledForPlanningItem

  return (
    <ItemDetailsLayout>
      <Stack spacing={3}>
        <DataMartCard storage={storage} isReadOnly={!canChangeStorages} />
        <EmailParserCard storage={storage} isReadOnly={!canChangeStorages} />
      </Stack>
    </ItemDetailsLayout>
  )
}
