import {useNotification} from '@hconnect/uikit/src/common'
import {AxiosError} from 'axios'
import {useErrorHandler} from 'react-error-boundary'
import {useTranslation} from 'react-i18next'
import {UseQueryResult} from 'react-query'

import {ErrorResponse} from '../../../common/types'
import {notifyIfErrorMessage} from '../../../common/utils/errorHandling'
import {GLOBAL_MEASUREMENT_TYPES_CATEGORIES_QUERY_KEY} from '../../api/queries/getGlobalMeasurementTypesCategories'
import {GlobalMeasurementTypesCategory} from '../../types/GlobalMeasurementTypesCategory'

import {useSettingsQuery} from '@settings/modules/common/providers'

export function useGlobalMeasurementTypesCategoriesQuery(): UseQueryResult<
  GlobalMeasurementTypesCategory[]
> {
  const {t} = useTranslation()
  const {notify} = useNotification()
  const raiseError = useErrorHandler()

  return useSettingsQuery(GLOBAL_MEASUREMENT_TYPES_CATEGORIES_QUERY_KEY, [], {
    select: (globalMeasurementTypesCategories) =>
      globalMeasurementTypesCategories.sort((current, next) => (current.name > next.name ? 1 : -1)),

    onError: (error) => {
      const axiosError = error as AxiosError<ErrorResponse>
      const errorDetails =
        axiosError?.response?.data.detail ?? axiosError.message ?? axiosError.response?.status

      notifyIfErrorMessage(
        t('measurementsSettings.queryError', {
          entity: 'Global Measurement Types Categories',
          details: errorDetails
        }),
        raiseError,
        notify
      )
    }
  })
}
