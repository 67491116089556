import {Feature} from '@hconnect/common/enums'
import {dataTestId, useBreakPoints} from '@hconnect/uikit'
import {Box} from '@mui/material'
import {useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router'

import {PageLayout, SettingsHelmet} from '../../common/components'
import {usePermission} from '../../permissions'
import {RouteName, getUrl, useUrlParam} from '../../routing'
import {SelectedView} from '../enums'

import {HeaderTab, HeaderTabs, generateTabsList} from './Header/HeaderTabs'

import {useSettingsFeature} from '@settings/modules/common/hooks'

export const PageJanusConfig = ({selectedView}: {selectedView?: SelectedView}) => {
  const canReadPlantConfig = usePermission('READ_JANUS_PLANT_CONFIG')
  const canReadUpm = usePermission('VIEW_UPM')
  const canReadData = usePermission('READ_JANUS_PLANT_DATA')
  const janusShowPssTab = useSettingsFeature(Feature.JanusShowPssTab)
  const canReadConfig = canReadPlantConfig || canReadUpm

  const tabs = useMemo(
    () => generateTabsList(canReadConfig, canReadData, janusShowPssTab),
    [canReadConfig, canReadData, janusShowPssTab]
  )

  const [selectedValue, setSelectedValue] = useState<HeaderTab | undefined>()
  const {t} = useTranslation()
  const navigate = useNavigate()

  const plantCode = useUrlParam('plantCode')

  const screenSizes = useBreakPoints()
  const isMobileOrTablet = ['xs', 'sm', 'md'].includes(screenSizes)

  useEffect(() => {
    const selectedTab = tabs.find((tab) => tab.id === selectedView?.valueOf())
    if (!selectedTab) {
      navigate(getUrl(tabs[0].url, {plantCode}))
    }

    setSelectedValue(selectedTab ?? tabs[0])
  }, [navigate, plantCode, selectedView, tabs])

  return (
    <PageLayout
      title={
        <Box
          display="flex"
          flexDirection={isMobileOrTablet ? 'column' : 'row'}
          gap={2}
          alignItems="start"
        >
          {t('janusConfig.tabLabel')}
          <HeaderTabs
            selectedValue={selectedValue}
            onChange={(selected) => {
              setSelectedValue(selected)
              navigate(getUrl(selected.url, {plantCode}))
            }}
          />
        </Box>
      }
      nameHeaderSx={{pb: 2}}
      {...dataTestId(`page_${RouteName.JANUS_CONFIG}`)}
    >
      <SettingsHelmet title={t('janusConfig.pageTitle')} />
      {selectedValue?.view}
    </PageLayout>
  )
}
