import {useMemo} from 'react'

import {ItemDetailsLayout} from '../../plant-setup-item-layout'

import {EnabledForPlanningItemData} from './EnabledForPlanningItemData'
import {PlantData} from './PlantData'

import {TreeNode, UpmEntityType} from '@settings/modules/common/types'

interface GeneralProps {
  selectedItem: TreeNode
}

export const General: React.FC<GeneralProps> = ({selectedItem}) => {
  const GeneralTabContentComponent = useMemo(() => {
    switch (selectedItem.itemType) {
      case UpmEntityType.Plant:
        return PlantData
      case UpmEntityType.Department:
        return EnabledForPlanningItemData
      case UpmEntityType.Equipment:
        return EnabledForPlanningItemData
      default:
        throw new Error(`BUG: Unknown entity type for the general tab: ${selectedItem.itemType}`)
    }
  }, [selectedItem.itemType])

  return (
    <ItemDetailsLayout>
      <GeneralTabContentComponent selectedItem={selectedItem} />
    </ItemDetailsLayout>
  )
}
