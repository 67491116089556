import {Asset, MaterialStorage} from '@hconnect/common'

import {getDepartmentOrEquipmentType} from './getDepartmentOrEquipmentType'

import {
  DepartmentNode,
  EquipmentNode,
  UpmDepartmentOrEquipmentType
} from '@settings/modules/common/types'

export const isAssetEnabledForPlanning = (
  enabledForPlanningItem?: Asset | MaterialStorage
): enabledForPlanningItem is Asset =>
  (enabledForPlanningItem as Asset)?.stopCoefficient !== undefined

export const isStorageEnabledForPlanning = (
  enabledForPlanningItem?: Asset | MaterialStorage
): enabledForPlanningItem is MaterialStorage =>
  (enabledForPlanningItem as MaterialStorage)?.capacity !== undefined

/**
 * This helper function is used to fetch enabled for planning item (either asset or material storage)
 * @param node - UPM tree node
 * @param assets - list of assets present in plant
 * @param materialStorages - list of material storages present in plant
 * @returns either Asset or MaterialStorage if found, otherwise undefined
 */
export const getEnabledForPlanningItem = (
  node: DepartmentNode | EquipmentNode,
  assets: Asset[],
  materialStorages: MaterialStorage[]
) => {
  const departmentOrEquipmentType = getDepartmentOrEquipmentType(node)

  if (departmentOrEquipmentType === UpmDepartmentOrEquipmentType.MaterialStorage) {
    return materialStorages.find((storage) => storage.upmId === node.upmId)
  }

  return assets.find((asset) => asset.upmId === node.upmId)
}
