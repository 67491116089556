import {dataTestId} from '@hconnect/uikit'
import {Card} from '@hconnect/uikit/src/lib2'
import {Alert, Box, Stack} from '@mui/material'
import React from 'react'
import {ErrorBoundary} from 'react-error-boundary'
import {useTranslation} from 'react-i18next'

import {useItemDetails} from '../providers'

import {ItemDetailsButtonProps, ItemDetailsLayoutHeader} from './ItemDetailsLayoutHeader'

import {HeaderTabs} from '@settings/modules/common/components/HeaderTabs'

type ItemDetailsLayoutProps = {
  children: React.ReactNode
  hideNav?: boolean
  title?: string
  titleAction?: {
    label: string
    onClick: () => void
  }
  revertButton?: ItemDetailsButtonProps
  saveButton?: ItemDetailsButtonProps
}

export const ItemDetailsLayout = ({
  children,
  hideNav,
  title,
  titleAction,
  revertButton,
  saveButton
}: ItemDetailsLayoutProps) => {
  const {availableTabs, handleTabChange, selectedTab} = useItemDetails()
  return (
    <Card
      headerContent={
        <ItemDetailsLayoutHeader
          title={title}
          titleAction={titleAction}
          revertButton={revertButton}
          saveButton={saveButton}
        />
      }
      {...dataTestId('janus-plant-structure-details-card')}
    >
      <Stack spacing={3}>
        {!hideNav && selectedTab && (
          <HeaderTabs
            tabs={availableTabs}
            selectedTab={selectedTab}
            onTabChange={handleTabChange}
            testIdPrefix={'plant-structure'}
            sxTabs={{mb: 3}}
            sxTab={{color: ({palette}) => palette.common.black, fontWeight: 500}}
          />
        )}
        <Box>
          <ErrorBoundary FallbackComponent={TabContentFallback}>{children}</ErrorBoundary>
        </Box>
      </Stack>
    </Card>
  )
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const TabContentFallback = ({error, resetErrorBoundary}) => {
  const {t} = useTranslation()

  if (error?.response?.status === 403) {
    return <Alert severity="error">{t('error.accessDeniedDescriptionShort')}</Alert>
  }
  return <Alert severity="error">{t('error.apology')}</Alert>
}
