import {MaterialWithRecipes} from '@hconnect/common/types'
import React from 'react'

import {usePermission} from '../../../../permissions'
import {useGlobalMaterialsQuery} from '../../../hooks'

import {MaterialForm} from './MaterialForm'

import {useAssetsQuery} from '@settings/modules/assets'
import {Skeleton} from '@settings/modules/common/components/skeletons'
import {useScheduleQuery} from '@settings/modules/common/hooks'

interface MaterialSettingsGeneralTabProps {
  material: MaterialWithRecipes | undefined
}

export const MaterialSettingsGeneralTab: React.FC<MaterialSettingsGeneralTabProps> = ({
  material
}) => {
  const canChangeMaterials = usePermission('CHANGE_MATERIALS')

  const {data: schedule} = useScheduleQuery()
  const {data: assets} = useAssetsQuery()

  const {data: globalMaterialOptions} = useGlobalMaterialsQuery(material?.type)
  if (!globalMaterialOptions) {
    throw new Error('BUG: global materials should be loaded before rendering general tab')
  }

  return assets && schedule ? (
    <MaterialForm
      material={material}
      key={material?.id ?? 'new'}
      isReadOnly={!canChangeMaterials}
      schedule={schedule}
      assets={assets}
      globalMaterialOptions={globalMaterialOptions}
    />
  ) : (
    <Skeleton
      height="15vh"
      sx={{
        backgroundColor: ({palette}) => palette.grey[200]
      }}
    />
  )
}
