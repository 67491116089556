import {dataTestId} from '@hconnect/uikit'
import {Card} from '@hconnect/uikit/src/lib2'
import {Stack} from '@mui/material'

import {TextWithCaption} from '../../common/components/TextWithCaption/TextWithCaption'
import {ExtendedGlobalMeasurementTypesCategory} from '../types/GlobalMeasurementTypesCategory'
import {LimsMeasurementType} from '../types/LimsMeasurementType'
import {createMeasurementTypesCategoryTitle} from '../utils'

import {LimsMeasurementTypesForm} from './LimsMeasurementTypesForm/LimsMeasurementTypesForm'

import {usePermission} from '@settings/modules/permissions'
import {useUrlParam} from '@settings/modules/routing'

interface MeasurementTypesCardProps {
  selectedMeasurementTypesCategory: ExtendedGlobalMeasurementTypesCategory
  unmappedLimsMeasurementTypes: LimsMeasurementType[]
}

export function MeasurementTypesCard({
  selectedMeasurementTypesCategory,
  unmappedLimsMeasurementTypes
}: MeasurementTypesCardProps): JSX.Element {
  const plantCode = useUrlParam('plantCode')
  const canChangeMappings = usePermission('CHANGE_MATERIALS')
  return (
    <Card
      headerContent={
        <TextWithCaption
          text={createMeasurementTypesCategoryTitle(selectedMeasurementTypesCategory)}
          {...dataTestId('measurements_types_card_header')}
        />
      }
      {...dataTestId('measurements_types_card')}
    >
      <Stack spacing={3}>
        {selectedMeasurementTypesCategory.globalMeasurementTypes.map((globalType) => (
          <LimsMeasurementTypesForm
            key={globalType.id}
            globalMeasurementType={globalType}
            unmappedLimsMeasurementTypes={unmappedLimsMeasurementTypes}
            canChangeMappings={canChangeMappings}
            plantCode={plantCode}
          />
        ))}
      </Stack>
    </Card>
  )
}
