import {TreeNode, UpmEntityType} from '../../types'

export const removeProductionLinesFromTree = <T extends TreeNode>(node: T): T => {
  if (node.itemType === UpmEntityType.ProductionLine) {
    return node.children.flatMap(removeProductionLinesFromTree) as unknown as T
  }

  return {
    ...node,
    children: node.children.flatMap(removeProductionLinesFromTree) ?? []
  }
}
