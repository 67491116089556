import type {Asset, OperationMode} from '@hconnect/common/types'
import type {AlertProps} from '@hconnect/uikit/src/lib2'
import React from 'react'
import {Trans} from 'react-i18next'

import {InfoGroup} from '@settings/modules/common/components'
import {ScheduleItem} from '@settings/modules/common/types'

interface DeleteFromPlanningInfoProps {
  asset: Asset
  operationModes: OperationMode[]
  scheduleItems: ScheduleItem[]
}

function cutStringTo40Chars(str: string) {
  return str.length <= 40 ? str : `${str.substring(0, 37)}...`
}

export const DeleteFromPlanningInfo: React.FC<DeleteFromPlanningInfoProps> = ({
  asset,
  operationModes,
  scheduleItems
}) => {
  const operationModesStr = cutStringTo40Chars(operationModes.map((mode) => mode.name).join(', '))

  const infoPanels: AlertProps[] = []

  if (operationModes.length > 0) {
    infoPanels.push({
      accentColor: 'warning',
      content: (
        <Trans
          i18nKey="assetsSettings.deleteAssetAssignedOperationModes"
          values={{
            operation_mode_count: operationModes.length,
            operation_modes: operationModesStr
          }}
        />
      )
    })
  }

  if (scheduleItems.length > 0) {
    infoPanels.push({
      accentColor: 'warning',
      content: (
        <Trans
          i18nKey="assetsSettings.deleteOperationModesAssignedScheduleItems"
          values={{
            number: scheduleItems.length
          }}
        />
      )
    })
  }

  return (
    <InfoGroup
      description={
        <Trans
          i18nKey="janusConfig.plantSetup.deleteFromPlanningWarning"
          values={{name: asset.name}}
        />
      }
      panels={infoPanels}
    />
  )
}
