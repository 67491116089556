import {GlobalMeasurementType, ExtendedGlobalMeasurementType} from '../types/GlobalMeasurementType'
import {
  GlobalMeasurementTypesCategory,
  ExtendedGlobalMeasurementTypesCategory
} from '../types/GlobalMeasurementTypesCategory'
import {LimsMeasurementType, ExtendedLimsMeasurementType} from '../types/LimsMeasurementType'
import {MeasurementTypesMapping} from '../types/MeasurementTypesMapping'

export function getExtendedMeasurementTypesCategories(
  globalMeasurementTypesCategories?: GlobalMeasurementTypesCategory[],
  globalMeasurementTypes?: GlobalMeasurementType[],
  limsMeasurementTypes?: LimsMeasurementType[],
  measurementTypesMappings?: MeasurementTypesMapping[]
) {
  if (
    globalMeasurementTypesCategories === undefined ||
    globalMeasurementTypes === undefined ||
    limsMeasurementTypes === undefined ||
    measurementTypesMappings === undefined
  )
    return []

  return globalMeasurementTypesCategories.map<ExtendedGlobalMeasurementTypesCategory>(
    (category) => ({
      ...category,
      globalMeasurementTypes: globalMeasurementTypes
        .filter((globalType) => globalType.category === category.name)
        .map<ExtendedGlobalMeasurementType>((globalType) => ({
          ...globalType,
          limsMeasurementTypes: measurementTypesMappings
            .filter((mapping) => mapping.globalMeasurementTypeId === globalType.id)
            .map<ExtendedLimsMeasurementType>((mapping) => ({
              mappingId: mapping.id,
              ...(limsMeasurementTypes.find(
                (limsType) => mapping.limsMeasurementTypeId === limsType.id
              ) as LimsMeasurementType)
            }))
        }))
    })
  )
}
