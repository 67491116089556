import {Asset} from '@hconnect/common'
import {dataTestId} from '@hconnect/uikit'
import {MoreVert, DownloadDone, DoDisturb} from '@mui/icons-material'
import {LoadingButton} from '@mui/lab'
import {Stack, Divider, Menu, MenuItem} from '@mui/material'
import {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {useHandleDeleteFromPlanning, useHandleEnableForPlanning} from './hooks'

import {useScheduleQuery} from '@settings/modules/common/hooks'
import {usePermission} from '@settings/modules/permissions'

interface ItemDetailsPlanningMenuProps {
  asset?: Asset
}

export const ItemDetailsPlanningMenu: React.FC<ItemDetailsPlanningMenuProps> = ({asset}) => {
  const {t} = useTranslation()
  const canChangeAssets = usePermission('CHANGE_ASSETS')

  const {data: schedule} = useScheduleQuery()

  const {isAddingAsset, handleEnableForPlanning} = useHandleEnableForPlanning()
  const {isDeletingAsset, handleDeleteFromPlanning} = useHandleDeleteFromPlanning(asset, schedule)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const isMenuOpen = !!anchorEl

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const isPlanningMenuDisabled = !canChangeAssets || !schedule
  const isEnableForPlanningDisabled = !!asset
  const isDeleteFromPlanningDisabled = !asset

  return (
    <>
      <LoadingButton
        loading={isAddingAsset || isDeletingAsset}
        disabled={isPlanningMenuDisabled}
        onClick={handleMenuOpen}
        sx={({spacing, palette}) => ({
          minWidth: spacing(5.5),
          minHeight: spacing(5.5),
          padding: 0,
          backgroundColor: isMenuOpen ? palette.action.hover : 'none'
        })}
        {...dataTestId('plant-structure-details-planning-menu-button')}
      >
        <MoreVert fontSize="small" />
      </LoadingButton>

      <Menu
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        sx={{marginTop: 1}}
        {...dataTestId('plant-structure-details-planning-menu')}
      >
        <MenuItem
          disabled={isEnableForPlanningDisabled}
          onClick={() => {
            handleEnableForPlanning()
            handleMenuClose()
          }}
          sx={({palette}) => ({
            color: palette.primary.main,
            '&.Mui-disabled .MuiSvgIcon-root': {
              color: palette.primary.main
            }
          })}
          {...dataTestId('plant-structure-details-enable-for-planning-button')}
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <DownloadDone fontSize="inherit" />
            <span>{t('janusConfig.plantSetup.enableForPlanning')}</span>
          </Stack>
        </MenuItem>
        <Divider variant="middle" />
        <MenuItem
          disabled={isDeleteFromPlanningDisabled}
          onClick={() => {
            handleDeleteFromPlanning()
            handleMenuClose()
          }}
          sx={({palette}) => ({
            color: palette.error.main,
            '&.Mui-disabled .MuiSvgIcon-root': {
              color: palette.error.main
            }
          })}
          {...dataTestId('plant-structure-details-delete-from-planning-button')}
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <DoDisturb fontSize="inherit" />
            <span>{t('janusConfig.plantSetup.deleteFromPlanning')}</span>
          </Stack>
        </MenuItem>
      </Menu>
    </>
  )
}
