import type {OperationMode} from '@hconnect/common/types'
import {dataTestId, useTranslation} from '@hconnect/uikit'
import {NumberLetterSequenceIndicator} from '@hconnect/uikit/src/lib2'
import {Stack, Typography} from '@mui/material'
import React from 'react'

import {DeleteButton, EditButton} from '../../../common/components'
import {useLatestData} from '../../../common/hooks/useLatestData'
import type {AssetStandardOperationTimeData} from '../../types'
import {
  StandartOperationTimeEnds,
  StandartOperationTimeRepetition
} from '../../types/standardOperationTimes'
import {getDayOfWeek} from '../../utils/getDayOfWeek'

import {NextScheduledItemsOccurencesPreview} from './common/NextScheduledItemsOccurencesPreview'

interface StandardOperationTimeFormProps {
  index: number
  operationTime: AssetStandardOperationTimeData
  operationModes: OperationMode[]
  isReadOnly: boolean
  onDelete: () => void
  onEdit: () => void
}

export const StandardOperationTimePreview: React.FC<StandardOperationTimeFormProps> = ({
  index,
  operationTime,
  operationModes,
  onDelete,
  onEdit,
  isReadOnly
}) => {
  const {t} = useTranslation()
  const {timezone_id} = useLatestData()

  // note: instead of using single translation we do use multiple translations similar to StandardOperationTimeAddOrEditDialog.tsx
  const endsText: string = [
    t('assetsSettings.standardOperationTimes.ends'),
    t(
      `assetsSettings.standardOperationTimes.endsValues.${operationTime.calendarEvent.recurrenceRule.ends}`
    ),
    operationTime.calendarEvent.recurrenceRule.ends === StandartOperationTimeEnds.after &&
      `${operationTime.calendarEvent.recurrenceRule.count} ${t(
        'assetsSettings.standardOperationTimes.occurrences'
      )}`,
    operationTime.calendarEvent.recurrenceRule.ends === StandartOperationTimeEnds.on &&
      operationTime.calendarEvent.recurrenceRule.until.tz(timezone_id).format('LLL')
  ]
    .filter((e) => e)
    .join(' ')

  return (
    <Stack spacing={2} {...dataTestId('standard_operation_times_preview')}>
      <Stack spacing={2} direction="row" alignItems="start">
        <Stack spacing={2} direction="row" alignItems="baseline">
          <NumberLetterSequenceIndicator numberIndex={index} />
          <Stack direction="column" spacing={0.5} sx={{width: (theme) => theme.spacing(50)}}>
            <Typography variant="subtitle1" {...dataTestId('operation_mode')}>
              {operationModes.find((e) => e.id === operationTime.operationModeId)?.name}
            </Typography>
            <Typography variant="subtitle1">
              <span {...dataTestId('start_datetime')}>
                {operationTime.calendarEvent.start.tz(timezone_id).format('LLL')}
              </span>{' '}
              -{' '}
              <span {...dataTestId('end_datetime')}>
                {operationTime.calendarEvent.end.tz(timezone_id).format('LLL')}
              </span>
            </Typography>
            <Typography variant="subtitle1">
              {t('assetsSettings.standardOperationTimes.repeatsEvery', {
                interval: operationTime.calendarEvent.recurrenceRule.interval,
                period: t(
                  `assetsSettings.standardOperationTimes.schedulingPeriod.${operationTime.calendarEvent.recurrenceRule.freq}`
                )
              })}
            </Typography>
            <Typography variant="subtitle1">
              {t(
                operationTime.isFixed
                  ? 'assetsSettings.standardOperationTimes.fixedForOptimizer'
                  : 'assetsSettings.standardOperationTimes.notFixedForOptimizer'
              )}
            </Typography>
            {operationTime.calendarEvent.recurrenceRule.freq ===
              StandartOperationTimeRepetition.WEEKLY && (
              <Typography variant="subtitle1" {...dataTestId('weekdays')}>
                {Object.entries(operationTime.calendarEvent.recurrenceRule.daysOfWeek)
                  .map(([day, is]) => (is ? getDayOfWeek(Number(day)) : null))
                  .filter((e) => e)
                  .join(' ')}
              </Typography>
            )}
            <Typography variant="subtitle1">{endsText}</Typography>
            <NextScheduledItemsOccurencesPreview occurrences={operationTime.occurrences} />
          </Stack>
        </Stack>
        <Stack pt={1} direction="row" spacing={4}>
          <EditButton
            disabled={isReadOnly}
            onClick={onEdit}
            {...dataTestId('edit_standard_operation_time')}
          />
          <DeleteButton
            disabled={isReadOnly}
            onClick={onDelete}
            {...dataTestId('delete_standard_operation_time')}
          />
        </Stack>
      </Stack>
    </Stack>
  )
}
