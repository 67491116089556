import {dataTestId} from '@hconnect/uikit'
import {Box, Typography} from '@mui/material'
import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {getNextScheduledOccurences} from '@settings/modules/assets'
import {StandardOperationTime} from '@settings/modules/assets/types/standardOperationTimes'
import {useCurrentTime} from '@settings/modules/common/hooks/useCurrentTime'
import {useLatestData} from '@settings/modules/common/hooks/useLatestData'

type NextOccurencesPreviewProps = {
  standardOperationTime: StandardOperationTime
  testId?: string
}

const DISPLAY_NEXT_OCCURENCES_LIMIT = 5

export const NextOccurencesPreview: React.FC<NextOccurencesPreviewProps> = ({
  standardOperationTime,
  testId = 'next-occurrences-preview'
}) => {
  const {t} = useTranslation()
  const {timezone_id: timezoneId} = useLatestData()

  const currentTime = useCurrentTime({timezoneId})
  const occurrences = useMemo(
    () =>
      getNextScheduledOccurences({
        standardOperationTime,
        count: DISPLAY_NEXT_OCCURENCES_LIMIT + 1,
        timezone_id: timezoneId,
        now: currentTime
      }),
    [currentTime, standardOperationTime, timezoneId]
  )

  if (occurrences.length === 0) {
    return (
      <Typography color="error" variant="caption" {...dataTestId(testId)}>
        {t('assetsSettings.standardOperationTimes.noUpcomingOccurrences')}
      </Typography>
    )
  } else {
    const hasMore = occurrences.length >= DISPLAY_NEXT_OCCURENCES_LIMIT
    const visibleOccurences = occurrences
      .slice(0, DISPLAY_NEXT_OCCURENCES_LIMIT - 1)
      .map((occurence) => occurence.format('LLL'))
    const displayItems = [...visibleOccurences, hasMore ? '...' : undefined].filter((e) => e)
    return (
      <Typography variant="caption" {...dataTestId(testId)}>
        {t('assetsSettings.standardOperationTimes.nextOccurrences')}
        {displayItems.map((e, i) => (
          <React.Fragment key={i}>
            {i <= 0 ? ' ' : ', '}
            <Box sx={{display: 'inline-block'}}>{e}</Box>
          </React.Fragment>
        ))}
      </Typography>
    )
  }
}
