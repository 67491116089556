import {InfoOutlined} from '@mui/icons-material'
import {Box, BoxProps, Tooltip, Typography} from '@mui/material'
import Markdown from 'markdown-to-jsx'

type OptimizerConstraintsProps = {
  constraintsMarkdown: string
} & BoxProps

export const OptimizerConstraintsInfo = ({
  constraintsMarkdown,
  ...props
}: OptimizerConstraintsProps) => {
  return (
    <Tooltip
      componentsProps={{
        tooltip: {
          sx: {
            maxWidth: (theme) => theme.spacing(50)
          }
        }
      }}
      title={
        <Typography variant="body1">
          <Markdown>{constraintsMarkdown}</Markdown>
        </Typography>
      }
    >
      <Box {...props} sx={{display: 'inline-block'}}>
        <InfoOutlined
          sx={{
            verticalAlign: 'middle',
            fontSize: 'inherit'
          }}
        />
      </Box>
    </Tooltip>
  )
}
