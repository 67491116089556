import {MaterialStorage, Material, MaterialWithRecipes} from '@hconnect/common/types'
import type {AlertProps} from '@hconnect/uikit/src/lib2'
import {compact} from 'lodash'
import {useCallback} from 'react'
import {Trans, useTranslation} from 'react-i18next'

import {InfoGroup} from '@settings/modules/common/components'
import {useConfirmDialog} from '@settings/modules/common/providers'

export const useHandleIsMeasuredChange = (
  storage: MaterialStorage,
  allStorages?: MaterialStorage[],
  materialWithRecipes?: MaterialWithRecipes,
  materials?: Material[]
) => {
  const {t} = useTranslation()
  const {openDialog} = useConfirmDialog()

  return useCallback(
    (newIsMeasured: boolean, onIsMeasuredChange: (newIsMeasured: boolean) => void) => {
      // Going from non measured to measured doesn't have any restrictions
      if (newIsMeasured) {
        return onIsMeasuredChange(newIsMeasured)
      }

      const attachedMaterial = materials?.find((material) => material.id === storage.materialId)

      const storagesWithSameMaterial = allStorages?.filter(
        (e) => e.id !== storage.id && e.materialId === storage.materialId
      )

      const errorPanels: AlertProps[] = compact([
        attachedMaterial &&
          (materialWithRecipes?.products.length || 0) === 0 && {
            accentColor: 'warning',
            content: (
              <Trans
                i18nKey="storagesSettings.changeUnmeasuredNoMaterialCodeInfo"
                values={{
                  material: attachedMaterial.name
                }}
              />
            )
          },
        attachedMaterial &&
          (!storagesWithSameMaterial || storagesWithSameMaterial.length > 0) && {
            accentColor: 'warning',
            content: (
              <Trans
                i18nKey="storagesSettings.changeUnmeasuredAttachedMaterialInOtherStorages"
                values={{
                  material: attachedMaterial.name,
                  other_storages: storagesWithSameMaterial
                    ?.map((storage) => storage.name)
                    .join(', ')
                }}
              />
            )
          },
        attachedMaterial &&
          attachedMaterial.pxTrendCounters !== undefined &&
          attachedMaterial.pxTrendCounters.length > 0 && {
            accentColor: 'warning',
            content: (
              <Trans
                i18nKey="storagesSettings.changeUnmeasuredAttachedMaterialHasPxtrend"
                values={{
                  material: attachedMaterial.name
                }}
              />
            )
          },
        // Material storage has email sync enabled
        storage.emailSyncDetails && {
          accentColor: 'warning',
          content: (
            <Trans
              i18nKey="storagesSettings.changeUnmeasuredMaterialStorageHasEmailSyncEnabled"
              values={{
                storage: storage.name
              }}
            />
          )
        }
      ])

      if (errorPanels.length === 0) {
        return onIsMeasuredChange(newIsMeasured)
      }

      openDialog({
        testId: 'material_change_is_measured_not_possible_dialog',
        title: t('storagesSettings.changeIsMeasuredNotPossible'),
        additionalContent: (
          <InfoGroup
            description={
              <Trans i18nKey="storagesSettings.changeIsMeasuredNotPossibleDescription" />
            }
            panels={errorPanels}
          />
        ),
        showCancelButton: false,
        mainAction: {
          text: t('common.ok')
        }
      })
    },
    [storage, allStorages, materialWithRecipes, materials, t, openDialog]
  )
}
