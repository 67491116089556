import {Asset} from '@hconnect/common'
import {dataTestId} from '@hconnect/uikit'
import {Alert} from '@hconnect/uikit/src/lib2'
import {
  SettingsBackupRestoreOutlined as SettingsBackupRestoreOutlinedIcon,
  CheckOutlined as CheckOutlinedIcon
} from '@mui/icons-material'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import {LoadingButton, LoadingButtonProps} from '@mui/lab'
import {Button, Stack, Typography} from '@mui/material'
import {useTranslation} from 'react-i18next'

import {isDepartmentOrEquipmentNode} from '../../helpers'
import {useSelectedItem} from '../../providers'

import {ItemDetailsPlanningMenu} from './ItemDetailsPlanningMenu'

import {UpmDepartmentOrEquipmentType} from '@settings/modules/common/types'

type ItemDetailsButtonShow = {
  onClick: () => void
  hide: false
  disabled?: boolean
  ButtonProps?: Omit<LoadingButtonProps, 'variant' | 'startIcon' | 'onClick' | 'disabled'>
}

type ItemDetailsButtonHide = {
  hide: true
}

export type ItemDetailsButtonProps = ItemDetailsButtonShow | ItemDetailsButtonHide

interface ItemDetailsLayoutHeaderProps {
  title?: string
  titleAction?: {
    label: string
    onClick: () => void
  }
  revertButton?: ItemDetailsButtonProps
  saveButton?: ItemDetailsButtonProps
}

export const ItemDetailsLayoutHeader: React.FC<ItemDetailsLayoutHeaderProps> = ({
  title,
  titleAction,
  revertButton,
  saveButton
}) => {
  const {t} = useTranslation()

  const {selectedItem} = useSelectedItem()

  const canAssetBeEnabledForPlanning =
    isDepartmentOrEquipmentNode(selectedItem) &&
    selectedItem.departmentOrEquipmentType === UpmDepartmentOrEquipmentType.Asset

  const shouldDisplayEnableAssetForPlanningWarning =
    canAssetBeEnabledForPlanning && !selectedItem.enabledForPlanningItem

  const shouldRevertButtonBeDisplayed = revertButton && !revertButton.hide
  const shouldSaveButtonBeDisplayed = saveButton && !saveButton.hide

  return (
    <Stack spacing={3}>
      <Stack flexDirection="row" justifyContent="space-between">
        <Stack>
          {titleAction && (
            <Button
              onClick={titleAction.onClick}
              startIcon={<ChevronLeftIcon />}
              variant="text"
              sx={{fontWeight: 500, p: 0.5, pl: 0, justifyContent: 'flex-start', display: 'flex'}}
              size="small"
            >
              {titleAction.label}
            </Button>
          )}
          <Typography variant="h3" {...dataTestId('janus-structure-details-title')}>
            {title ?? selectedItem?.text}
          </Typography>
        </Stack>
        <Stack flexDirection="row" alignItems="flex-start" gap={1}>
          {shouldRevertButtonBeDisplayed && (
            <LoadingButton
              {...dataTestId('plant-structure-details-revert-button')}
              variant="outlined"
              startIcon={<SettingsBackupRestoreOutlinedIcon />}
              onClick={revertButton.onClick}
              disabled={revertButton.disabled || !revertButton?.onClick}
              {...revertButton.ButtonProps}
            >
              {t('janusConfig.action.revert')}
            </LoadingButton>
          )}
          {shouldSaveButtonBeDisplayed && (
            <LoadingButton
              {...dataTestId('plant-structure-details-save-button')}
              variant="contained"
              startIcon={<CheckOutlinedIcon />}
              onClick={saveButton.onClick}
              disabled={saveButton.disabled || !saveButton?.onClick}
              {...saveButton.ButtonProps}
            >
              {t('janusConfig.action.save')}
            </LoadingButton>
          )}
          {canAssetBeEnabledForPlanning && (
            <ItemDetailsPlanningMenu
              asset={selectedItem.enabledForPlanningItem as Asset | undefined}
            />
          )}
        </Stack>
      </Stack>
      {shouldDisplayEnableAssetForPlanningWarning && (
        <Alert
          accentColor="warning"
          content={t('janusConfig.plantSetup.enableForPlanningWarning')}
          {...dataTestId('janus-structure-details-enable-for-planning-warning')}
        />
      )}
    </Stack>
  )
}
