import {ErrorResponse} from '../../common/types'

import {ScheduleItem} from './schedule'
import {StandardOperationTime} from './standardOperationTimes'

export interface AssetStandardOperationTimeValidationData {
  errorMessage: string
  conflictItems: ScheduleItem[]
}

export interface AssetStandardOperationTimeOccurences {
  scheduleItems: Omit<ScheduleItem, 'selectedSilos' | 'isFixed'>[]
  hasMore: boolean
}

export interface AssetStandardOperationTime {
  id: number
  calendarEvent: string
  operationModeId: number
  occurrences: AssetStandardOperationTimeOccurences,
  isFixed: boolean
}

export enum AssetStandardOperationTimeConflictResolutionAction {
  Skip = 'Skip',
  Overwrite = 'Overwrite',
  Error = 'Error'
}

export type AssetStandardOperationTimeValidationError = ErrorResponse<
  string,
  string,
  AssetStandardOperationTimeValidationData | undefined
>

export interface AssetsStandardOperationTimesData {
  [assetId: string]: AssetStandardOperationTimeData[]
}

export interface AssetsStandardOperationTimes {
  [assetId: string]: AssetStandardOperationTime[]
}

export type AssetStandardOperationTimePayload = Omit<
  AssetStandardOperationTime,
  'id' | 'occurrences'
> & {
  assetId: number
}

export type AssetStandardOperationTimeData = Omit<
  AssetStandardOperationTime,
  'calendarEvent' | 'assetId'
> & {
  calendarEvent: StandardOperationTime
}
export type NewAssetStandardOperationTimeData = Omit<
  AssetStandardOperationTime,
  'calendarEvent' | 'id' | 'occurrences'
> & {
  calendarEvent: StandardOperationTime
}
