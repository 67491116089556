import {MaterialStorage, MaterialStorageDTO} from '@hconnect/common/types'
import {dataTestId} from '@hconnect/uikit'
import {Loader} from '@hconnect/uikit/src/lib2'
import {TextField, Grid, MenuItem} from '@mui/material'
import {Controller, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {useHandleIsMeasuredChange} from './hooks'

import {NumberInput, Switch} from '@settings/modules/common/components'
import {
  minValidator,
  requiredValidator,
  submitOnBlurAndEnterProps
} from '@settings/modules/common/utils'
import {useMaterialsQuery, useMaterialWithRecipesQuery} from '@settings/modules/materials/hooks'
import {useUrlParam} from '@settings/modules/routing'
import {useEditStorage, useMaterialStorageListQuery} from '@settings/modules/storages/hooks'

interface EnabledForPlanningStorageProps {
  storage: MaterialStorage
  isReadOnly: boolean
}

export const EnabledForPlanningStorage = ({
  storage,
  isReadOnly
}: EnabledForPlanningStorageProps) => {
  const {t} = useTranslation()
  const plantCode = useUrlParam('plantCode')

  const {data: materials, isLoading: areMaterialsLoading} = useMaterialsQuery(storage.materialType)
  const {data: allStorages, isLoading: areStoragesLoading} = useMaterialStorageListQuery(
    storage.materialType
  )
  const {data: materialWithRecipes, isLoading: isStoredMaterialLoading} =
    useMaterialWithRecipesQuery(storage.materialId ?? undefined)

  const {mutate: editStorage, isLoading: isStorageEditing} = useEditStorage()

  const {
    handleSubmit,
    control,
    reset,
    formState: {isDirty, dirtyFields}
  } = useForm<MaterialStorageDTO>({
    mode: 'onChange',
    shouldFocusError: false,
    defaultValues: storage
  })

  const submit = handleSubmit((values) => {
    if (!isDirty) return

    const keys = Object.keys(dirtyFields).map((key) => key as keyof MaterialStorageDTO)
    keys.forEach((key) => {
      editStorage(
        {plantCode, id: storage.id, dto: values, key},
        {
          onError: () => reset(),
          onSuccess: (storage) => {
            reset(storage)
          }
        }
      )
    })
  })

  // If storage doesn't have a stored material --> isMeasured switch should be disabled
  const isMeasuredDisabled = materialWithRecipes === undefined
  const handleIsMeasuredChange = useHandleIsMeasuredChange(
    storage,
    allStorages,
    materialWithRecipes,
    materials
  )

  if (areMaterialsLoading || areStoragesLoading || isStoredMaterialLoading) {
    return <Loader />
  }

  return (
    <Grid container sx={{width: ({spacing}) => spacing(56)}} spacing={2}>
      <Grid item xs={12}>
        <Controller
          control={control}
          name="name"
          rules={requiredValidator(t)}
          render={({field: {ref, value, onChange}, fieldState: {error}}) => (
            <TextField
              fullWidth
              variant="outlined"
              disabled={isReadOnly || isStorageEditing}
              label={t('storagesSettings.storageName')}
              inputRef={ref}
              value={value}
              onChange={onChange}
              helperText={error?.message}
              error={Boolean(error?.message)}
              {...submitOnBlurAndEnterProps(submit)}
              {...dataTestId('storage_name_input')}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          control={control}
          name="materialId"
          render={({field: {ref, value, onChange}, fieldState: {error}}) => (
            <TextField
              fullWidth
              select
              inputRef={ref}
              variant="outlined"
              label={t('storagesSettings.storedMaterial')}
              value={value ?? ''}
              onChange={(e) => onChange(e.target.value !== '' ? e.target.value : null)}
              helperText={error?.message}
              error={Boolean(error?.message)}
              disabled={isReadOnly || isStorageEditing}
              {...submitOnBlurAndEnterProps(submit)}
              {...dataTestId('stored_material_select')}
            >
              {/* TODO extract to component? */}
              <MenuItem key={'no_material'} value="" {...dataTestId('material_select_item')}>
                {t('storagesSettings.noSelectedMaterial')}
              </MenuItem>
              {materials?.map((material) => (
                <MenuItem
                  key={material.id}
                  value={material.id}
                  {...dataTestId('material_select_item')}
                >
                  {material.name}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          control={control}
          name="capacity"
          rules={{...requiredValidator(t), ...minValidator(t, 0)}}
          render={({field: {ref, value, onChange}, fieldState: {error}}) => (
            <NumberInput
              inputRef={ref}
              label={t('storagesSettings.capacity')}
              step={10}
              onChange={onChange}
              value={value}
              error={error?.message}
              disabled={isReadOnly || isStorageEditing}
              {...submitOnBlurAndEnterProps(submit)}
              dataTestId={'capacity_input'}
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          control={control}
          name="deadStock"
          rules={{...requiredValidator(t), ...minValidator(t, 0)}}
          render={({field: {ref, value, onChange}, fieldState: {error}}) => (
            <NumberInput
              inputRef={ref}
              label={t('storagesSettings.deadStock')}
              step={10}
              onChange={onChange}
              value={value}
              error={error?.message}
              disabled={isReadOnly || isStorageEditing}
              {...submitOnBlurAndEnterProps(submit)}
              dataTestId={'dead_stock_input'}
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          control={control}
          name="minimumStockLevel"
          rules={{...requiredValidator(t), ...minValidator(t, 0)}}
          render={({field: {ref, value, onChange}, fieldState: {error}}) => (
            <NumberInput
              inputRef={ref}
              label={t('storagesSettings.minStockLevel')}
              step={10}
              onChange={onChange}
              value={value}
              error={error?.message}
              disabled={isReadOnly || isStorageEditing}
              {...submitOnBlurAndEnterProps(submit)}
              dataTestId={'min_stock_lvl_input'}
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          control={control}
          name="minimumWeekendStockLevel"
          rules={{...requiredValidator(t), ...minValidator(t, 0)}}
          render={({field: {ref, value, onChange}, fieldState: {error}}) => (
            <NumberInput
              inputRef={ref}
              label={t('storagesSettings.minStockLevelWeekend')}
              step={10}
              onChange={onChange}
              value={value}
              error={error?.message}
              disabled={isReadOnly || isStorageEditing}
              {...submitOnBlurAndEnterProps(submit)}
              dataTestId={'min_stock_lvl_weekend_input'}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          control={control}
          name="isOptimized"
          render={({field: {ref, value, onChange}}) => (
            <Switch
              label={t('storagesSettings.optimized')}
              inputRef={ref}
              value={value}
              onChange={onChange}
              disabled={isReadOnly || isStorageEditing}
              {...submitOnBlurAndEnterProps(submit)}
              {...dataTestId('is_optimized_switch')}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          control={control}
          name="isMeasured"
          render={({field: {ref, value, onChange: onIsMeasuredChange}}) => (
            <Switch
              label={t('storagesSettings.measured')}
              inputRef={ref}
              value={value}
              onChange={(newIsMeasured) => {
                handleIsMeasuredChange(newIsMeasured, onIsMeasuredChange)
              }}
              disabled={isReadOnly || isStorageEditing || isMeasuredDisabled}
              {...submitOnBlurAndEnterProps(submit)}
              {...dataTestId('is_measured_switch')}
            />
          )}
        />
      </Grid>
      {storage.isMeasured && (
        <Grid item xs={12}>
          <TextField
            label={t('storagesSettings.unitOfMeasurement')}
            value={t(`storagesSettings.unitOfMeasurementItems.${storage.unitOfMeasurement}`)}
            disabled
            margin="normal"
            {...dataTestId('unit_of_measurement_input')}
          />
        </Grid>
      )}
    </Grid>
  )
}
