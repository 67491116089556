import {
  MaterialBoughtFrom,
  type MaterialWithRecipes,
  MaterialSource,
  type Asset
} from '@hconnect/common/types'
import {dataTestId} from '@hconnect/uikit'
import {LocationOn, Public} from '@mui/icons-material'
import {FormControl, Stack, FormHelperText} from '@mui/material'
import React, {useCallback, useRef} from 'react'
import {Controller, useFormContext, UseFormReturn} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {Checkbox} from '../../../../common/components'
import {SegmentedButton} from '../../../../common/components/form/SegmentedButton'
import {useConfirmDialog} from '../../../../common/providers'
import {isMaterialTypeBoughtFromVendor} from '../../../helpers/getProductsPerMaterialSource'
import {MaterialFormDefaultValues} from '../../materials-details/ExistingMaterialDetails/hooks'
import {MaterialsGridItem} from '../components/MaterialsGridItem'

import {MaterialChangeSourceInfo} from './MaterialChangeSourceInfo'

import {findAllOperationModes} from '@settings/modules/assets'
import {Schedule} from '@settings/modules/common/types'

interface MaterialSourceInputsProps {
  material: MaterialWithRecipes | undefined
  schedule: Schedule
  assets: Asset[]
  disabled?: boolean
}

const isProducedInPlantOrBoughtAndProduced = (materialSource: MaterialSource | null) => {
  return [MaterialSource.ProducedInPlant, MaterialSource.BoughtAndProduced].includes(
    materialSource as MaterialSource
  )
}

const isBoughtFromVendorOrBoughtAndProduced = (materialSource: MaterialSource | null) => {
  return [MaterialSource.BoughtFromVendor, MaterialSource.BoughtAndProduced].includes(
    materialSource as MaterialSource
  )
}

const calculateCurrentSource = (isProduced: boolean, isReceived: boolean) => {
  if (!isProduced && !isReceived) return null
  return isProduced
    ? isReceived
      ? MaterialSource.BoughtAndProduced
      : MaterialSource.ProducedInPlant
    : MaterialSource.BoughtFromVendor
}

export const MaterialSourceInputs: React.FC<MaterialSourceInputsProps> = ({
  material,
  schedule,
  assets,
  disabled = false
}) => {
  const {t} = useTranslation()
  const {openDialog} = useConfirmDialog()

  const {control, trigger, getValues, setValue, watch}: UseFormReturn<MaterialFormDefaultValues> =
    useFormContext()

  const isProducedRef = useRef<HTMLInputElement>(null)
  const isReceivedRef = useRef<HTMLInputElement>(null)
  const currentSource = watch('source')

  const deleteProducedProducts = useCallback(() => {
    const currentProducts = getValues('products')
    const boughtFromVendorProducts = currentProducts.filter((product) =>
      isMaterialTypeBoughtFromVendor(product.type)
    )
    setValue('products', boughtFromVendorProducts, {shouldDirty: true})
  }, [getValues, setValue])

  const deleteRecipes = useCallback(() => {
    setValue('recipes', [], {shouldDirty: true})
  }, [setValue])

  const handleProducedSourceUncheck = useCallback(() => {
    deleteProducedProducts()
    deleteRecipes()
  }, [deleteProducedProducts, deleteRecipes])

  const isExistingMaterial = material !== undefined

  // Only produced in plant products can be unattached by changing source
  const shouldShowSourceChangeDialog =
    isExistingMaterial &&
    (material.products.filter((product) => !isMaterialTypeBoughtFromVendor(product.type)).length >
      0 ||
      material.recipes.length > 0)

  const openProducedSourceUncheckDialog = useCallback(
    (material: MaterialWithRecipes) => {
      const operationModes = findAllOperationModes(assets, material.recipes)
      const scheduleItems = Object.values(schedule.schedules).filter(
        (item) => operationModes.find((mode) => mode.id === item.assetOperationModeId) !== undefined
      )
      return openDialog({
        testId: 'change_source_warning_dialog',
        title: t('materialsSettings.changeMaterialSource'),
        mainAction: {
          text: t('common.ok'),
          onAction: handleProducedSourceUncheck
        },
        onCancel: () => {
          setValue('source', currentSource)
          void trigger()
        },
        additionalContent: (
          <MaterialChangeSourceInfo
            material={material}
            sourceBeingRemoved={MaterialSource.ProducedInPlant}
            operationModes={operationModes}
            scheduleItems={scheduleItems}
          />
        )
      })
    },
    [
      assets,
      currentSource,
      schedule.schedules,
      t,
      openDialog,
      handleProducedSourceUncheck,
      setValue,
      trigger
    ]
  )

  return (
    <>
      <MaterialsGridItem>
        <Controller
          control={control}
          name="source"
          rules={{
            validate: () =>
              isProducedRef.current?.checked ||
              isReceivedRef.current?.checked ||
              t('materialsSettings.sourceValidationError')
          }}
          render={({field: {value, onChange}, formState: {errors}}) => (
            <FormControl>
              <Stack direction={{xs: 'column', md: 'row'}}>
                <Checkbox
                  inputRef={isProducedRef}
                  label={t('materialsSettings.producedInPlant')}
                  value={isProducedInPlantOrBoughtAndProduced(value)}
                  disabled={disabled}
                  onChange={(checked) => {
                    if (
                      isExistingMaterial &&
                      isProducedInPlantOrBoughtAndProduced(currentSource) &&
                      !checked
                    ) {
                      shouldShowSourceChangeDialog
                        ? openProducedSourceUncheckDialog(material)
                        : handleProducedSourceUncheck()
                    }

                    onChange(calculateCurrentSource(checked, isReceivedRef.current!.checked))
                    void trigger()
                  }}
                  {...dataTestId('material_is_produced_checkbox')}
                />
                <Checkbox
                  inputRef={isReceivedRef}
                  label={t('materialsSettings.receivedFromVendor')}
                  value={isBoughtFromVendorOrBoughtAndProduced(value)}
                  disabled={disabled}
                  onChange={(checked) => {
                    onChange(calculateCurrentSource(isProducedRef.current!.checked, checked))
                    void trigger()
                  }}
                  {...dataTestId('material_is_received_checkbox')}
                />
              </Stack>
              {errors.source && (
                <FormHelperText error {...dataTestId('material_source_error')}>
                  {errors.source.message}
                </FormHelperText>
              )}
            </FormControl>
          )}
        />
      </MaterialsGridItem>
      <MaterialsGridItem>
        {isBoughtFromVendorOrBoughtAndProduced(currentSource) && (
          <Controller
            control={control}
            name="boughtFrom"
            render={({field: {value, onChange}}) => (
              <SegmentedButton
                value={value}
                onChange={onChange}
                isDisabled={disabled}
                options={[
                  {
                    id: 'materials-bought-from-button',
                    value: MaterialBoughtFrom.Domestic,
                    icon: <LocationOn />,
                    title: t('materialsSettings.domestic'),
                    dataTestId: 'material_bought_from_domestic'
                  },
                  {
                    value: MaterialBoughtFrom.Abroad,
                    icon: <Public />,
                    title: t('materialsSettings.abroad'),
                    dataTestId: 'material_bought_from_abroad'
                  }
                ]}
                {...dataTestId('material_bough_from_toggle')}
              />
            )}
          />
        )}
      </MaterialsGridItem>
    </>
  )
}
