import {Grid, GridProps, Box} from '@mui/material'

export const MaterialsGridItem = ({
  children,
  ...props
}: GridProps & {children: React.ReactNode}) => {
  return (
    <Grid {...props} item xs={5} md={2.6}>
      <Box
        sx={{
          maxWidth: {
            base: 'none',
            md: (theme) => theme.spacing(49)
          }
        }}
      >
        {children}
      </Box>
    </Grid>
  )
}
