import faker from '@faker-js/faker'
import MockAdapter from 'axios-mock-adapter'

import {
  numberRegEx,
  sleepResponse,
  uuidRegEx,
  saveScenario
} from '@settings/__mock__/mockServices/utils'
import {mockStore} from '@settings/__mock__/mockStore'
import {SignalDetail, SignalListData} from '@settings/modules/upm/types/signal'

export const enableUpmEndpoints = (mock: MockAdapter) => {
  // ----- UPM structure endpoints -----

  // GET upm plant structure
  mock.onGet(new RegExp(`upm/plants/${numberRegEx}/structure`)).reply(() => {
    const scenario = mockStore.scenario()
    const {
      burglengenfeld: {upm}
    } = scenario
    return sleepResponse([200, upm.structure])
  })

  // ----- UPM general tab endpoints -----

  // GET plant general data
  mock.onGet(new RegExp(`/upm/plant/${uuidRegEx}`)).reply(() => {
    const scenario = mockStore.scenario()
    const {
      burglengenfeld: {
        upm: {plantData}
      }
    } = scenario
    return sleepResponse([200, plantData])
  })

  // ----- UPM sensors tab endpoints -----

  // GET list of signals for plant/area/production-line/department/equipment
  const nodeType = '(plant|area|production-line|department|equipment)'
  mock.onGet(new RegExp(`/upm/signal/${nodeType}/${uuidRegEx}\\?pageSize=1000`)).reply(() => {
    const scenario = mockStore.scenario()
    const {
      burglengenfeld: {upm}
    } = scenario
    return sleepResponse([200, upm.signals])
  })

  // GET signal details
  mock.onGet(new RegExp(`/upm/signal/${uuidRegEx}`)).reply((config) => {
    const scenario = mockStore.scenario()
    const {
      burglengenfeld: {upm}
    } = scenario

    const signalId = (config.url as string).split('/')[3]
    const signal = upm.signals.find((signal) => signal.id === signalId)

    return sleepResponse([200, signal])
  })

  // GET signal types (used in signal details)
  mock.onGet('upm/signal-type').reply(() => {
    const scenario = mockStore.scenario()
    const {
      burglengenfeld: {upm}
    } = scenario
    return sleepResponse([200, upm.signalType])
  })

  // GET units (used in signal details)
  mock.onGet('upm/unit').reply(() => {
    const scenario = mockStore.scenario()
    const {
      burglengenfeld: {upm}
    } = scenario
    return sleepResponse([200, upm.units])
  })

  // GET equipments (used in signal details)
  mock
    .onGet(new RegExp(`upm/equipment/plant/${numberRegEx}\\?pageSize=${numberRegEx}`))
    .reply(() => {
      const scenario = mockStore.scenario()
      const {
        burglengenfeld: {upm}
      } = scenario
      return sleepResponse([200, upm.equipment])
    })

  // GET local names (used in signal details)
  mock.onGet(new RegExp(`upm/signal/plant/${numberRegEx}/local-names`)).reply(() => {
    return sleepResponse([200, []])
  })

  // POST create signal
  mock.onPost('/upm/signal').reply((config) => {
    const scenario = mockStore.scenario()
    const payload = JSON.parse(config.data as string) as SignalDetail
    const newSignal: SignalListData = {
      ...payload,
      id: faker.datatype.uuid(),
      area: {
        id: faker.datatype.uuid(),
        name: 'Area'
      },
      equipment: {
        id: payload.equipmentId,
        name: 'Equipment'
      },
      department: {
        id: faker.datatype.uuid(),
        name: 'Department'
      },
      productionLine: {
        id: faker.datatype.uuid(),
        name: 'Production Line'
      }
    }
    scenario.burglengenfeld.upm.signals = [...scenario.burglengenfeld.upm.signals, newSignal]

    saveScenario(scenario)

    return sleepResponse([201, newSignal])
  })

  return mock
}
