import {
  Material,
  MaterialStorage,
  MaterialStorageDTO,
  DataConnectionType,
  DatamartSyncDetails,
  EmailSyncDetails
} from '@hconnect/common/types'
import type {
  Asset,
  AssetPayload,
  AssetLink,
  OperationMode,
  OperationModePayload,
  AssetTransitionDTO,
  AssetLinkDTO,
  AssetTransition,
  AssetEditableFields,
  ProductionOperationMode,
  AddMaterialDTO,
  EditMaterialDTO
} from '@hconnect/common/types'

import type {
  AssetStandardOperationTimePayload,
  AssetStandardOperationTime,
  DefaultAssetOperationTimePayload,
  DefaultAssetOperationTime,
  AssetStandardOperationTimeConflictResolutionAction
} from '../modules/assets/types'
import {MonthlyReportApproveStatus} from '../modules/janus/enums'
import {
  FinalValue,
  DailyKpiInfo,
  EditKpiResponse,
  EditedFinalKpiValue,
  EditMonthlyKpi,
  EditedMonthlyKpi,
  EditedPssKpi,
  EditPssKpi
} from '../modules/janus/types'
import type {Kpi} from '../modules/kpi-calculation/types'

import {Api} from './api'

import {measurementsMutations} from '@settings/modules/measurements/api/mutations'
import {DepartmentConfigDto} from '@settings/modules/plant-setup/types'
import {samplingPointsMutations} from '@settings/modules/sampling-points/api/mutations'

// TODO <HCP-82759>: remove after all add/edit material tabs functionalities
// are moved to the single save button
export enum MaterialApiKeys {
  globalMaterial = 'global-material'
}

type UpdateValidateStandardOperationTimeParams = {
  plantCode: string
  defaultOperationTimeId: number
  AssetStandardOperationTimeDto: AssetStandardOperationTimePayload
}
type UpdateStandardOperationTimeParams = UpdateValidateStandardOperationTimeParams & {
  onConflict: AssetStandardOperationTimeConflictResolutionAction
}

type AddValidateStandardOperationTimeParams = {
  plantCode: string
  AssetStandardOperationTimeDto: AssetStandardOperationTimePayload
}
type AddStandardOperationTimeParams = AddValidateStandardOperationTimeParams & {
  onConflict: AssetStandardOperationTimeConflictResolutionAction
}

export const mutations = {
  addDefaultAssetOperationTime: async ({
    assetOperationModeId,
    plantCode,
    defaultAssetOperationTimeDto
  }: {
    plantCode: string
    assetOperationModeId: number
    defaultAssetOperationTimeDto: DefaultAssetOperationTimePayload
  }): Promise<DefaultAssetOperationTime> => {
    const path = `/schedules/default-asset-operation-times/${plantCode}/${assetOperationModeId}`
    const response = await Api.axiosInstance.post<DefaultAssetOperationTime>(
      path,
      defaultAssetOperationTimeDto
    )
    return response.data
  },
  updateDefaultAssetOperationTime: async ({
    plantCode,
    assetOperationModeId,
    defaultOperationTimeId,
    defaultAssetOperationTimeDto
  }: {
    plantCode: string
    assetOperationModeId: number
    defaultOperationTimeId: number
    defaultAssetOperationTimeDto: DefaultAssetOperationTimePayload
  }): Promise<void> => {
    const path = `/schedules/default-asset-operation-times/${plantCode}/${assetOperationModeId}/${defaultOperationTimeId}`
    const response = await Api.axiosInstance.put<void>(path, defaultAssetOperationTimeDto)
    return response.data
  },
  deleteDefaultAssetOperationTime: async ({
    plantCode,
    assetOperationModeId,
    defaultAssetOperationTimeId
  }: {
    plantCode: string
    assetOperationModeId: number
    defaultAssetOperationTimeId: number
  }): Promise<void> => {
    const path = `/schedules/default-asset-operation-times/${plantCode}/${assetOperationModeId}/${defaultAssetOperationTimeId}`
    const response = await Api.axiosInstance.delete<void>(path)
    return response.data
  },
  addStandardOperationTime: async ({
    plantCode,
    onConflict,
    AssetStandardOperationTimeDto
  }: AddStandardOperationTimeParams): Promise<AssetStandardOperationTime> => {
    const path = `/plants/${plantCode}/schedules/standard-operation-times`
    const params = {
      onConflict
    }
    const response = await Api.axiosInstance.post<AssetStandardOperationTime>(
      path,
      AssetStandardOperationTimeDto,
      {params}
    )
    return response.data
  },
  updateStandardOperationTime: async ({
    plantCode,
    onConflict,
    defaultOperationTimeId,
    AssetStandardOperationTimeDto
  }: UpdateStandardOperationTimeParams): Promise<void> => {
    const path = `/plants/${plantCode}/schedules/standard-operation-times/${defaultOperationTimeId}`
    const params = {
      onConflict
    }
    const response = await Api.axiosInstance.put<void>(path, AssetStandardOperationTimeDto, {
      params
    })
    return response.data
  },
  deleteStandardOperationTime: async ({
    plantCode,
    AssetStandardOperationTimeId
  }: {
    plantCode: string
    AssetStandardOperationTimeId: number
  }): Promise<void> => {
    const path = `/plants/${plantCode}/schedules/standard-operation-times/${AssetStandardOperationTimeId}`
    const response = await Api.axiosInstance.delete<void>(path)
    return response.data
  },
  addMaterial: async ({
    plantCode,
    dto
  }: {
    plantCode: string
    dto: AddMaterialDTO
  }): Promise<Material> => {
    const path = `/plants/${plantCode}/materials`
    const response = await Api.axiosInstance.post<Material>(path, dto)
    return response.data
  },
  // TODO <HCP-82759>: remove after all add/edit material tabs functionalities
  // are moved to the single save button
  editMaterialAttribute: async ({
    plantCode,
    key,
    materialId,
    pxTrendCounters,
    globalMaterialId
  }: {
    key: MaterialApiKeys
    plantCode: string
    materialId: number
    pxTrendCounters?: string[]
    globalMaterialId?: number
  }): Promise<void> => {
    const path = `/plants/${plantCode}/materials/${materialId}/${key}`
    await Api.axiosInstance.patch<Material>(path, {
      pxTrendCounters,
      globalMaterialId
    })
  },
  editMaterial: async ({
    plantCode,
    materialId,
    dto
  }: {
    plantCode: string
    materialId: number
    dto: EditMaterialDTO
  }): Promise<void> => {
    const path = `/plants/${plantCode}/materials/${materialId}`
    await Api.axiosInstance.patch<Material>(path, dto)
  },
  deleteMaterial: async ({
    plantCode,
    materialId
  }: {
    plantCode: string
    materialId: number
  }): Promise<void> => {
    const path = `/plants/${plantCode}/materials/${materialId}`
    await Api.axiosInstance.delete<void>(path)
  },
  addAsset: async ({
    plantCode,
    name,
    type,
    upmId
  }: {plantCode: string} & AssetPayload): Promise<Asset> => {
    const path = `/plants/${plantCode}/assets`
    const response = await Api.axiosInstance.post<Asset>(path, {
      name,
      type,
      ...(upmId ? {upmId} : {})
    })
    return response.data
  },
  editAsset: async ({plantCode, assetId, key, dto}) => {
    const keyToPath: Record<AssetEditableFields, string> = {
      isOptimized: 'optimized',
      startupCost: 'startup-cost',
      minimumDowntime: 'minimum-downtime',
      isShutdownAvailable: 'is-shutdown-available',
      name: 'name',
      type: 'type',
      startCoefficient: 'start-coefficient',
      stopCoefficient: 'stop-coefficient',
      upmId: 'upm-id'
    }
    const path = `/plants/${plantCode}/assets/${assetId}/${keyToPath[key]}`
    const response = await Api.axiosInstance.patch<Asset>(path, dto)
    return response.data
  },
  deleteAsset: async ({
    plantCode,
    assetId
  }: {
    plantCode: string
    assetId: number
  }): Promise<void> => {
    const path = `/plants/${plantCode}/assets/${assetId}`
    await Api.axiosInstance.delete<void>(path)
  },
  addOperationMode: async ({
    plantCode,
    assetId,
    ...dto
  }: {plantCode: string; assetId: number} & Omit<
    OperationModePayload,
    'isOptimized'
  >): Promise<OperationMode> => {
    const path = `/plants/${plantCode}/assets/${assetId}/operation-modes`
    const response = await Api.axiosInstance.post<OperationMode>(path, dto)
    return response.data
  },
  editOperationMode: async ({
    plantCode,
    assetId,
    operationModeId,
    key,
    dto
  }: {
    plantCode: string
    assetId: number
    operationModeId: number
    key: keyof OperationModePayload
    dto: OperationModePayload
  }): Promise<ProductionOperationMode> => {
    const keyToResourceMap: Record<
      keyof OperationModePayload,
      | 'recipe'
      | 'optimized'
      | 'name'
      | 'type'
      | 'throughput'
      | 'power-consumption'
      | 'minimum-runtime'
    > = {
      recipeId: 'recipe',
      isOptimized: 'optimized',
      name: 'name',
      type: 'type',
      throughput: 'throughput',
      powerConsumption: 'power-consumption',
      minimumRuntime: 'minimum-runtime'
    }
    const resource = keyToResourceMap[key]

    const path = `/plants/${plantCode}/assets/${assetId}/operation-modes/${operationModeId}/${resource}`
    const response = await Api.axiosInstance.patch<ProductionOperationMode>(path, dto)
    return response.data
  },
  deleteOperationMode: async ({
    plantCode,
    assetId,
    operationModeId
  }: {
    plantCode: string
    assetId: number
    operationModeId: number
  }): Promise<void> => {
    const path = `/plants/${plantCode}/assets/${assetId}/operation-modes/${operationModeId}`
    await Api.axiosInstance.delete<void>(path)
  },
  editAssetTransition: async ({
    plantCode,
    assetId,
    id,
    key,
    dto
  }: {
    plantCode: string
    assetId: number
    id: number
    key: keyof AssetTransitionDTO
    dto: AssetTransitionDTO
  }): Promise<AssetTransition> => {
    const keyToResourceMap: Record<keyof AssetTransitionDTO, 'time' | 'is-possible'> = {
      time: 'time',
      isPossible: 'is-possible'
    }
    const resource = keyToResourceMap[key]
    const path = `/plants/${plantCode}/assets/${assetId}/transitions/${id}/${resource}`
    const response = await Api.axiosInstance.patch<AssetTransition>(path, dto)
    return response.data
  },
  addAssetLink: async ({
    plantCode,
    dto
  }: {
    plantCode: string
    dto: AssetLinkDTO
  }): Promise<AssetLink> => {
    const path = `/plants/${plantCode}/assets/links`
    const response = await Api.axiosInstance.post<AssetLink>(path, dto)
    return response.data
  },
  editAssetLink: async ({
    plantCode,
    id,
    dto
  }: {
    plantCode: string
    id: number
    dto: AssetLinkDTO
  }): Promise<AssetLink> => {
    const path = `/plants/${plantCode}/assets/links/${id}`
    const response = await Api.axiosInstance.patch<AssetLink>(path, dto)
    return response.data
  },
  deleteAssetLink: async ({plantCode, id}: {plantCode: string; id: number}): Promise<void> => {
    const path = `/plants/${plantCode}/assets/links/${id}`
    await Api.axiosInstance.delete<void>(path)
  },
  addMaterialStorage: async ({
    plantCode,
    dto
  }: {
    plantCode: string
    dto: MaterialStorageDTO
  }): Promise<MaterialStorage> => {
    const path = `/plants/${plantCode}/material-storage`
    const response = await Api.axiosInstance.post<MaterialStorage>(path, dto)
    return response.data
  },
  editMaterialStorage: async ({
    plantCode,
    id,
    key,
    dto
  }: {
    plantCode: string
    id: number
    key: keyof MaterialStorageDTO
    dto: Partial<MaterialStorageDTO>
  }): Promise<MaterialStorage> => {
    const keyToResourceMap: Record<
      keyof MaterialStorageDTO,
      | 'name'
      | 'capacity'
      | 'dead-stock'
      | 'material'
      | 'is-optimized'
      | 'is-measured'
      | 'minimum-stock-level'
      | 'minimum-weekend-stock-level'
      | 'material-type'
      | 'measurement-type'
      | 'upm-id'
    > = {
      name: 'name',
      capacity: 'capacity',
      deadStock: 'dead-stock',
      materialId: 'material',
      isOptimized: 'is-optimized',
      isMeasured: 'is-measured',
      minimumStockLevel: 'minimum-stock-level',
      minimumWeekendStockLevel: 'minimum-weekend-stock-level',
      materialType: 'material-type',
      measurementType: 'measurement-type',
      upmId: 'upm-id'
    }
    const resource = keyToResourceMap[key]
    const path = `/plants/${plantCode}/material-storage/${id}/${resource}`
    const response = await Api.axiosInstance.patch<MaterialStorage>(path, dto)
    return response.data
  },
  deleteMaterialStorage: async ({
    plantCode,
    id
  }: {
    plantCode: string
    id: number
  }): Promise<void> => {
    const path = `/plants/${plantCode}/material-storage/${id}`
    await Api.axiosInstance.delete<void>(path)
  },
  addStorageDatamartConnection: async ({
    plantCode,
    storageId,
    dto
  }: {
    plantCode: string
    storageId: number
    dto: DatamartSyncDetails
  }): Promise<MaterialStorage> => {
    const path = `/plants/${plantCode}/material-storage/${storageId}/details/datamart`
    const response = await Api.axiosInstance.post<MaterialStorage>(path, dto)
    return response.data
  },
  editStorageDatamartConnection: async ({
    plantCode,
    storageId,
    key,
    dto
  }: {
    plantCode: string
    storageId: number
    key: keyof DatamartSyncDetails
    dto: DatamartSyncDetails
  }): Promise<MaterialStorage> => {
    const keyToResourceMap: Record<typeof key, 'tag-name' | 'tonne-conversion-factor'> = {
      tagName: 'tag-name',
      tonneConversionFactor: 'tonne-conversion-factor'
    }
    const path = `/plants/${plantCode}/material-storage/${storageId}/details/datamart/${keyToResourceMap[key]}`
    const response = await Api.axiosInstance.patch<MaterialStorage>(path, dto)
    return response.data
  },
  addStorageEmailParserConnection: async ({
    plantCode,
    storageId,
    dto
  }: {
    plantCode: string
    storageId: number
    dto: EmailSyncDetails
  }): Promise<MaterialStorage> => {
    const path = `/plants/${plantCode}/material-storage/${storageId}/details/email-parser`
    const response = await Api.axiosInstance.post<MaterialStorage>(path, dto)
    return response.data
  },
  editStorageEmailParserConnection: async ({
    plantCode,
    storageId,
    key,
    dto
  }: {
    plantCode: string
    storageId: number
    key: keyof EmailSyncDetails
    dto: EmailSyncDetails
  }): Promise<MaterialStorage> => {
    const keyToResourceMap: Record<typeof key, 'email-column-name' | 'tonne-conversion-factor'> = {
      emailColumnName: 'email-column-name',
      tonneConversionFactor: 'tonne-conversion-factor'
    }
    const path = `/plants/${plantCode}/material-storage/${storageId}/details/email-parser/${keyToResourceMap[key]}`
    const response = await Api.axiosInstance.patch<MaterialStorage>(path, dto)
    return response.data
  },
  deleteStorageDataConnection: async ({
    plantCode,
    storageId,
    type
  }: {
    plantCode: string
    storageId: number
    type: DataConnectionType
  }): Promise<void> => {
    const path = `/plants/${plantCode}/material-storage/${storageId}/details/${type}`
    await Api.axiosInstance.delete<void>(path)
  },
  requestSalesForecast: async ({
    plantCode,
    materialId,
    productId
  }: {
    plantCode: string
    materialId: number
    productId: number
  }): Promise<void> => {
    const path = `/plants/${plantCode}/material-demand/${materialId}/demand/prediction/${productId}`
    await Api.axiosInstance.post<void>(path)
  },
  editKpiDisplayName: async ({plantCode, kpiId, displayName}) => {
    const path = `/kpieditor/plants/${plantCode}/kpis/${kpiId}/displayname`
    const response = await Api.axiosInstance.patch<Kpi>(path, {displayName})
    return response.data
  },
  editKpiFrequency: async ({plantCode, kpiId, frequency}) => {
    const path = `/kpieditor/plants/${plantCode}/kpis/${kpiId}/frequency`
    const response = await Api.axiosInstance.patch<Kpi>(path, {frequency})
    return response.data
  },
  editKpiUnit: async ({plantCode, kpiId, unit}) => {
    const path = `/kpieditor/plants/${plantCode}/kpis/${kpiId}/unit`
    const response = await Api.axiosInstance.patch<Kpi>(path, {unit})
    return response.data
  },
  editKpiThresholds: async ({plantCode, kpiId, thresholds}) => {
    const path = `/kpieditor/plants/${plantCode}/kpis/${kpiId}/ranges`
    const response = await Api.axiosInstance.patch<Kpi>(path, {...thresholds})
    return response.data
  },
  verifyKpiFormula: async ({plantCode, kpiId, formula}) => {
    const path = `/kpieditor/plants/${plantCode}/kpis/${kpiId}/formula`
    const response = await Api.axiosInstance.patch<Kpi>(path, {formula})
    return response.data
  },
  editKpiPlantEntryPointVisibility: async ({plantCode, kpiId, plantEntryPointVisibility}) => {
    const path = `/kpieditor/plants/${plantCode}/kpis/${kpiId}/visibility/plant-entry-point`
    const response = await Api.axiosInstance.patch<Kpi>(path, plantEntryPointVisibility, {
      headers: {'Content-Type': 'application/json'}
    })
    return response.data
  },
  addComment: async ({
    plantCode,
    kpiValueId,
    value
  }: {
    plantCode: string
    kpiValueId: string
    value: string
  }): Promise<void> => {
    const path = `/janus-kpi-events/${plantCode}/events/${kpiValueId}/comments`
    await Api.axiosInstance.post(path, {value})
  },
  editDailyKpi: async ({
    plantCode,
    value,
    date
  }: {
    plantCode: string
    value: DailyKpiInfo[]
    date?: string
  }): Promise<EditKpiResponse> => {
    const path = `/janus-daily-kpi/${plantCode}/daily/${date}`
    const response = await Api.axiosInstance.post(path, value)
    return response.data
  },
  editMonthlyKpi: async ({
    plantCode,
    value,
    groupId,
    date
  }: {
    plantCode: string
    value: EditMonthlyKpi[]
    groupId: string
    date?: string
  }): Promise<EditedMonthlyKpi[]> => {
    const path = `/janus-monthly-result/janus-kpi/${plantCode}/kpi/${groupId}/${date}`
    const response = await Api.axiosInstance.post(path, value)
    return response.data
  },
  editPssKpi: async ({
    plantCode,
    value,
    groupId,
    date
  }: {
    plantCode: string
    value: EditPssKpi[]
    groupId: string
    date?: string
  }): Promise<EditedPssKpi[]> => {
    const path = `/janus-pss/janus-kpi/${plantCode}/${date}/${groupId}`
    const response = await Api.axiosInstance.post(path, value)
    return response.data
  },
  approveDailyKpi: async ({
    plantCode,
    date
  }: {
    plantCode: string
    date?: string
  }): Promise<number> => {
    const path = `/janus-daily-kpi/${plantCode}/daily/${date}/approve`
    const response = await Api.axiosInstance.patch(path)
    return response.data
  },
  approveMonthlyKpi: async ({
    plantCode,
    groupId,
    date
  }: {
    plantCode: string
    groupId: string
    date?: string
  }): Promise<MonthlyReportApproveStatus> => {
    const path = `janus-monthly-result/janus-kpi/${plantCode}/kpi/${groupId}/${date}/approve`
    const response = await Api.axiosInstance.patch(path)
    return response.data
  },
  approveCumulatedKpi: async ({
    plantCode,
    date
  }: {
    plantCode: string
    date?: string
  }): Promise<number> => {
    const path = `janus-daily-kpi/${plantCode}/cumulated/${date}/approve`
    const response = await Api.axiosInstance.patch(path)
    return response.data
  },
  approvePssPlannedKpis: async ({
    plantCode,
    date
  }: {
    plantCode: string
    date?: string
  }): Promise<number> => {
    const path = `/janus-pss/janus-kpi/${plantCode}/${date}/approve`
    const response = await Api.axiosInstance.patch(path)
    return response.data
  },
  addDepartmentConfig: async ({
    plantCode,
    departmentConfig
  }: {
    plantCode: string
    departmentConfig: DepartmentConfigDto
  }) => {
    const path = `asset-config/janus-production-data/plant/${plantCode}?upmType=Department&typeUpmId=${departmentConfig.metadata.typeUpmId}`
    return (await Api.axiosInstance.post(path, {...departmentConfig.payload})).data
  },
  updateDepartmentConfig: async ({
    plantCode,
    departmentConfig
  }: {
    plantCode: string
    departmentConfig: DepartmentConfigDto
  }) => {
    const path = `asset-config/janus-production-data/plant/${plantCode}?upmType=Department&typeUpmId=${departmentConfig.metadata.typeUpmId}`
    return (await Api.axiosInstance.put(path, {...departmentConfig.payload})).data
  },
  editKpiFinalValue: async ({
    plantCode,
    value,
    date
  }: {
    plantCode: string
    value: FinalValue[]
    date?: string
  }): Promise<EditedFinalKpiValue[]> => {
    const path = `janus-daily-kpi/${plantCode}/cumulated/${date}`
    const response = await Api.axiosInstance.post(path, value)
    return response.data
  },
  deleteGlobalMaterial: async ({
    plantCode,
    materialId
  }: {
    plantCode: string
    materialId: number
  }): Promise<void> => {
    const path = `/plants/${plantCode}/materials/${materialId}/global-material`
    await Api.axiosInstance.delete<void>(path)
  },
  ...measurementsMutations,
  ...samplingPointsMutations
}

export type Mutations = typeof mutations
