import type {AssetLink, AssetLinkDTO, NewAttachedSilo} from '@hconnect/common/types'
import {AssetLinkType, LinkDirection} from '@hconnect/common/types'
import {dataTestId} from '@hconnect/uikit'
import {NumberLetterSequenceIndicator} from '@hconnect/uikit/src/lib2'
import {DeleteOutlined} from '@mui/icons-material'
import {Stack, ToggleButton, ToggleButtonGroup, TextField, MenuItem} from '@mui/material'
import React from 'react'
import {useForm, Controller} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {useAddSiloLink, useDeleteSiloLink, useEditSiloLink} from '@settings/modules/assets'
import {DeleteButton} from '@settings/modules/common/components'
import {useConfirmDialog} from '@settings/modules/common/providers'
import {requiredValidator, submitOnBlurAndEnterProps} from '@settings/modules/common/utils'
import {useUrlParam} from '@settings/modules/routing'

const getAttachedSiloName = (id: number, silos: {id: number; name: string}[]) => {
  const foundSilo = silos.find((silo) => silo.id === id)
  if (!foundSilo) throw new Error('BUG: Attached silo should be on available silos list')
  return foundSilo.name
}

const getAttachedSiloId = (link: AssetLink) => {
  const {from, to} = link
  if (from.type === AssetLinkType.Silo) return from.id
  if (to.type === AssetLinkType.Silo) return to.id
  throw new Error('Link type Silo must be present in to or from objects')
}

const getAssetToSiloRealtion = (link: Pick<AssetLink, LinkDirection.To>) => {
  const {to} = link
  if (to.type === AssetLinkType.Asset) return LinkDirection.From
  if (to.type === AssetLinkType.Silo) return LinkDirection.To
  throw new Error('Silo should have fromType equals only Asset or Silo')
}

const isNewAssetLink = (link: AssetLink | NewAttachedSilo): link is NewAttachedSilo => {
  return (link as NewAttachedSilo).id === undefined || (link as NewAttachedSilo).from === undefined
}

const getAssetToSiloLinkDTO = (relation: LinkDirection, assetId: number, siloId: number) => {
  const siloData = {id: siloId, type: AssetLinkType.Silo}
  const assetData = {id: assetId, type: AssetLinkType.Asset}
  const dto: AssetLinkDTO = {
    from: relation === LinkDirection.From ? siloData : assetData,
    to: relation === LinkDirection.From ? assetData : siloData
  }
  return dto
}

interface AttachedSiloFormProps {
  index: number
  assetId: number
  siloLink: AssetLink | NewAttachedSilo
  availableSilos: {id: number; name: string}[]
  isReadOnly: boolean
  setNewSiloLink: (link?: NewAttachedSilo) => void
}

export const AttachedSiloForm: React.FC<AttachedSiloFormProps> = ({
  index,
  assetId,
  siloLink,
  availableSilos,
  isReadOnly,
  setNewSiloLink
}) => {
  const {t} = useTranslation()
  const plantCode = useUrlParam('plantCode')
  const {openDialog} = useConfirmDialog()

  const {mutate: addSiloLink} = useAddSiloLink(assetId)
  const {mutate: editSiloLink} = useEditSiloLink(assetId)
  const {mutate: deleteSiloLink} = useDeleteSiloLink(assetId)

  const assetToSiloRelation = getAssetToSiloRealtion(siloLink)

  const initialValues = {
    relation: assetToSiloRelation,
    siloId: isNewAssetLink(siloLink) ? undefined : getAttachedSiloId(siloLink),
    assetId
  }

  const {
    handleSubmit,
    control,
    reset,
    formState: {isDirty}
  } = useForm({
    mode: 'onChange',
    shouldFocusError: false,
    defaultValues: initialValues
  })

  const onSubmit = handleSubmit(({relation, siloId, assetId}) => {
    if (!isDirty || !siloId) return
    if (!siloLink.id) {
      return addSiloLink(
        {
          plantCode,
          dto: getAssetToSiloLinkDTO(relation, assetId, siloId)
        },
        {
          onError: () => reset(),
          onSuccess: () => setNewSiloLink(undefined)
        }
      )
    }
    return editSiloLink(
      {
        plantCode,
        id: siloLink.id,
        dto: getAssetToSiloLinkDTO(relation, assetId, siloId)
      },
      {
        onError: () => reset(),
        onSuccess: (assetLink) => {
          const relation = getAssetToSiloRealtion(assetLink)
          reset({relation, siloId: getAttachedSiloId(assetLink), assetId})
        }
      }
    )
  })

  const handleDeleteAssetLink = (linkId: number) => {
    deleteSiloLink({plantCode, id: linkId})
  }

  const onDelete = () => {
    if (isNewAssetLink(siloLink)) {
      setNewSiloLink(undefined)
      return
    }
    const linkId = siloLink.id
    const siloId = getAttachedSiloId(siloLink)
    const siloName = getAttachedSiloName(siloId, availableSilos)
    openDialog({
      title: t('assetsSettings.deleteSiloLink'),
      description: t('assetsSettings.deleteSiloLinkConfirmation', {
        name: siloName
      }),
      mainAction: {
        color: 'error',
        text: t('common.delete'),
        icon: <DeleteOutlined />,
        onAction: () => handleDeleteAssetLink(linkId)
      },
      testId: 'delete_silo_link_confirmation_dialog'
    })
  }

  return (
    <Stack spacing={2} direction="row" alignItems="center" {...dataTestId('attached_silo_form')}>
      <NumberLetterSequenceIndicator numberIndex={index} />
      <Controller
        control={control}
        name="relation"
        render={({field: {value, onChange}}) => (
          <ToggleButtonGroup
            value={value}
            onChange={(e, value: LinkDirection | null) => {
              // preventing unselecting toggle button
              if (value) {
                onChange(value)
              }
            }}
            color="primary"
            sx={{width: 'fit-content'}}
            exclusive
            disabled={isReadOnly}
            {...submitOnBlurAndEnterProps(onSubmit)}
            {...dataTestId('relation_toggle_group')}
          >
            <ToggleButton
              {...dataTestId('relation_toggle_button-taken')}
              value={LinkDirection.From}
            >
              {t('assetsSettings.takenFrom')}
            </ToggleButton>
            <ToggleButton value={LinkDirection.To}>{t('assetsSettings.producedInto')}</ToggleButton>
          </ToggleButtonGroup>
        )}
      />
      <Controller
        control={control}
        name="siloId"
        rules={requiredValidator(t)}
        render={({field: {value, onChange}, fieldState: {error}}) => (
          <TextField
            select
            label={t('assetsSettings.silo')}
            sx={{width: ({spacing}) => spacing(30)}}
            onChange={onChange}
            value={value ?? ''}
            disabled={isReadOnly}
            error={Boolean(error)}
            helperText={error?.message}
            {...submitOnBlurAndEnterProps(onSubmit)}
            {...dataTestId('silo_id_select')}
          >
            {availableSilos.map((silo) => (
              <MenuItem key={silo.id} value={silo.id} {...dataTestId('silo_id_select_item')}>
                {silo.name}
              </MenuItem>
            ))}
          </TextField>
        )}
      />
      <DeleteButton disabled={isReadOnly} onClick={onDelete} {...dataTestId('delete_silo_link')} />
    </Stack>
  )
}
