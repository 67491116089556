import {FuelsClassificationInputNames} from '@hconnect/common/enums'
import {MaterialType} from '@hconnect/common/types'
import {dataTestId} from '@hconnect/uikit/src/common/utils/testingUtils'
import {Stack} from '@mui/material'
import {useFormContext} from 'react-hook-form'

import {MaterialFormDefaultValues} from '../../materials-details/ExistingMaterialDetails/hooks'
import {useMaterialsClassificationMetadata} from '../../../../../modules/materials/hooks'

import {ClassificationAutocomplete} from './inputs/ClassificationAutocomplete'
import {ClassificationSegmentedButton} from './inputs/ClassificationSegmentedButton'
import {MaterialsGrid} from '../components/MaterialsGrid'
import {MaterialsGridItem} from '../components/MaterialsGridItem'

interface FuelsClassificationProps {
  isDisabled: boolean
}

export const FuelsClassification: React.FC<FuelsClassificationProps> = ({isDisabled}) => {
  const {data: metadata} = useMaterialsClassificationMetadata(MaterialType.Fuels)
  const {setValue, getValues, clearErrors} = useFormContext<MaterialFormDefaultValues>()

  if (!metadata) {
    throw new Error(
      'BUG: classification metadata should be loaded before rendering classification tab'
    )
  }

  return (
    <MaterialsGrid {...dataTestId('fuels_classification_form')}>
      <MaterialsGridItem>
        <ClassificationSegmentedButton
          materialType={MaterialType.Fuels}
          inputName={FuelsClassificationInputNames.FuelType}
          metadata={metadata}
          isDisabled={isDisabled}
          onChangeCb={() => {
            setValue('classification', {
              ...getValues('classification'),
              [FuelsClassificationInputNames.FuelCategory]: null,
              [FuelsClassificationInputNames.FuelSubCategory]: null,
              [FuelsClassificationInputNames.FormSize]: null,
              [FuelsClassificationInputNames.ProcessingDegree]: null
            })
            clearErrors([
              `classification.${FuelsClassificationInputNames.FuelCategory}`,
              `classification.${FuelsClassificationInputNames.FuelSubCategory}`,
              `classification.${FuelsClassificationInputNames.FormSize}`,
              `classification.${FuelsClassificationInputNames.ProcessingDegree}`
            ])
          }}
        />
      </MaterialsGridItem>
      <MaterialsGridItem>
        <ClassificationAutocomplete
          materialType={MaterialType.Fuels}
          inputName={FuelsClassificationInputNames.FuelCategory}
          metadata={metadata}
          isDisabled={isDisabled}
          onChangeCb={() => {
            setValue('classification', {
              ...getValues('classification'),
              [FuelsClassificationInputNames.FuelSubCategory]: null,
              [FuelsClassificationInputNames.FormSize]: null,
              [FuelsClassificationInputNames.ProcessingDegree]: null
            })
            clearErrors([
              `classification.${FuelsClassificationInputNames.FuelSubCategory}`,
              `classification.${FuelsClassificationInputNames.FormSize}`,
              `classification.${FuelsClassificationInputNames.ProcessingDegree}`
            ])
          }}
        />
      </MaterialsGridItem>
      <MaterialsGridItem>
        <ClassificationAutocomplete
          materialType={MaterialType.Fuels}
          inputName={FuelsClassificationInputNames.FuelSubCategory}
          metadata={metadata}
          isDisabled={isDisabled}
          onChangeCb={() => {
            setValue('classification', {
              ...getValues('classification'),
              [FuelsClassificationInputNames.FormSize]: null,
              [FuelsClassificationInputNames.ProcessingDegree]: null
            })
            clearErrors([
              `classification.${FuelsClassificationInputNames.FormSize}`,
              `classification.${FuelsClassificationInputNames.ProcessingDegree}`
            ])
          }}
        />
      </MaterialsGridItem>
      <MaterialsGridItem>
        <ClassificationAutocomplete
          materialType={MaterialType.Fuels}
          inputName={FuelsClassificationInputNames.FormSize}
          metadata={metadata}
          isDisabled={isDisabled}
        />
      </MaterialsGridItem>
      <MaterialsGridItem>
        <ClassificationAutocomplete
          materialType={MaterialType.Fuels}
          inputName={FuelsClassificationInputNames.ProcessingDegree}
          metadata={metadata}
          isDisabled={isDisabled}
        />
      </MaterialsGridItem>
    </MaterialsGrid>
  )
}
