import {MaterialWithRecipes} from '@hconnect/common/types'
import {dataTestId} from '@hconnect/uikit'
import {Add} from '@mui/icons-material'
import {Box, Button, Stack} from '@mui/material'
import React from 'react'
import {useFieldArray, useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useIsMutating} from 'react-query'

import {usePermission} from '../../../../permissions'
import {useMaterialsQuery} from '../../../hooks'
import {MaterialFormDefaultValues} from '../../materials-details/ExistingMaterialDetails/hooks'

import {getNewFormRecipe} from './helpers'
import {MaterialFormRecipe} from './MaterialFormRecipe'

import {useAssetsQuery} from '@settings/modules/assets'
import {Skeleton} from '@settings/modules/common/components/skeletons'
import {useScheduleQuery} from '@settings/modules/common/hooks'

interface MaterialSettingsRecipesTabProps {
  material: MaterialWithRecipes
}

export const MaterialSettingsRecipesTab: React.FC<MaterialSettingsRecipesTabProps> = ({
  material
}) => {
  const {t} = useTranslation()
  const canChangeMaterials = usePermission('CHANGE_MATERIALS')
  const isMaterialSaving = !!useIsMutating(['editMaterial'])

  const {data: materials} = useMaterialsQuery()
  if (!materials) {
    throw new Error('BUG: materials must be loaded before rendering recipes tab')
  }

  const {data: assets} = useAssetsQuery()
  const {data: schedule} = useScheduleQuery()

  const {control} = useFormContext<MaterialFormDefaultValues>()

  const {
    fields: formRecipes,
    append: appendRecipe,
    remove: removeRecipe
  } = useFieldArray({
    control,
    name: 'recipes',
    keyName: 'formRecipeId'
  })

  const isRecipeFormDisabled = !canChangeMaterials || isMaterialSaving

  if (!schedule || !assets) {
    return (
      <Skeleton
        height="15vh"
        sx={{
          backgroundColor: ({palette}) => palette.grey[200]
        }}
      />
    )
  }

  return (
    <Stack spacing={3} {...dataTestId('recipe_card')}>
      <Box>
        <Button
          variant="text"
          startIcon={<Add />}
          disabled={isRecipeFormDisabled}
          onClick={() => {
            appendRecipe(getNewFormRecipe(material))
          }}
          {...dataTestId('add_recipe_button')}
        >
          {t('materialsSettings.addRecipe')}
        </Button>
      </Box>
      <Stack spacing={3}>
        {formRecipes.map((formRecipe, index) => {
          return (
            <MaterialFormRecipe
              key={formRecipe.formRecipeId}
              index={index}
              formRecipe={formRecipe}
              disabled={isRecipeFormDisabled}
              materials={materials}
              assets={assets}
              schedule={schedule}
              removeRecipe={removeRecipe}
            />
          )
        })}
      </Stack>
    </Stack>
  )
}
