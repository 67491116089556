import React from 'react'

import {LayoutSettings} from '../LayoutSettings'

import {PageSkeleton} from './PageSkeleton'
import {SettingsDetailsSkeleton} from './SettingsDetailsSkeleton'
import {Skeleton} from './Skeleton'

interface SettingsSkeletonProps {
  numberOfDetailsCards?: number
}
export const SettingsSkeleton: React.FC<SettingsSkeletonProps> = ({numberOfDetailsCards = 1}) => {
  return (
    <PageSkeleton>
      <LayoutSettings
        dataTestId="settings_tab_skeleton"
        list={<Skeleton height="65vh" />}
        details={<SettingsDetailsSkeleton numberOfCards={numberOfDetailsCards} />}
      />
    </PageSkeleton>
  )
}
