import {ItemDetailsLayout} from '../../plant-setup-item-layout'

import {AssetDetails} from './AssetDetails'

import {TreeNode} from '@settings/modules/common/types'

interface LayoutProps {
  selectedItem: TreeNode
}

export const Layout: React.FC<LayoutProps> = ({selectedItem}) => {
  return (
    <ItemDetailsLayout>
      <AssetDetails assetId={selectedItem.upmId} />
    </ItemDetailsLayout>
  )
}
