import {useCallback} from 'react'
import {NodeApi} from 'react-arborist'
import {useSearchParams} from 'react-router-dom'

import {useSelectedItem} from '../../providers'

import {TreeNode} from '@settings/modules/common/types'

export const useHandleNodeSelected = () => {
  const {setSelectedItem} = useSelectedItem()
  const [, setSearchParams] = useSearchParams()

  return useCallback(
    (node?: NodeApi<TreeNode>) => {
      if (!node) return

      setSelectedItem(node.data)
      setSearchParams((params) => {
        params.set('entityId', node.data.upmId)
        return params
      })
    },
    [setSearchParams, setSelectedItem]
  )
}
