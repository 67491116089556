import {AssetType, isProductionMode} from '@hconnect/common/types'
import type {AssetPayload, Asset, OperationModePayload} from '@hconnect/common/types'
import {dataTestId} from '@hconnect/uikit'
import {NumericTextField} from '@hconnect/uikit/src/lib2'
import {TextField, Stack} from '@mui/material'
import {FC} from 'react'
import {useForm, Controller} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {Switch} from '../../../common/components'
import {requiredValidator, submitOnBlurAndEnterProps} from '../../../common/utils'
import {useUrlParam} from '../../../routing'
import {useEditAsset, useEditOperationMode} from '../../hooks'

type BaseloadFormState = Pick<OperationModePayload, 'powerConsumption'> & Pick<AssetPayload, 'name'>

interface BaseloadFormProps {
  baseloadAsset: Asset
  isReadOnly: boolean
}
export const BaseloadForm: FC<BaseloadFormProps> = ({baseloadAsset, isReadOnly}) => {
  const plantCode = useUrlParam('plantCode')
  const {mutate: editAsset} = useEditAsset()
  const {mutate: editAssetOperationMode} = useEditOperationMode()

  const {t} = useTranslation()

  // baseload must have only one production operation mode
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const operationMode = baseloadAsset.operationModes.find(isProductionMode)!

  const {id: baseloadOperationModeId, ...baseloadOperationModeBase} = operationMode

  const initialValues: BaseloadFormState = {
    name: baseloadAsset.name,
    powerConsumption: baseloadOperationModeBase.powerConsumption
  }

  const {
    handleSubmit,
    control,
    reset,
    formState: {dirtyFields, isDirty}
  } = useForm<BaseloadFormState>({
    mode: 'onChange',
    shouldFocusError: false,
    defaultValues: initialValues
  })

  const submit = handleSubmit(({name, powerConsumption}) => {
    if (!isDirty) return
    const onError = () => reset()
    const onSuccess = () => reset({name, powerConsumption})
    if (dirtyFields.name) {
      return editAsset(
        {plantCode, assetId: baseloadAsset.id, key: 'name', dto: {name, type: AssetType.BaseLoad}},
        {onError, onSuccess}
      )
    }
    editAssetOperationMode(
      {
        plantCode,
        assetId: baseloadAsset.id,
        operationModeId: baseloadOperationModeId,
        key: 'powerConsumption',
        dto: {...baseloadOperationModeBase, powerConsumption}
      },
      {onError, onSuccess}
    )
  })

  return (
    <Stack spacing={2} {...dataTestId('edit_baseload_form')}>
      <Controller
        control={control}
        name="name"
        rules={requiredValidator(t)}
        render={({field: {ref, value, onChange}, fieldState: {error}}) => (
          <TextField
            label={t('assetsSettings.assetName')}
            sx={{width: ({spacing}) => spacing(50)}}
            value={value}
            placeholder={value}
            inputRef={ref}
            onChange={onChange}
            helperText={error?.message}
            disabled={isReadOnly}
            error={Boolean(error?.message)}
            {...submitOnBlurAndEnterProps(submit)}
            {...dataTestId('baseload_name_input')}
          />
        )}
      />
      <Controller
        control={control}
        name="powerConsumption"
        rules={requiredValidator(t)}
        render={({field: {ref, value, onChange}, fieldState: {error}}) => (
          <NumericTextField
            sx={{width: ({spacing}) => spacing(18)}}
            label={t('assetsSettings.powerConsumption')}
            inputRef={ref}
            onChange={({target: {value}}) => onChange(value === '' ? '' : Number(value))}
            value={value}
            disabled={isReadOnly}
            error={!!error}
            helperText={error?.message}
            {...submitOnBlurAndEnterProps(submit)}
            {...dataTestId('baseload_power_consumption_input')}
          />
        )}
      />
      {/* isOptimized on baseload should be read only*/}
      <Switch
        value={baseloadAsset.isOptimized}
        label={t('assetsSettings.optimized')}
        disabled
        {...dataTestId('is_optimized_asset_switch')}
      />
    </Stack>
  )
}
