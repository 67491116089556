import {dataTestId} from '@hconnect/uikit'
import {CardBox, CardTitle, KebabMenu, MenuAction} from '@hconnect/uikit/src/lib2'
import {List, ListItem, ListItemSecondaryAction, ListItemText, ListSubheader} from '@mui/material'
import type {SxProps, Theme} from '@mui/material'
import React from 'react'

export interface SettingsListItem {
  text: string
  caption?: string
  isSelected: boolean
  testId?: string
  onClick?: (index: number) => void
  secondaryActions?: MenuAction[]
  listItemTextSx?: SxProps<Theme>
  id?: string
}

export interface HMListProps {
  items: SettingsListItem[]
  headerContent?: string | React.ReactElement
  testId?: string
}

export const SettingsList: React.FC<HMListProps> = ({headerContent, items, testId}) => {
  const isHeaderContentString = typeof headerContent === 'string'

  return (
    <CardBox px={{xs: 0, md: 0}}>
      <List {...dataTestId(testId ?? 'list')}>
        {headerContent && (
          <ListSubheader disableGutters sx={{display: 'flex', mb: 3, pl: 3, pr: 2}}>
            {isHeaderContentString ? (
              <CardTitle sx={{mb: 0}}>{headerContent}</CardTitle>
            ) : (
              headerContent
            )}
          </ListSubheader>
        )}
        {items.map(
          (
            {
              isSelected,
              onClick,
              testId = 'list_item',
              listItemTextSx,
              text,
              caption,
              secondaryActions,
              id
            },
            index
          ) => (
            <ListItem
              button
              key={index}
              selected={isSelected}
              onClick={() => onClick?.(index)}
              divider={index !== items.length - 1}
              sx={{pl: 3}}
              {...dataTestId(testId)}
              id={id ?? `settings-list-item-${index}`}
            >
              <ListItemText
                primaryTypographyProps={{
                  variant: 'h4',
                  sx: listItemTextSx
                }}
                primary={text}
                secondary={caption}
              />
              {secondaryActions && (
                <ListItemSecondaryAction>
                  <KebabMenu
                    actions={secondaryActions}
                    id={`${id ?? `settings-list-item-${index}`}-menu`}
                  />
                </ListItemSecondaryAction>
              )}
            </ListItem>
          )
        )}
      </List>
    </CardBox>
  )
}
