import {Material, MaterialType} from '@hconnect/common/types'
import {Loader} from '@hconnect/uikit/src/lib2'
import {useMemo} from 'react'

import {useSettingsQuery} from '../../../common/providers'
import {useUrlParam} from '../../../routing'
import {useCreateDepartmentConfig} from '../../hooks/departmentConfig/useCreateDepartmentConfig'
import {useDepartmentConfig} from '../../hooks/departmentConfig/useDepartmentConfig'
import {useUpdateQuarryConfig} from '../../hooks/departmentConfig/useUpdateQuarryConfig'
import {ItemDetailsLayout} from '../../plant-setup-item-layout'
import {DepartmentConfigDto} from '../../types'

import {ConfigForm} from './ConfigForm'

import {TreeNode} from '@settings/modules/common/types'

const departmentHeaders = {
  '05_5': 'WHR',
  '05_6': 'COPG',
  '06_1': 'FuelMaterialsHandling',
  '06_4': 'CoalMill',
  '10_2': 'Quarry',
  '20_4': 'Crusher',
  '25_1': 'RawMaterialDryer',
  '25_2': 'RawMaterialDeforesting',
  '30_4': 'RawMill',
  '40_4': 'Kiln',
  '41_1': 'ClayCalcination',
  '45_1': 'CementDryer',
  '49_0': 'CarbonCaptureUtilization',
  '50_4': 'CementMill',
  '55_1': 'CementBlending'
}

const CONFIG_TYPE = 'JanusProductionData'

const getInitialConfig = (
  plantCode: string,
  departmentConfig?: DepartmentConfigDto,
  upmId?: string
): DepartmentConfigDto => {
  return {
    metadata: {
      configurationType: CONFIG_TYPE,
      plantId: plantCode,
      upmType: 'Department',
      typeUpmId: upmId ?? ''
    },
    payload: {productionData: departmentConfig?.payload?.productionData || []}
  }
}

interface ConfigurationProps {
  selectedItem: TreeNode
}

export const Configuration: React.FC<ConfigurationProps> = ({selectedItem}) => {
  const entityCodesPath = useMemo(() => {
    return selectedItem.entityCodesPath ? selectedItem.entityCodesPath : ''
  }, [selectedItem.entityCodesPath])

  const {data: departmentConfig, isLoading, isFetching} = useDepartmentConfig(selectedItem.upmId)
  const {mutateAsync: createDepartmentConfig} = useCreateDepartmentConfig()
  const {mutateAsync: updateDepartmentConfig} = useUpdateQuarryConfig()

  const plantCode = useUrlParam('plantCode')
  const {
    data: materials,
    isLoading: isLoadingMaterials,
    isFetching: isFetchingMaterials
  } = useSettingsQuery('materials', [plantCode])
  const configNotCreated = useMemo(
    () => !isLoading && !departmentConfig,
    [departmentConfig, isLoading]
  )
  const isLoadingOrFetching = isLoading || isFetching || isLoadingMaterials || isFetchingMaterials

  if (isLoadingOrFetching) {
    return (
      <ItemDetailsLayout>
        <Loader />
      </ItemDetailsLayout>
    )
  }

  const initialConfig: DepartmentConfigDto = getInitialConfig(
    plantCode,
    departmentConfig,
    selectedItem.upmId
  )

  const config = departmentConfig || initialConfig

  const handleCreateConfig = (departmentConfig: DepartmentConfigDto) =>
    createDepartmentConfig({plantCode, departmentConfig})

  const handleUpdateConfig = (departmentConfig: DepartmentConfigDto) =>
    updateDepartmentConfig({plantCode, departmentConfig})

  const onSubmit = configNotCreated ? handleCreateConfig : handleUpdateConfig

  return (
    <ConfigForm
      selectedItem={selectedItem}
      configNotCreated={configNotCreated}
      config={config}
      onSubmit={onSubmit}
      materials={materials || []}
      type={departmentHeaders[entityCodesPath]}
    />
  )
}
