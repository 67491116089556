import {dataTestId} from '@hconnect/uikit'
import {Stack, TextField} from '@mui/material'
import React from 'react'

import {Checkbox} from '@settings/modules/common/components'

interface FakeTransitionFormProps {
  label: string
  isReadOnly: boolean
}

export const FakeTransitionForm: React.FC<FakeTransitionFormProps> = ({label, isReadOnly}) => (
  <Stack direction="row">
    <Checkbox value={false} disabled />
    <TextField
      fullWidth
      disabled
      label={label}
      value="-"
      sx={
        isReadOnly
          ? undefined
          : {
              '& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
                border: ({palette}) => `1px solid ${palette.grey[300]}`
              }
            }
      }
      {...dataTestId('fake_transition_input')}
    />
  </Stack>
)
