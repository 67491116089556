import {AssetType} from '@hconnect/common/types'
import React, {useLayoutEffect} from 'react'
import {useNavigate} from 'react-router-dom'

import {SettingsDetailsSkeleton} from '../../common/components'
import {useAssetsQuery} from '../hooks'

import {AssetDetails} from './AssetDetails'

import {PLANT_ROUTES, getUrl, useUrlParam} from '@settings/modules/routing'

export const PageAsset: React.FC = () => {
  const assetType = useUrlParam('assetType')
  const {data: assets} = useAssetsQuery({sorted: true, types: [assetType as AssetType]})
  const plantCode = useUrlParam('plantCode')
  const assetId = useUrlParam('assetId')
  const navigate = useNavigate()

  const isAssetIdValid = !!assets?.find((asset) => asset.id === Number(assetId))

  const firstAssetId = assets?.[0]?.id

  // effect to redirect to default asset type if the asset type is not valid
  useLayoutEffect(() => {
    if (!isAssetIdValid) {
      navigate(
        getUrl(PLANT_ROUTES.ASSETS.ASSET_TYPE.ID.path, {
          plantCode,
          assetType: AssetType.CementMill,
          assetId: String(firstAssetId)
        })
      )
    }
  }, [isAssetIdValid, navigate, plantCode, assetType, firstAssetId])

  if (!isAssetIdValid) {
    return <SettingsDetailsSkeleton numberOfCards={3} />
  }

  return <AssetDetails />
}
