import {ClinkerClassificationInputNames} from '@hconnect/common/enums'
import {MaterialType} from '@hconnect/common/types'
import {dataTestId} from '@hconnect/uikit/src/common/utils/testingUtils'
import {Stack} from '@mui/material'

import {ClassificationAutocomplete} from './inputs/ClassificationAutocomplete'
import {ClassificationCheckBox} from './inputs/ClassificationCheckbox'

import {useMaterialsClassificationMetadata} from '../../../hooks'
import {MaterialsGrid} from '../components/MaterialsGrid'
import {MaterialsGridItem} from '../components/MaterialsGridItem'

interface ClinkerClassificationProps {
  isDisabled: boolean
}

export const ClinkerClassification: React.FC<ClinkerClassificationProps> = ({isDisabled}) => {
  const {data: metadata} = useMaterialsClassificationMetadata(MaterialType.Clinker)

  if (!metadata) {
    throw new Error(
      'BUG: classification metadata should be loaded before rendering classification tab'
    )
  }

  return (
    <MaterialsGrid {...dataTestId('clinker_classification_form')}>
      <MaterialsGridItem>
        <ClassificationAutocomplete
          materialType={MaterialType.Clinker}
          inputName={ClinkerClassificationInputNames.Type}
          metadata={metadata}
          isDisabled={isDisabled}
        />
      </MaterialsGridItem>
      <MaterialsGridItem>
        <ClassificationAutocomplete
          materialType={MaterialType.Clinker}
          inputName={ClinkerClassificationInputNames.Grade}
          metadata={metadata}
          isDisabled={isDisabled}
        />
      </MaterialsGridItem>
      <MaterialsGridItem>
        <ClassificationCheckBox
          materialType={MaterialType.Clinker}
          inputName={ClinkerClassificationInputNames.SulphateResistance}
          metadata={metadata}
          customValues={{trueValue: 'SR', falseValue: '-'}}
          isDisabled={isDisabled}
        />
      </MaterialsGridItem>
      <MaterialsGridItem>
        <ClassificationCheckBox
          materialType={MaterialType.Clinker}
          inputName={ClinkerClassificationInputNames.AlkaliContent}
          metadata={metadata}
          customValues={{trueValue: 'LA', falseValue: '-'}}
          isDisabled={isDisabled}
        />
      </MaterialsGridItem>
      <MaterialsGridItem mb={4}>
        <ClassificationCheckBox
          materialType={MaterialType.Clinker}
          inputName={ClinkerClassificationInputNames.LowHeat}
          metadata={metadata}
          customValues={{trueValue: 'LH', falseValue: '-'}}
          isDisabled={isDisabled}
        />
      </MaterialsGridItem>
    </MaterialsGrid>
  )
}
