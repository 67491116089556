import {Asset, MaterialStorage} from '@hconnect/common'
import type {Data, Node} from 'unist'

export enum UpmEntityType {
  Plant = 'Plant',
  Area = 'Area',
  ProductionLine = 'ProductionLine',
  Department = 'Department',
  Equipment = 'Equipment'
}

export enum UpmDepartmentOrEquipmentType {
  Asset = 'Asset',
  MaterialStorage = 'MaterialStorage'
}

export enum StorageDepartmentNodeKey {
  FuelsSiloStorage = 'Fuels_SiloStorage',
  AuxiliaryMaterialStorageSilos = 'Auxiliary_MaterialStorageSilos',
  QuarryMaterialStorage = 'Quarry_MaterialStorage',
  CrushingRawMaterialRawMaterialStorage = 'CrushingRawMaterial_RawMaterialStorage',
  RawMaterialPreparationRawMealSilo = 'RawMaterialPreparation_RawMealSilo',
  ClinkerProductionClinkerStorage = 'ClinkerProduction_ClinkerStorage',
  CementGrindingCementSilos = 'CementGrinding_CementSilos',
  PackingLoadingProductStorage = 'PackingLoading_ProductStorage'
}

export enum StorageEquipmentNodeKey {
  Bin = 'Bin',
  Bucket = 'Bucket',
  HopperBin = 'HopperBin',
  ReservoirAirReceiverGasTank = 'ReservoirAirReceiverGasTank',
  StorageSilo = 'StorageSilo',
  TankWaterOilOthers = 'TankWaterOilOthers',
  Wagon = 'Wagon'
}

export interface BaseNodeType {
  code: string
  name: string
  key?: string
}

export interface BaseNode {
  upmId: string
  itemType: UpmEntityType
  type?: BaseNodeType
  entityCodesPath?: string
  text?: string
}

export type PlantNode = BaseNode & {
  itemType: UpmEntityType.Plant
  plantId: string
  siteId: string
  plantName: string
  plantIdHistory: {
    plantId: string
    periodStart: string
    periodEnd: string
  }[]
  children: AreaNode[]
}

export type AreaNode = BaseNode & {
  itemType: UpmEntityType.Area
  orderGroup: number
  children: ProductionLineNode[]
}

export type ProductionLineNode = BaseNode & {
  itemType: UpmEntityType.ProductionLine
  productionLineCode: string
  children: DepartmentNode[]
}

export type DepartmentNode = BaseNode & {
  itemType: UpmEntityType.Department
  children: EquipmentNode[]
  displayValue?: string
  sourceSystemCode?: string
  departmentOrEquipmentType?: UpmDepartmentOrEquipmentType
  enabledForPlanningItem?: Asset | MaterialStorage
}

export type EquipmentNode = BaseNode & {
  itemType: UpmEntityType.Equipment
  children: []
  departmentOrEquipmentType?: UpmDepartmentOrEquipmentType
  enabledForPlanningItem?: Asset | MaterialStorage
}

export type TreeNode = PlantNode | AreaNode | ProductionLineNode | DepartmentNode | EquipmentNode

export interface EntityProperty {
  code: string
  name: string
}
export interface StructureNodeData extends Data {
  id: string
  upmId: string
  name: string
  signalCount: number
  entityProperties?: EntityProperty
  entityLabel: string
}
export interface StructureNodeUnist extends Node {
  entityType: UpmEntityType
  data: StructureNodeData
  children?: StructureNodeUnist[]
  entityProperties?: EntityProperty
}

export interface StructureNodeUnistWithAncestors extends StructureNodeUnist {
  ancestors: StructureNodeUnist[]
}
