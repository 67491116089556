import {Feature} from '@hconnect/common/enums'
import type {Env} from '@hconnect/common/hproduce'
import {getPlantName} from '@hconnect/common/hproduce/whiteListCheck'
import {CommonHeader, useGuidedTours} from '@hconnect/uikit/src/lib2'
import type {User, NavItem} from '@hconnect/uikit/src/lib2'
import type {i18n as I18nType} from 'i18next'
import React, {FC, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {useLocation, useNavigate} from 'react-router-dom'

import {usePermission, usePermissionsQuery} from '../../permissions/hooks'
import {getUrl, PLANT_ROUTES, ROUTES_PERMISSIONS} from '../../routing'
import {FeatureFlagKey, useFeatureFlag} from '../hooks/useFeatureFlag'
import {useSettingsFeature} from '../hooks/useSettingsFeature'
import {useLoginDetails} from '../providers/LoginProvider'

const getSupportedLanguages = (i18n: I18nType) => {
  const language = i18n.language
  const languagesRaw = i18n.options?.supportedLngs || [language]
  return languagesRaw.filter((code) => code !== 'cimode')
}

interface SettingsHeaderProps {
  plantCode: string
}

export const SettingsHeader: FC<SettingsHeaderProps> = ({plantCode}) => {
  const {t, i18n} = useTranslation()

  const navigate = useNavigate()
  const location = useLocation()
  const pathName = location.pathname

  const {data: permissions} = usePermissionsQuery()
  const {loginDetails, logout} = useLoginDetails()

  // User permissions
  const canReadJanusConfig = usePermission('READ_JANUS_PLANT_CONFIG')
  const canReadJanusData = usePermission('READ_JANUS_PLANT_DATA')

  const canViewUpm = usePermission('VIEW_UPM')

  const canViewStorages = usePermission('VIEW_MATERIAL_STORAGE')
  const canViewAssets = usePermission('VIEW_ASSETS')

  const canViewMaterials = usePermission('VIEW_MATERIALS')
  const canViewMeasurements = usePermission(ROUTES_PERMISSIONS.MEASUREMENTS)
  const canViewSamplingPoints = usePermission(ROUTES_PERMISSIONS.SAMPLING_POINTS)

  // Feature flags
  // TODO: update the permission when new permission is created on BE
  // VIEW_MATERIAL_STORAGE and CHANGE_MATERIAL_STORAGE
  const isKpiCalculationsTabEnabled = useFeatureFlag(FeatureFlagKey.KpiCalculationsTab)
  const isUpmStructureEnabled = useSettingsFeature(Feature.UpmAssetsAndStorages)

  // Calculated conditions
  const canViewJanus = canReadJanusConfig || canReadJanusData

  const canViewKPICalculation = canViewStorages && isKpiCalculationsTabEnabled

  const canViewPlantSetupTab = canViewUpm
  const canViewStoragesTab = canViewStorages && !isUpmStructureEnabled
  const canViewAssetsTab = canViewAssets && !isUpmStructureEnabled

  const navItems = useMemo(() => {
    if (!plantCode) {
      return []
    }
    const navItemsConfig: {condition: boolean; item: NavItem}[] = [
      {
        condition: canViewPlantSetupTab,
        item: {
          label: t('upmSettings.plantSetup'),
          url: getUrl(PLANT_ROUTES.PLANT_SETUP.path, {plantCode}),
          dataTestId: 'navItem_plant_setup',
          id: 'plant_setup-nav-item'
        }
      },
      {
        condition: canViewStoragesTab,
        item: {
          label: t('storagesSettings.storages'),
          url: getUrl(PLANT_ROUTES.STORAGES.path, {plantCode}),
          dataTestId: 'navItem_storages',
          id: 'storages-nav-item'
        }
      },
      {
        condition: canViewAssetsTab,
        item: {
          label: t('assetsSettings.assets'),
          url: getUrl(PLANT_ROUTES.ASSETS.path, {plantCode}),
          dataTestId: 'navItem_assets'
        }
      },
      {
        condition: canViewMaterials,
        item: {
          label: t('materialsSettings.materials'),
          url: getUrl(PLANT_ROUTES.MATERIALS.path, {plantCode}),
          dataTestId: 'navItem_materials',
          id: 'materials-nav-item'
        }
      },
      {
        condition: canViewSamplingPoints,
        item: {
          label: t('samplingPointsSettings.samplingPoints'),
          url: getUrl(PLANT_ROUTES.SAMPLING_POINTS.path, {plantCode}),
          dataTestId: 'navItem_sampling_points',
          id: 'sampling-points-nav-item'
        }
      },
      {
        condition: canViewMeasurements,
        item: {
          label: t('measurementsSettings.measurements'),
          url: getUrl(PLANT_ROUTES.MEASUREMENTS.path, {plantCode}),
          dataTestId: 'navItem_measurements',
          id: 'measurements-nav-item'
        }
      },
      {
        condition: canViewKPICalculation,
        item: {
          label: t('kpiCalculationSettings.kpiCalculation'),
          url: getUrl(PLANT_ROUTES.KPI_CALCULATION.path, {plantCode}),
          dataTestId: 'navItem_kpi_cal',
          id: 'kpi-cal-nav-item'
        }
      },
      {
        condition: canViewJanus,
        item: {
          label: t('janusConfig.tabLabel'),
          url: getUrl(PLANT_ROUTES.ENTRY_DAILY.path, {plantCode}),
          dataTestId: 'navItem_janus',
          id: 'janus-nav-item'
        }
      }
    ]

    return navItemsConfig.filter(({condition}) => condition).map(({item}) => item)
  }, [
    plantCode,
    canViewPlantSetupTab,
    canViewStoragesTab,
    canViewAssetsTab,
    canViewMaterials,
    canViewSamplingPoints,
    canViewMeasurements,
    canViewKPICalculation,
    canViewJanus,
    t
  ])

  const {tours, tourStatuses} = useGuidedTours()

  if (!plantCode) return null

  const supportedLanguages = getSupportedLanguages(i18n)

  const user: User = {
    email: loginDetails?.email,
    name: loginDetails?.name ?? undefined
  }

  return (
    <CommonHeader
      appName="Settings"
      plant={{
        plantName: getPlantName(plantCode),
        plantId: plantCode
      }}
      env={process.env.REACT_APP_STAGE as Env}
      permissions={permissions ?? []}
      navItems={navItems}
      navigate={navigate}
      locationPath={pathName}
      supportedLanguages={supportedLanguages}
      user={user}
      onLogout={() => void logout()}
      guidedTours={{tours, statuses: tourStatuses}}
    />
  )
}
