export enum UnitType {
  Numeric = 1,
  Time = 2,
  Text = 3,
  Percentage = 4,
  Gigajoule = 5
}

export enum PlantStructureFilterOptions {
  All = 'all',
  Storages = 'storages',
  Planner = 'planner',
  Janus = 'janus'
}
