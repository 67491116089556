import React from 'react'

import {PageLayout} from '../PageLayout'

import {Skeleton} from './Skeleton'

export const PageSkeleton = ({children}: {children?: React.ReactNode}) => {
  return (
    <PageLayout title={<Skeleton width="25vh" />}>
      {children ?? <Skeleton height="65vh" />}
    </PageLayout>
  )
}
