import {dataTestId} from '@hconnect/uikit'
import {ChevronRight, ExpandMore, Add as AddIcon} from '@mui/icons-material'
import {
  Divider,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  alpha
} from '@mui/material'
import {styled} from '@mui/material/styles'
import {useCallback} from 'react'
import {NodeRendererProps} from 'react-arborist'

import {useLoginDetails} from '../../common/providers'
import {TreeNode, UpmEntityType} from '../../common/types'
import {useItemLink} from '../providers/ItemLinkProvider'

const StyledListItem = styled(ListItem)(() => ({
  '&:hover .icon-button': {
    visibility: 'visible'
  },
  '.icon-button': {
    visibility: 'hidden'
  }
}))

// These are the user ids that are allowed to map UPM nodes to subdomains
// Currently only Starikov Daniil and Gockeln Kilian on QA/UAT/PROD
const userIdsAllowedToMapUpmNodes = [
  '6b8a54b2-cfa6-407c-8ad5-e88780152f10',
  '86f532f3-66d0-4545-88da-510ec1ad70e6',
  '6296fa10-24f2-4528-bfbd-468d08cb1d2d',
  '5264e312-4be4-4fa3-a9e6-e09723ffc709',
  '0ccfea22-d1fa-4b7f-bd13-9ffbb2f8690c',
  '4767a8df-a149-41b0-8784-73c01b586a8e',
  '5f22fce9-0a59-45e5-8f65-f546d3b84536',
  'ba1100d7-d746-4319-989d-cf1f01cbefbd',
  '40c85dc6-5630-4afb-bcce-a1c6a0edeeba'
]

export const PlantStructureNode = <T extends TreeNode>({node, style}: NodeRendererProps<T>) => {
  const {loginDetails} = useLoginDetails()
  const {setItemToLink} = useItemLink()

  const userId = loginDetails?.userId
  const canAddSubdomain = !!userId && userIdsAllowedToMapUpmNodes.includes(userId)

  const shouldHideArrow =
    node.isLeaf || node.data.children.length === 0 || node.data.itemType === UpmEntityType.Plant

  const shouldShowAddSubdomain =
    canAddSubdomain &&
    (node.data.itemType === UpmEntityType.Department ||
      node.data.itemType === UpmEntityType.Equipment)

  const handleExpandCollapseNode = useCallback(
    (event: React.MouseEvent) => {
      node.isInternal && node.toggle()
      event.stopPropagation()
    },
    [node]
  )

  const handleLinkItemToNode = useCallback(
    (event: React.MouseEvent) => {
      setItemToLink(node.data.upmId)
      event.stopPropagation()
    },
    [node.data.upmId, setItemToLink]
  )

  const label = node.data.text

  return (
    <Stack>
      <StyledListItem dense disablePadding {...dataTestId(`list-item-${label}`)}>
        <ListItemButton
          style={style}
          sx={{
            backgroundColor: ({palette}) =>
              node.isSelected ? alpha(palette.primary.light, 0.1) : 'inherit',
            height: 56
          }}
        >
          <ListItemIcon
            sx={{
              display: shouldHideArrow ? 'none' : 'flex',
              minWidth: 30
            }}
            onClick={handleExpandCollapseNode}
          >
            {node.isOpen ? <ExpandMore color="primary" /> : <ChevronRight color="primary" />}
          </ListItemIcon>
          <ListItemText
            sx={{pl: shouldHideArrow ? 4 : 0}}
            primaryTypographyProps={{noWrap: true}}
            primary={label}
          />
          {shouldShowAddSubdomain && (
            <IconButton className="icon-button" onClick={handleLinkItemToNode}>
              <AddIcon />
            </IconButton>
          )}
        </ListItemButton>
      </StyledListItem>
      <Divider variant="middle" sx={{mx: 4}} />
    </Stack>
  )
}
