import {Permission, Role, User} from '@hconnect/apiclient'
import {AppStage, MockStore} from '@hconnect/common/mocking'

import {TEST_PLANT_CODE, TEST_USER_ID, TEST_USER_NAME} from '../../cypress/consts'
import {MockState} from '../../src/__mock__/mockState'

import {sampleDataset01} from './sampleDataset'
import assetsPermissions from './sampleDatasets/01/permissions/assetsPermissions.json'
import janusPermissions from './sampleDatasets/01/permissions/janusPermissions.json'
import machinePlanPermissions from './sampleDatasets/01/permissions/machinePlanPermissions.json'
import materialsPermissions from './sampleDatasets/01/permissions/materialsPermissions.json'
import measurementsPermissions from './sampleDatasets/01/permissions/measurementsPermissions.json'
import samplingPointsPermissions from './sampleDatasets/01/permissions/samplingPointsPermissions.json'
import storagePermissions from './sampleDatasets/01/permissions/storagePermissions.json'
import upmPermissions from './sampleDatasets/01/permissions/upmPermissions.json'

export const defaultRoleTypes: Role['roleType'][] = [
  'PRODUCTION_SUPERVISOR',
  'CONTROL_ROOM_OPERATOR',
  'PLANT_DIRECTOR',
  'MANAGING_DIRECTOR'
]

export const defaultRoles: Role[] = defaultRoleTypes.map(
  (roleType): Role =>
    ({
      roleType,
      dataScope: {businessLine: 'CEM', countryId: 'DE', plantId: TEST_PLANT_CODE}
    }) as Role
)

export const defaultPermissions: Permission[] = [
  ...(machinePlanPermissions as Permission[]),
  ...(materialsPermissions as Permission[]),
  ...(assetsPermissions as Permission[]),
  ...(storagePermissions as Permission[]),
  ...(measurementsPermissions as Permission[]),
  ...(samplingPointsPermissions as Permission[]),
  ...(upmPermissions as Permission[]),
  ...(janusPermissions as Permission[])
]

// This mockStore is used for mocking BE data in local development.
// Use mockStore.enable() in brower console to enable mocking
export const mockStore = MockStore.enableMocking<MockState>(
  'Settings',
  process.env.REACT_APP_STAGE as AppStage
)

mockStore.onEnable(() => {
  mockStore.setScenarioData(sampleDataset01)

  const testUser: User = mockStore.users.addUser({
    id: TEST_USER_ID,
    name: TEST_USER_NAME,
    hasRoles: defaultRoles
  })
  mockStore.users.login(testUser)

  defaultPermissions.map((permission) =>
    mockStore.permissions.addPermission(permission.userId, permission.permissionType, {
      dataScope: permission.dataScope
    })
  )
})

mockStore.onDisable(() => {
  mockStore.users.logout()
})
