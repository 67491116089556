import {usePermission} from '../../../permissions'
import {isAssetEnabledForPlanning, isDepartmentOrEquipmentNode} from '../../helpers'
import {ItemDetailsLayout} from '../../plant-setup-item-layout'
import {useSelectedItem} from '../../providers'

import {StandardOperationTimesCard} from './StandardOperationTimesCard'

export const StandardTimes: React.FC = () => {
  const {selectedItem} = useSelectedItem()
  const canChangeAssets = usePermission('CHANGE_ASSETS')
  const canChangeMachinePlan = usePermission('CHANGE_MACHINE_PLAN')

  if (
    !isDepartmentOrEquipmentNode(selectedItem) ||
    !isAssetEnabledForPlanning(selectedItem.enabledForPlanningItem)
  )
    return null

  const asset = selectedItem.enabledForPlanningItem

  return (
    <ItemDetailsLayout>
      <StandardOperationTimesCard
        asset={asset}
        isReadOnly={!canChangeAssets || !canChangeMachinePlan}
      />
    </ItemDetailsLayout>
  )
}
