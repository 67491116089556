export const departmentsWithConfig = [
  '05_5',
  '05_6',
  '06_1',
  '06_4',
  '10_2',
  '20_4',
  '25_1',
  '25_2',
  '30_4',
  '40_4',
  '41_1',
  '45_1',
  '49_0',
  '50_4',
  '55_1'
]
