import {useNotification} from '@hconnect/uikit/src/common'
import {AxiosError} from 'axios'
import {useErrorHandler} from 'react-error-boundary'
import {useTranslation} from 'react-i18next'
import {UseQueryResult} from 'react-query'

import {ErrorResponse} from '../../../common/types'
import {notifyIfErrorMessage} from '../../../common/utils/errorHandling'
import {MEASUREMENT_TYPES_MAPPINGS_QUERY_KEY} from '../../api/queries/getMeasurementTypesMappings'
import {MeasurementTypesMapping} from '../../types/MeasurementTypesMapping'

import {useSettingsQuery} from '@settings/modules/common/providers'
import {useUrlParam} from '@settings/modules/routing'

export function useMeasurementTypesMappingsQuery(): UseQueryResult<MeasurementTypesMapping[]> {
  const plantCode = useUrlParam('plantCode')
  const {t} = useTranslation()
  const {notify} = useNotification()
  const raiseError = useErrorHandler()

  return useSettingsQuery(MEASUREMENT_TYPES_MAPPINGS_QUERY_KEY, [plantCode], {
    onError: (error) => {
      const axiosError = error as AxiosError<ErrorResponse>
      const errorDetails =
        axiosError?.response?.data.detail ?? axiosError.message ?? axiosError.response?.status

      notifyIfErrorMessage(
        t('measurementsSettings.queryError', {
          entity: 'Measurement Types Mappings',
          details: errorDetails
        }),
        raiseError,
        notify
      )
    }
  })
}
