import {KebabMenu} from '@hconnect/uikit/src/lib2'
import {DeleteOutlined} from '@mui/icons-material'
import {ListItemSecondaryAction} from '@mui/material'
import React from 'react'
import {NodeApi} from 'react-arborist'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'

import {MaterialTreeNode} from '../../types'

import {PLANT_ROUTES, getUrl, useUrlParam} from '@settings/modules/routing'

interface MaterialsTreeNodeActionsProps {
  node: NodeApi<MaterialTreeNode>
}

export const NewMaterialNodeActions: React.FC<MaterialsTreeNodeActionsProps> = ({node}) => {
  const {t} = useTranslation()
  const plantCode = useUrlParam('plantCode')
  const navigate = useNavigate()
  const redirectToMaterialType = () => {
    navigate(getUrl(PLANT_ROUTES.MATERIALS.TYPE.path, {plantCode}))
  }

  return (
    <ListItemSecondaryAction>
      <KebabMenu
        id={`${`settings-tree-item-${node.data.name}`}-menu`}
        actions={[
          {
            icon: <DeleteOutlined fontSize="small" />,
            title: t('common.delete'),
            testId: 'cancel_adding_new_material',
            isDisabled: false,
            onClick: redirectToMaterialType
          }
        ]}
      />
    </ListItemSecondaryAction>
  )
}
