import {AssetType} from '@hconnect/common/types'
import {Loader} from '@hconnect/uikit/src/lib2'

import {usePermission} from '../../../permissions'
import {ItemDetailsLayout} from '../../plant-setup-item-layout'

import {BaseLoadForm} from './BaseLoadForm'

import {useAssetsQuery} from '@settings/modules/assets'

export const BaseLoad: React.FC = () => {
  const canChangeAssets = usePermission('CHANGE_ASSETS')

  const {data: baseLoadAssets} = useAssetsQuery({types: [AssetType.BaseLoad]})

  if (!baseLoadAssets) return <Loader />

  return (
    <ItemDetailsLayout>
      <BaseLoadForm baseLoadAsset={baseLoadAssets[0]} isReadOnly={!canChangeAssets} />
    </ItemDetailsLayout>
  )
}
