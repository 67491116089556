import {useNotification} from '@hconnect/uikit/src/common'
import {AxiosError} from 'axios'
import {useErrorHandler} from 'react-error-boundary'
import {useTranslation} from 'react-i18next'
import {useMutation} from 'react-query'

import {mutations} from '../../../../api/mutations'
import {getSettingsQueryData, removeSettingsQuery} from '../../../common/providers/ApiInterface'
import {ErrorResponse} from '../../../common/types'
import {notifyIfErrorMessage} from '../../../common/utils/errorHandling'

import {settingsQueryClient} from '@settings/modules/common/providers'

export const useDeleteAsset = () => {
  const {notify} = useNotification()
  const raiseError = useErrorHandler()
  const {t} = useTranslation()

  return useMutation<void, AxiosError<ErrorResponse>, Parameters<typeof mutations.deleteAsset>[0]>(
    mutations.deleteAsset,
    {
      onError: (error) => {
        notifyIfErrorMessage(error.response?.data.detail, raiseError, notify)
      },
      onSuccess: (data, variables) => {
        notify('success', t('success.deleteAsset'))
        const prevAssets = getSettingsQueryData('assets', [variables.plantCode])
        if (prevAssets) {
          void settingsQueryClient.setQueryData(
            ['assets', variables.plantCode],
            prevAssets.filter((asset) => asset.id !== variables.assetId)
          )
        }
        removeSettingsQuery('schedule')
      }
    }
  )
}
