import {Feature} from '@hconnect/common/enums'
import {GuidedTour, GuidedTourStep} from '@hconnect/uikit/src/lib2/components/guidedTour/types'
import {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'

import {useSettingsFeature} from '../modules/common/hooks'
import {getUrl, PLANT_ROUTES, useUrlParam} from '../modules/routing'

import {ReactComponent as Illustration} from './Onboarding_illustration.svg'

const getStepTranslations = (index: number, key: 'title' | 'description') =>
  `guidedTours.introductionTour.steps.${index}.${key}`

export const useSettingsTours = (): Record<string, GuidedTour> => {
  const {t} = useTranslation()
  const plantCode = useUrlParam('plantCode')
  const navigate = useNavigate()
  const isUpmAssetsAndStoragesEnabled = useSettingsFeature(Feature.UpmAssetsAndStorages)

  const startRoute = isUpmAssetsAndStoragesEnabled
    ? PLANT_ROUTES.MATERIALS.path
    : PLANT_ROUTES.STORAGES.path

  return useMemo((): Record<string, GuidedTour> => {
    const nonUpmStorageSteps: GuidedTourStep[] = !isUpmAssetsAndStoragesEnabled
      ? [
          {
            target: '#storages-nav-item',
            title: t(getStepTranslations(1, 'title')),
            content: t(getStepTranslations(1, 'description')),
            placement: 'auto'
          },
          {
            target: '#storage-add-button',
            title: t(getStepTranslations(2, 'title')),
            content: t(getStepTranslations(2, 'description')),
            placement: 'auto',
            disableScrolling: true
          },
          {
            target: '#settings-list-item-0-menu',
            title: t(getStepTranslations(3, 'title')),
            content: t(getStepTranslations(3, 'description')),
            placement: 'auto',
            disableScrolling: true
          },
          {
            target: '#storage-details',
            title: t(getStepTranslations(4, 'title')),
            content: t(getStepTranslations(4, 'description')),
            placement: 'left',
            onGoPrevToStep: () =>
              new Promise((resolve) =>
                setTimeout(() => {
                  navigate(getUrl(PLANT_ROUTES.STORAGES.path, {plantCode}))
                  resolve()
                }, 500)
              )
          }
        ]
      : []

    return {
      introductionTour: {
        title: t('guidedTours.introductionTour.title'),
        description: t('guidedTours.introductionTour.description'),
        onTourStarted: () => navigate(getUrl(startRoute, {plantCode})),
        image: <Illustration />,
        steps: [
          {
            target: '#burger-menu',
            title: t(getStepTranslations(0, 'title')),
            content: t(getStepTranslations(0, 'description')),
            placement: 'left'
          },
          ...nonUpmStorageSteps,
          {
            target: '#materials-nav-item',
            title: t(getStepTranslations(5, 'title')),
            content: t(getStepTranslations(5, 'description')),
            placement: 'bottom',
            onGoNextToStep: () => navigate(getUrl(PLANT_ROUTES.MATERIALS.path, {plantCode}))
          },
          {
            target: '#materials-source-button',
            title: t(getStepTranslations(6, 'title')),
            content: t(getStepTranslations(6, 'description')),
            placement: 'bottom',
            disableScrolling: true
          },
          {
            target: '#account-menu',
            title: t(getStepTranslations(7, 'title')),
            content: t(getStepTranslations(7, 'description')),
            placement: 'bottom'
          }
        ]
      }
    }
  }, [isUpmAssetsAndStoragesEnabled, t, navigate, plantCode, startRoute])
}
