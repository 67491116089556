export enum SelectedView {
  'plantSetup' = 0,
  'dailyEntry' = 1,
  'monthlyEntry' = 2,
  'standardReports' = 3,
  'pss' = 4
}

export enum Status {
  Empty = 0, // no icon
  Ok = 1, // error icon
  Error = 2, // icon
  PartiallySaved = 3, // error icon
  Saved = 4, // empty circle
  Approved = 5 // success
}

export enum CalendarRange {
  Monthly = 'month',
  Weekly = 'week',
  Yearly = 'year'
}

export enum EventType {
  Version = 1,
  Comment = 2
}

export enum EditKpiStatus {
  Success = 0,
  VersionConcurrencyFail = 1,
  ValidationFail = 2,
  NotUpdated = 3,
  NetHeatValueValidationFail = 4
}

export enum MonthlyReportApproveStatus {
  Success = 1,
  Failed = 2,
  ReportInStateNotReadyToBeApproved = 3
}

export enum SaveButtonState {
  Disabled = 0,
  Changed = 1,
  Saved = 2,
  Hidden = 3
}

export enum WarningMessage {
  NoDataFor3Days = 1,
  FillDataReminder = 2,
  DataOverDue = 3
}

export enum MonthsFilterOptions {
  Month = 1,
  Quarter = 2,
  HalfYear = 3,
  Year = 4
}

export enum MonthlyDataType {
  All = 1,
  Autofilled = 2,
  Manual = 3,
  Missing = 4
}

export enum DataTypeFilterOptions {
  Manual = 'manual',
  Autogenerated = 'autogenerated'
}

export enum CompareToOptions {
  OriginalPlan = 'originalPlan'
}

export enum PssPageShowOptions {
  PL = 0,
  ACFC = 1
}

export enum KpiValueType {
  Planned,
  Forecast,
  Actual
}
