import MockAdapter from 'axios-mock-adapter'
import {flow} from 'lodash'

import {TEST_PLANT_CODE} from '../../cypress/consts'
import {Api} from '../api'
import {PlantsResponse} from '../modules/common/types'

import {
  enableMaterialsEndpoints,
  enableSchedulerEndpoints,
  enableAssetsEndpoints,
  enableMaterialStorageEndpoints,
  enableSalesForecastEndpoints,
  enableKpisEndpoints,
  enableMeasurementsEndpoints,
  enableUpmEndpoints
} from './mockServices'
import {enableDSEndpoints} from './mockServices/ds.mock'
import {enableJanusEndpoints} from './mockServices/janus.mock'
import {enableSamplingPointsEndpoints} from './mockServices/samplingPoints.mock'
import {enableLogging, sleepResponse} from './mockServices/utils'
import {mockStore} from './mockStore'

export const checkAndEnableMockIfRequired = () => {
  if (!mockStore.isEnabled()) {
    return
  }
  enableLogging(Api.axiosInstance)
  const mock = new MockAdapter(Api.axiosInstance, {onNoMatch: 'throwException'})

  mockStore.enableDfApiMockForAxios(mock)
  enableBurglengenfeldMock(mock)
}

const enableBurglengenfeldMock = (mock: MockAdapter) => {
  flow(
    enableMaterialsEndpoints,
    enableSchedulerEndpoints,
    enableAssetsEndpoints,
    enableMaterialStorageEndpoints,
    enableSalesForecastEndpoints,
    enableDSEndpoints
  )(mock)
  flow(enableMeasurementsEndpoints, enableSamplingPointsEndpoints, enableKpisEndpoints)(mock) // upper flow works for 5 functions max
  flow(enableUpmEndpoints)(mock) // upper flow works for 5 functions max
  flow(enableJanusEndpoints)(mock)

  // GET Latest (Py)
  mock.onGet('/hcem/ui/v2/latest/0014').reply(() => {
    const {burglengenfeld} = mockStore.scenario()
    return sleepResponse([200, burglengenfeld.latest])
  })

  mock.onGet('/plants').reply(() => {
    const mockResponse = [
      {
        plantId: TEST_PLANT_CODE,
        plantName: 'Burglengenfeld',
        plantNumber: TEST_PLANT_CODE,
        country: 'DE'
      }
    ] as unknown as PlantsResponse
    return sleepResponse([200, mockResponse])
  })
}
