import {toNumber} from 'lodash'

export const getNumberSeparators = (locale: string) => {
  const parts = new Intl.NumberFormat(locale).formatToParts(1234.5)
  return {
    decimalSeparator: parts.find((part) => part.type === 'decimal')?.value || '.',
    thousandsSeparator: parts.find((part) => part.type === 'group')?.value || ' '
  }
}

export const formatNumber = (
  value: number | string,
  locale: string,
  maximumFractionDigits?: number
): string => {
  const formatter = new Intl.NumberFormat(locale, {
    maximumFractionDigits: maximumFractionDigits ?? 2,
    useGrouping: true
  })
  return formatter.format(Number(value))
}

export const isNumber = (
  value?: string,
  thousandSeparator?: string,
  decimalSeparator?: string
): boolean => {
  return (
    value !== null &&
    value !== undefined &&
    value !== '' &&
    !isNaN(Number(normalizeStringValue(value, thousandSeparator, decimalSeparator)))
  )
}

export const normalizeStringValue = (
  value: string,
  thousandSeparator?: string,
  decimalSeparator?: string,
  convertToString = false
) => {
  if (typeof value === 'number') return convertToString ? String(value) : value

  if (value) {
    const normalizedStringValue = thousandSeparator
      ? value.replaceAll(thousandSeparator, '')
      : value
    return normalizedStringValue && normalizedStringValue.replace(decimalSeparator || '.', '.')
  }

  return value
}

export const formatMaskedValue = (
  value: string | null,
  isOriginalValue: boolean,
  language: string,
  thousandsSeparator: string,
  decimalSeparator: string
) => {
  if (value) {
    const resolvedValue = isOriginalValue
      ? toNumber(value)
      : toNumber(normalizeStringValue(value, thousandsSeparator, decimalSeparator))
    return formatNumber(resolvedValue, language, 8)
  }
  return value
}
