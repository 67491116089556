import {AssetLinkType} from '@hconnect/common/types'
import type {Asset, AssetLink, NewAttachedSilo} from '@hconnect/common/types'
import {dataTestId} from '@hconnect/uikit'
import {Card, CardTitle} from '@hconnect/uikit/src/lib2'
import {Add} from '@mui/icons-material'
import {Stack, Box, Button} from '@mui/material'
import {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {AttachedSiloForm} from './AttachedSiloForm'

import {useAttachedSilosQuery} from '@settings/modules/assets'
import {useMaterialStorageListQuery} from '@settings/modules/storages/hooks'

interface AttachedSilosCardProps {
  asset: Asset
  isReadOnly: boolean
}

export const AttachedSilosCard: React.FC<AttachedSilosCardProps> = ({asset, isReadOnly}) => {
  const {t} = useTranslation()

  const {data: attachedSilosLinks} = useAttachedSilosQuery(asset.id)
  const {data: materialStorage} = useMaterialStorageListQuery()

  const [newAttachedSilo, setNewAttachedSilo] = useState<NewAttachedSilo>()

  const availableSilos = materialStorage?.map(({id, name}) => ({id, name}))

  const availableSiloLinks = attachedSilosLinks ?? []
  const attachedSilos: (NewAttachedSilo | AssetLink)[] = [
    ...availableSiloLinks,
    ...(newAttachedSilo ? [newAttachedSilo] : [])
  ]

  return (
    <Card
      headerContent={
        <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <CardTitle sx={{mb: 0}}>
            {t('assetsSettings.attachedSilosTitle', {amount: attachedSilosLinks?.length ?? 0})}
          </CardTitle>
          <Button
            variant="text"
            startIcon={<Add />}
            disabled={isReadOnly || Boolean(newAttachedSilo)}
            onClick={() =>
              setNewAttachedSilo({
                to: {type: AssetLinkType.Asset, id: asset.id}
              })
            }
            {...dataTestId('add_silo')}
          >
            {t('assetsSettings.addSilo')}
          </Button>
        </Box>
      }
      {...dataTestId('attached_silos_card')}
    >
      <Stack spacing={3}>
        {availableSilos &&
          attachedSilos?.map((siloLink, index) => (
            <AttachedSiloForm
              key={siloLink.id ?? 'new'}
              index={index}
              assetId={asset.id}
              siloLink={siloLink}
              availableSilos={availableSilos}
              isReadOnly={isReadOnly}
              setNewSiloLink={setNewAttachedSilo}
            />
          ))}
      </Stack>
    </Card>
  )
}
