import {CementClassificationInputNames} from '@hconnect/common/enums'
import {MaterialType} from '@hconnect/common/types'
import {Grid, Tooltip} from '@mui/material'
import {HTMLAttributes, useCallback} from 'react'
import {useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {useMaterialsClassificationMetadata} from '../../../hooks/queries'
import {
  MaterialFormDefaultValues,
  getEmptyClassification
} from '../../materials-details/ExistingMaterialDetails/hooks'
import {MaterialsGrid} from '../components/MaterialsGrid'
import {MaterialsGridItem} from '../components/MaterialsGridItem'

import {
  ClassificationAutocomplete,
  ClassificationAutocompleteProps
} from './inputs/ClassificationAutocomplete'
import {ClassificationCheckBox} from './inputs/ClassificationCheckbox'

type CementClassificationStandardAutocompleteProps = Pick<
  ClassificationAutocompleteProps<MaterialType.Cement>,
  'isDisabled' | 'metadata' | 'onChangeCb'
>

function CementClassificationStandardAutocomplete({
  metadata,
  isDisabled,
  onChangeCb
}: CementClassificationStandardAutocompleteProps) {
  const {t} = useTranslation()

  const renderOption = useCallback(
    (props: HTMLAttributes<HTMLLIElement>, materialStandard: string) => {
      const tooltipName = `materialsSettings.classificationInputs.Cement.standardTooltips.${materialStandard}`
      return (
        <Tooltip placement="right" title={t(tooltipName)}>
          <li {...props} key={materialStandard}>
            {materialStandard}
          </li>
        </Tooltip>
      )
    },
    [t]
  )

  return (
    <ClassificationAutocomplete
      materialType={MaterialType.Cement}
      inputName={CementClassificationInputNames.Standard}
      metadata={metadata}
      isDisabled={isDisabled}
      renderOption={renderOption}
      onChangeCb={onChangeCb}
    />
  )
}

interface CementClassificationProps {
  isDisabled: boolean
}

export const CementClassification: React.FC<CementClassificationProps> = ({isDisabled}) => {
  const {data: cementClassificationMetadata} = useMaterialsClassificationMetadata(
    MaterialType.Cement
  )
  if (!cementClassificationMetadata) {
    throw new Error(
      'BUG: classification metadata should be loaded before rendering classification tab'
    )
  }

  const {setValue, getValues, clearErrors} = useFormContext<MaterialFormDefaultValues>()

  return (
    <MaterialsGrid>
      <Grid item xs={5} md={1.5}>
        <CementClassificationStandardAutocomplete
          metadata={cementClassificationMetadata}
          isDisabled={isDisabled}
          onChangeCb={() => {
            setValue('classification', {
              ...getEmptyClassification(MaterialType.Cement),
              [CementClassificationInputNames.Standard]: getValues(
                `classification.${CementClassificationInputNames.Standard}`
              )
            })
            clearErrors('classification')
          }}
        />
      </Grid>
      <Grid item xs={5} md={1}>
        <ClassificationAutocomplete
          materialType={MaterialType.Cement}
          inputName={CementClassificationInputNames.Type}
          metadata={cementClassificationMetadata}
          isDisabled={isDisabled}
          onChangeCb={() => {
            setValue('classification', {
              ...getValues('classification'),
              [CementClassificationInputNames.Const1]: null,
              [CementClassificationInputNames.Const2]: null
            })
            clearErrors([
              `classification.${CementClassificationInputNames.Const1}`,
              `classification.${CementClassificationInputNames.Const2}`
            ])
          }}
        />
      </Grid>
      <Grid item xs={5} md={0.8}>
        <ClassificationAutocomplete
          materialType={MaterialType.Cement}
          inputName={CementClassificationInputNames.Const1}
          metadata={cementClassificationMetadata}
          isDisabled={isDisabled}
        />
      </Grid>
      <Grid item xs={5} md={0.8}>
        <ClassificationAutocomplete
          materialType={MaterialType.Cement}
          inputName={CementClassificationInputNames.Const2}
          metadata={cementClassificationMetadata}
          isDisabled={isDisabled}
        />
      </Grid>
      <Grid item xs={5} md={0.8}>
        <ClassificationAutocomplete
          materialType={MaterialType.Cement}
          inputName={CementClassificationInputNames.Strength1}
          metadata={cementClassificationMetadata}
          isDisabled={isDisabled}
        />
      </Grid>
      <Grid item xs={5} md={0.8}>
        <ClassificationAutocomplete
          materialType={MaterialType.Cement}
          inputName={CementClassificationInputNames.Strength2}
          metadata={cementClassificationMetadata}
          isDisabled={isDisabled}
        />
      </Grid>
      <Grid item xs={5} md={1}>
        <ClassificationAutocomplete
          materialType={MaterialType.Cement}
          inputName={CementClassificationInputNames.SulphateResistance}
          metadata={cementClassificationMetadata}
          isDisabled={isDisabled}
        />
      </Grid>
      <Grid item xs={5} md={0.8}>
        <ClassificationAutocomplete
          materialType={MaterialType.Cement}
          inputName={CementClassificationInputNames.SulphurLevel}
          metadata={cementClassificationMetadata}
          isDisabled={isDisabled}
        />
      </Grid>
      <Grid item xs={5} md={1}>
        <ClassificationAutocomplete
          materialType={MaterialType.Cement}
          inputName={CementClassificationInputNames.HeatOfHydration}
          metadata={cementClassificationMetadata}
          isDisabled={isDisabled}
        />
      </Grid>
      <Grid item xs={5} md={0.8}>
        <ClassificationAutocomplete
          materialType={MaterialType.Cement}
          inputName={CementClassificationInputNames.LowAlkali}
          metadata={cementClassificationMetadata}
          isDisabled={isDisabled}
        />
      </Grid>
      <Grid item xs={5} md={1.5}>
        <ClassificationAutocomplete
          materialType={MaterialType.Cement}
          inputName={CementClassificationInputNames.SpecialCharacteristics}
          metadata={cementClassificationMetadata}
          isDisabled={isDisabled}
        />
      </Grid>
      <Grid item xs={5} md={5}>
        <ClassificationCheckBox
          materialType={MaterialType.Cement}
          inputName={CementClassificationInputNames.IntermediateMillProduct}
          metadata={cementClassificationMetadata}
          customValues={{trueValue: 'Yes', falseValue: 'No'}}
          isDisabled={isDisabled}
        />
      </Grid>
      <MaterialsGridItem>
        <ClassificationAutocomplete
          materialType={MaterialType.Cement}
          inputName={CementClassificationInputNames.CategoryForAnnualReport}
          metadata={cementClassificationMetadata}
          isDisabled={isDisabled}
        />
      </MaterialsGridItem>
      <MaterialsGridItem>
        <ClassificationAutocomplete
          materialType={MaterialType.Cement}
          inputName={CementClassificationInputNames.MethodOfFinenessDetermination}
          metadata={cementClassificationMetadata}
          isDisabled={isDisabled}
        />
      </MaterialsGridItem>
      <MaterialsGridItem>
        <ClassificationAutocomplete
          materialType={MaterialType.Cement}
          inputName={CementClassificationInputNames.ReferenceMeshSizeForResidue}
          metadata={cementClassificationMetadata}
          isDisabled={isDisabled}
        />
      </MaterialsGridItem>
    </MaterialsGrid>
  )
}
