import type {Asset} from '@hconnect/common/types'
import {AssetEditableFields, AssetType} from '@hconnect/common/types'
import {dataTestId} from '@hconnect/uikit'
import {NumericTextField} from '@hconnect/uikit/src/lib2'
import {InfoOutlined} from '@mui/icons-material'
import {TextField, Stack, Box, Tooltip} from '@mui/material'
import {useForm, Controller} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {OptimizerConstraintsInfo} from '../../common/OptimizerConstraintsInfo'

import {useEditAsset, useGetAssetConstraint} from '@settings/modules/assets'
import {ControlledAutocomplete, NumberInput, Switch} from '@settings/modules/common/components'
import {CopyableTextField} from '@settings/modules/common/components/form/CopyableTextField'
import {
  submitOnBlurAndEnterProps,
  requiredValidator,
  minValidator,
  rangeValidator
} from '@settings/modules/common/utils'
import {useUrlParam} from '@settings/modules/routing'

interface EnabledForPlanningAssetProps {
  asset: Asset
  isReadOnly: boolean
}

type AssetFormInitialValues = Pick<Asset, AssetEditableFields>

export const EnabledForPlanningAsset: React.FC<EnabledForPlanningAssetProps> = ({
  asset,
  isReadOnly
}) => {
  const {t} = useTranslation()
  const plantCode = useUrlParam('plantCode')

  const assetConstraint = useGetAssetConstraint(asset.id)

  const {mutate: editAsset, isLoading: isAssetEditing} = useEditAsset()

  const {
    name,
    type,
    startupCost,
    minimumDowntime,
    isOptimized,
    isShutdownAvailable,
    startCoefficient,
    stopCoefficient
  } = asset

  const initialValues: AssetFormInitialValues = {
    name,
    type,
    startupCost,
    minimumDowntime,
    isOptimized,
    isShutdownAvailable,
    startCoefficient,
    stopCoefficient
  }

  const {
    handleSubmit,
    control,
    reset,
    formState: {dirtyFields}
  } = useForm<AssetFormInitialValues>({
    mode: 'onChange',
    shouldFocusError: false,
    defaultValues: initialValues
  })

  const submit = handleSubmit((params) => {
    const keys = Object.keys(dirtyFields).map((key) => key as AssetEditableFields)
    keys.forEach((key) => {
      editAsset(
        {plantCode, assetId: asset.id, key, dto: {[key]: params[key]}},
        {
          onError: () => reset(),
          onSuccess: () => reset(params)
        }
      )
    })
  })

  return (
    <Stack spacing={2} {...dataTestId('add_edit_asset_form')}>
      <Stack direction="row" spacing={2}>
        <Controller
          control={control}
          name="name"
          rules={requiredValidator(t)}
          render={({field: {ref, value, onChange}, fieldState: {error}}) => (
            <TextField
              label={t('assetsSettings.assetName')}
              sx={{width: ({spacing}) => spacing(50)}}
              value={value}
              placeholder={value}
              inputRef={ref}
              onChange={onChange}
              helperText={error?.message}
              error={Boolean(error?.message)}
              disabled={isReadOnly || isAssetEditing}
              {...submitOnBlurAndEnterProps(submit)}
              {...dataTestId('asset_name_input')}
            />
          )}
        />
        <CopyableTextField label={t('assetsSettings.assetId')} value={asset.id.toString()} />
      </Stack>
      <Box width={({spacing}) => spacing(50)}>
        <ControlledAutocomplete
          control={control}
          formDataName="type"
          options={Object.values(AssetType)}
          translateOption={(option) => t(`assetsSettings.assetTypes.${option}`)}
          label={t('assetsSettings.assetType')}
          rules={requiredValidator(t)}
          disabled // asset type must be read only
          {...dataTestId('asset_type_select')}
        />
      </Box>
      {
        <Controller
          control={control}
          name="minimumDowntime"
          rules={requiredValidator(t)}
          render={({field: {ref, value, onChange}, fieldState: {error}}) => (
            <NumericTextField
              label={t('assetsSettings.minimumDowntime')}
              sx={{width: ({spacing}) => spacing(50)}}
              value={value}
              inputRef={ref}
              onChange={onChange}
              disabled={isReadOnly || isAssetEditing}
              helperText={error?.message}
              error={Boolean(error?.message)}
              inputProps={{step: '1', min: '0', max: '1000'}}
              {...submitOnBlurAndEnterProps(submit)}
              {...dataTestId('asset_minimum_downtime')}
            />
          )}
        />
      }
      <Controller
        control={control}
        name="startupCost"
        rules={{...requiredValidator(t), ...minValidator(t, 0)}}
        render={({field: {ref, value, onChange}, fieldState: {error}}) => (
          <NumberInput
            inputRef={ref}
            sx={{width: ({spacing}) => spacing(50)}}
            label={t('assetsSettings.startUpCosts')}
            onChange={onChange}
            value={value}
            error={error?.message}
            disabled={isReadOnly || isAssetEditing}
            {...submitOnBlurAndEnterProps(submit)}
            dataTestId={'startup_cost'}
          />
        )}
      />

      <Stack direction="row" spacing={2}>
        <Controller
          control={control}
          name="startCoefficient"
          rules={{...requiredValidator(t), ...rangeValidator(t, [0.01, 10])}}
          render={({field: {ref, value, onChange}, fieldState: {error}}) => (
            <NumberInput
              inputRef={ref}
              sx={{width: ({spacing}) => spacing(24)}}
              label={t('assetsSettings.energyCoefficientStart')}
              onChange={onChange}
              value={value}
              error={error?.message}
              disabled={isReadOnly || isAssetEditing}
              {...submitOnBlurAndEnterProps(submit)}
              dataTestId={'energy_coefficient_start'}
            />
          )}
        />
        <Controller
          control={control}
          name="stopCoefficient"
          rules={{...requiredValidator(t), ...rangeValidator(t, [0.01, 10])}}
          render={({field: {ref, value, onChange}, fieldState: {error}}) => (
            <NumberInput
              inputRef={ref}
              sx={{width: ({spacing}) => spacing(24)}}
              label={t('assetsSettings.energyCoefficientStop')}
              onChange={onChange}
              value={value}
              error={error?.message}
              disabled={isReadOnly || isAssetEditing}
              {...submitOnBlurAndEnterProps(submit)}
              dataTestId={'energy_coefficient_stop'}
            />
          )}
        />
        <Tooltip title={t('assetsSettings.energyCoefficientTooltip')}>
          <Box sx={{display: 'flex', alignItems: 'center'}}>
            <InfoOutlined sx={{fontSize: 'inherit'}} />
          </Box>
        </Tooltip>
      </Stack>
      <Switch
        value={asset.isOptimized}
        label={
          <Stack direction="row" alignItems="center">
            {t('assetsSettings.optimized')}
            {assetConstraint && (
              <OptimizerConstraintsInfo
                {...dataTestId('asset_constraint_info')}
                constraintsMarkdown={assetConstraint}
                ml={2}
              />
            )}
          </Stack>
        }
        disabled // isOptimized on asset level must be read only
        {...dataTestId('is_optimized_asset_switch')}
      />
      <Controller
        name="isShutdownAvailable"
        control={control}
        render={({field: {value, onChange}}) => (
          <Switch
            value={value}
            onChange={onChange}
            disabled={isReadOnly || isAssetEditing}
            label={t('assetsSettings.shutdownAvailability')}
            {...submitOnBlurAndEnterProps(submit)}
            {...dataTestId('is_shutdown_available_switch')}
          />
        )}
      />
    </Stack>
  )
}
