import type {Asset} from '@hconnect/common/types'
import {AssetEditableFields} from '@hconnect/common/types'
import {dataTestId} from '@hconnect/uikit'
import {NumericTextField} from '@hconnect/uikit/src/lib2'
import {InfoOutlined} from '@mui/icons-material'
import {TextField, Stack, Box, Tooltip} from '@mui/material'
import {FC, useEffect} from 'react'
import {useForm, Controller} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {NumberInput, Switch} from '../../../common/components'
import {
  requiredValidator,
  minValidator,
  rangeValidator,
  submitOnBlurAndEnterProps
} from '../../../common/utils'
import {useUrlParam} from '../../../routing'
import {useEditAsset} from '../../hooks'
import {useGetAssetConstraint} from '../../hooks/queries/useGetAssetConstraint'

import {OptimizerConstraintsInfo} from './OptimizerConstraintsInfo'

import {CopyableTextField} from '@settings/modules/common/components/form/CopyableTextField'

interface EditAssetFormProps {
  asset: Asset
  isReadOnly: boolean
}
export const EditAssetForm: FC<EditAssetFormProps> = ({asset, isReadOnly}) => {
  const plantCode = useUrlParam('plantCode')
  const {mutate: editAsset} = useEditAsset()

  const {t} = useTranslation()

  const initialValues: Pick<Asset, AssetEditableFields> = {
    name: asset.name,
    type: asset.type,
    startupCost: asset.startupCost,
    minimumDowntime: asset.minimumDowntime,
    isOptimized: asset.isOptimized,
    isShutdownAvailable: asset.isShutdownAvailable,
    startCoefficient: asset.startCoefficient,
    stopCoefficient: asset.stopCoefficient
  }

  const {
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    formState: {dirtyFields}
  } = useForm<Pick<Asset, AssetEditableFields>>({
    mode: 'onChange',
    shouldFocusError: false,
    defaultValues: initialValues
  })

  const currentAssetType = watch('type')

  const submit = handleSubmit((params) => {
    const keys = Object.keys(dirtyFields).map((key) => key as AssetEditableFields)
    keys.forEach((key) => {
      editAsset(
        {plantCode, assetId: asset.id, key, dto: {[key]: params[key]}},
        {
          onError: () => reset(),
          onSuccess: () => reset(params)
        }
      )
    })
  })

  // since we are changing asset type outside of form, we should update it manually
  useEffect(() => {
    if (asset.type !== currentAssetType) {
      setValue('type', asset.type)
    }
  }, [asset.type, currentAssetType, setValue])

  const assetConstraint = useGetAssetConstraint(asset.id)

  return (
    <Stack spacing={2} {...dataTestId('add_edit_asset_form')}>
      <Stack direction="row" spacing={2}>
        <Controller
          control={control}
          name="name"
          rules={requiredValidator(t)}
          render={({field: {ref, value, onChange}, fieldState: {error}}) => (
            <TextField
              label={t('assetsSettings.assetName')}
              sx={{width: ({spacing}) => spacing(50)}}
              value={value}
              placeholder={value}
              inputRef={ref}
              onChange={onChange}
              helperText={error?.message}
              error={Boolean(error?.message)}
              disabled={isReadOnly}
              {...submitOnBlurAndEnterProps(submit)}
              {...dataTestId('asset_name_input')}
            />
          )}
        />
        <CopyableTextField label={t('assetsSettings.assetId')} value={asset.id.toString()} />
      </Stack>
      {/* Minimum downtime */}
      {
        <Controller
          control={control}
          name="minimumDowntime"
          rules={requiredValidator(t)}
          render={({field: {ref, value, onChange}, fieldState: {error}}) => (
            <NumericTextField
              label={t('assetsSettings.minimumDowntime')}
              sx={{width: ({spacing}) => spacing(50)}}
              value={value}
              inputRef={ref}
              onChange={onChange}
              disabled={isReadOnly}
              helperText={error?.message}
              error={Boolean(error?.message)}
              inputProps={{step: '1', min: '0', max: '1000'}}
              {...submitOnBlurAndEnterProps(submit)}
              {...dataTestId('asset_minimum_downtime')}
            />
          )}
        />
      }
      <Controller
        control={control}
        name="startupCost"
        rules={{...requiredValidator(t), ...minValidator(t, 0)}}
        render={({field: {ref, value, onChange}, fieldState: {error}}) => (
          <NumberInput
            inputRef={ref}
            sx={{width: ({spacing}) => spacing(50)}}
            label={t('assetsSettings.startUpCosts')}
            onChange={onChange}
            value={value}
            error={error?.message}
            disabled={isReadOnly}
            {...submitOnBlurAndEnterProps(submit)}
            dataTestId={'startup_cost'}
          />
        )}
      />

      <Stack direction="row" spacing={2}>
        <Controller
          control={control}
          name="startCoefficient"
          rules={{...requiredValidator(t), ...rangeValidator(t, [0.01, 10])}}
          render={({field: {ref, value, onChange}, fieldState: {error}}) => (
            <NumberInput
              inputRef={ref}
              sx={{width: ({spacing}) => spacing(24)}}
              label={t('assetsSettings.energyCoefficientStart')}
              onChange={onChange}
              value={value}
              error={error?.message}
              disabled={isReadOnly}
              {...submitOnBlurAndEnterProps(submit)}
              dataTestId={'energy_coefficient_start'}
            />
          )}
        />
        <Controller
          control={control}
          name="stopCoefficient"
          rules={{...requiredValidator(t), ...rangeValidator(t, [0.01, 10])}}
          render={({field: {ref, value, onChange}, fieldState: {error}}) => (
            <NumberInput
              inputRef={ref}
              sx={{width: ({spacing}) => spacing(24)}}
              label={t('assetsSettings.energyCoefficientStop')}
              onChange={onChange}
              value={value}
              error={error?.message}
              disabled={isReadOnly}
              {...submitOnBlurAndEnterProps(submit)}
              dataTestId={'energy_coefficient_stop'}
            />
          )}
        />
        <Tooltip title={t('assetsSettings.energyCoefficientTooltip')}>
          <Box sx={{display: 'flex', alignItems: 'center'}}>
            <InfoOutlined sx={{fontSize: 'inherit'}} />
          </Box>
        </Tooltip>
      </Stack>
      {/* isOptimized on asset level needed read only*/}
      <Switch
        value={asset.isOptimized}
        label={
          <Stack direction="row" alignItems="center">
            {t('assetsSettings.optimized')}
            {assetConstraint && (
              <OptimizerConstraintsInfo
                {...dataTestId('asset_constraint_info')}
                constraintsMarkdown={assetConstraint}
                ml={2}
              />
            )}
          </Stack>
        }
        disabled
        {...dataTestId('is_optimized_asset_switch')}
      />
      <Controller
        name="isShutdownAvailable"
        control={control}
        render={({field: {value, onChange}}) => (
          <Switch
            value={value}
            onChange={onChange}
            disabled={isReadOnly}
            label={t('assetsSettings.shutdownAvailability')}
            {...submitOnBlurAndEnterProps(submit)}
            {...dataTestId('is_shutdown_available_switch')}
          />
        )}
      />
    </Stack>
  )
}
