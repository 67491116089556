import {Feature} from '@hconnect/common/enums'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {Navigate} from 'react-router-dom'

import {PermissionParsed, usePermissionsQuery} from '../../permissions'
import {checkPermission} from '../../permissions/helpers/checkPermission'
import {getUrl, mainRoutes, PLANT_ROUTES, ROUTES} from '../../routing'
import {useUrlParam} from '../../routing/hooks'
import {PageSkeleton} from '../components/skeletons'
import {PlantData, useLatestQuery, usePlantDataFromJson, useSettingsFeature} from '../hooks'
import {useLoginDetails} from '../providers/LoginProvider'
import {Latest} from '../types/latest'
import {logger} from '../utils/logger'

const log = logger.context('DefaultPageRedirect')

export const getRouteToRedirect = (
  isUpmAssetsAndStoragesEnabled: boolean,
  plantCode: string,
  plantData: PlantData,
  permissions: PermissionParsed[],
  latest: Latest
) => {
  const filteredMainRoutes = isUpmAssetsAndStoragesEnabled
    ? mainRoutes.filter(
        (route) =>
          route.path !== PLANT_ROUTES.STORAGES.path && route.path !== PLANT_ROUTES.ASSETS.path
      )
    : mainRoutes

  return filteredMainRoutes.find((route) => {
    const {permissionType, featureType} = route

    const hasPermission = checkPermission(permissionType, plantCode, permissions, [
      plantData.countryId
    ])

    const hasFeature = !featureType || latest.available_features.includes(featureType as Feature)

    return hasPermission && hasFeature
  })
}

export const DefaultPageRedirect = () => {
  const {t} = useTranslation()
  const plantCode = useUrlParam('plantCode')
  const {data: permissions} = usePermissionsQuery()
  const {data: latest} = useLatestQuery()
  const plantData = usePlantDataFromJson()
  const {loginDetails} = useLoginDetails()
  const isUpmAssetsAndStoragesEnabled = useSettingsFeature(Feature.UpmAssetsAndStorages)

  if (!loginDetails || !permissions || !latest) {
    return <PageSkeleton />
  }

  if (!plantData) {
    const errorMessage = t('error.pageNotFoundTitle')
    log.extras({plantCode}).critical(errorMessage)
    return <Navigate to={getUrl(ROUTES.BASE.NOT_FOUND.path, {})} replace />
  }

  const routeToRedirect = getRouteToRedirect(
    isUpmAssetsAndStoragesEnabled,
    plantCode,
    plantData,
    permissions,
    latest
  )

  if (!routeToRedirect) {
    const errorMessage = t('error.accessDenied')
    log.extras({plantCode}).critical(errorMessage)
    return <Navigate to={getUrl(ROUTES.BASE.NOT_AUTHORIZED.path, {})} replace />
  }

  return <Navigate to={getUrl(routeToRedirect.path, {plantCode})} replace />
}
