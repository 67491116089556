import {dataTestId} from '@hconnect/uikit'
import {CardBox} from '@hconnect/uikit/src/lib2'
import {Box, Grid, Stack} from '@mui/material'
import {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {useAssetsQuery} from '../assets'
import {PlantStructureFilterOptions} from '../common/enums'
import {useLatestQuery} from '../common/hooks'
import {useMaterialStorageListQuery} from '../storages/hooks'

import {ItemDetails} from './ItemDetails'
import {PlantStructureTree} from './plant-setup-tree/PlantStructureTree'
import {ItemDetailsProvider, SelectedItemProvider, ItemLinkProvider} from './providers'

import {PageLayout, SettingsHelmet, SettingsSkeleton} from '@settings/modules/common/components'
import {QuickFilterOptionButton} from '@settings/modules/common/components/settingsTree'
import {RouteName} from '@settings/modules/routing'

export const PlantStructure = () => {
  const {t} = useTranslation()

  const {data: latestData} = useLatestQuery()
  const {data: assets} = useAssetsQuery()
  const {data: materialStorages} = useMaterialStorageListQuery()

  const [selectedFilterOption, setSelectedFilterOption] = useState<PlantStructureFilterOptions>(
    PlantStructureFilterOptions.All
  )

  if (!latestData || !assets || !materialStorages) {
    return <SettingsSkeleton />
  }

  return (
    <SelectedItemProvider>
      <ItemLinkProvider>
        <PageLayout
          title={t('upmSettings.plantSetup')}
          {...dataTestId(`page_${RouteName.PLANT_SETUP}`)}
        >
          <SettingsHelmet title={t('janusConfig.plantSetup.pageTitle')} />
          <Grid container spacing={2} {...dataTestId('plant-structure-container')}>
            <Grid item xs={12} sm={4}>
              <CardBox px={0}>
                <Stack
                  direction="column"
                  justifyContent="space-between"
                  {...dataTestId('janus-plant-structure-menu')}
                  alignItems={'left'}
                  gap={2}
                >
                  <Box
                    gap={1}
                    px={2}
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      flexDirection: {xs: 'column', sm: 'row'}
                    }}
                  >
                    {Object.values(PlantStructureFilterOptions).map((option) => (
                      <QuickFilterOptionButton
                        data-selected={option === selectedFilterOption}
                        onClick={() => setSelectedFilterOption(option)}
                        key={option}
                        variant="outlined"
                        {...dataTestId(`plant-structure-type-filter-${option}`)}
                      >
                        {t(`janusConfig.label.${option}`)}
                      </QuickFilterOptionButton>
                    ))}
                  </Box>
                  <PlantStructureTree selectedFilterOption={selectedFilterOption} />
                </Stack>
              </CardBox>
            </Grid>
            <Grid item xs={12} sm={8}>
              <ItemDetailsProvider>
                <ItemDetails />
              </ItemDetailsProvider>
            </Grid>
          </Grid>
        </PageLayout>
      </ItemLinkProvider>
    </SelectedItemProvider>
  )
}
