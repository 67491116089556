import {EmailSyncDetails, MaterialStorage} from '@hconnect/common/types'
import {dataTestId} from '@hconnect/uikit'
import {Card, CardTitle} from '@hconnect/uikit/src/lib2'
import {Add} from '@mui/icons-material'
import {Stack, Button} from '@mui/material'
import {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {EmailParserConnectionForm} from './EmailParserConnectionForm'

interface EmailParserCardProps {
  storage: MaterialStorage
  isReadOnly: boolean
}

export const EmailParserCard: React.FC<EmailParserCardProps> = ({storage, isReadOnly}) => {
  const {t} = useTranslation()
  const [emailParserConnection, setEmailParserConnection] = useState<EmailSyncDetails | null>(
    storage.emailSyncDetails
  )
  const isAddConnectionButtonDisabled = isReadOnly || !!emailParserConnection

  return (
    <Card
      headerContent={
        <Stack direction="row" justifyContent="space-between">
          <CardTitle>{t('storagesSettings.emailParserConnection')}</CardTitle>
          <Button
            variant="text"
            startIcon={<Add />}
            disabled={isAddConnectionButtonDisabled}
            onClick={() =>
              setEmailParserConnection({emailColumnName: '', tonneConversionFactor: 1})
            }
            {...dataTestId('add_email_parser_connection')}
          >
            {t('storagesSettings.addConnection')}
          </Button>
        </Stack>
      }
      {...dataTestId('email_parser_card')}
    >
      {emailParserConnection && (
        <EmailParserConnectionForm
          key={storage.id}
          storage={storage}
          isReadOnly={isReadOnly}
          emailParserConnection={emailParserConnection}
          setEmailParserConnection={setEmailParserConnection}
        />
      )}
    </Card>
  )
}
