import {Status, Recipe} from '@hconnect/common/types'
import type {
  Asset,
  OperationMode,
  OperationModePayload,
  ProductionOperationMode
} from '@hconnect/common/types'
import {dataTestId} from '@hconnect/uikit'
import {NumericTextField, NumberLetterSequenceIndicator} from '@hconnect/uikit/src/lib2'
import {DeleteOutlined} from '@mui/icons-material'
import {MenuItem, Stack, TextField} from '@mui/material'
import React from 'react'
import {useForm, Controller} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {DeleteButton, Switch} from '../../../common/components'
import {useScheduleQuery} from '../../../common/hooks'
import {useConfirmDialog} from '../../../common/providers'
import type {Schedule} from '../../../common/types'
import {requiredValidator, minValidator, submitOnBlurAndEnterProps} from '../../../common/utils'
import {useUrlParam} from '../../../routing'
import {OptimizerConstraintsInfo} from '../common/OptimizerConstraintsInfo'

import {OperationModeDeleteInfo} from './OperationModeDeleteInfo'

import {
  useAddOperationMode,
  useDeleteOperationMode,
  useEditOperationMode,
  useGetOperationModeConstraint
} from '@settings/modules/assets'

export type NewOperationMode = OperationModePayload & {status: Status.New}

type OperationModeFormState = NewOperationMode | ProductionOperationMode

interface OperationModeFormProps {
  index: number
  asset: Asset
  isReadOnly: boolean
  recipes: Recipe[]
  operationMode: OperationModeFormState
  setNewOperationMode: (operationMode?: NewOperationMode) => void
}

export const OperationModeForm: React.FC<OperationModeFormProps> = ({
  index,
  asset,
  isReadOnly,
  recipes,
  operationMode,
  setNewOperationMode
}) => {
  const {t} = useTranslation()
  const plantCode = useUrlParam('plantCode')
  const {openDialog} = useConfirmDialog()

  const {data: schedule} = useScheduleQuery()

  const {mutate: addOperationMode, isLoading: isAddingOperationMode} = useAddOperationMode()
  const {mutate: editOperationMode, isLoading: isEditingOperationMode} = useEditOperationMode()
  const {mutate: deleteOperationMode, isLoading: isDeletingOperationMode} = useDeleteOperationMode()

  const {id: assetId} = asset
  const recipesIdsWithNames = recipes.map(({id, name}) => ({id, name}))

  const isMutating = isAddingOperationMode || isEditingOperationMode || isDeletingOperationMode
  const isNewOperationMode = operationMode.status === Status.New

  const operationModeConstraint = useGetOperationModeConstraint(
    operationMode.status === Status.New ? undefined : operationMode.id
  )

  const {
    handleSubmit,
    control,
    reset,
    formState: {isDirty, dirtyFields},
    watch,
    setValue,
    clearErrors,
    trigger
  } = useForm<OperationModeFormState>({
    mode: 'onChange',
    shouldFocusError: false,
    defaultValues: operationMode
  })

  const currentRecipeId = watch('recipeId')

  const onSubmit = handleSubmit((operationMode) => {
    if (!isDirty) return

    const {recipeId, name, type, throughput, powerConsumption, minimumRuntime, isOptimized} =
      operationMode

    if (operationMode.status === Status.New) {
      addOperationMode(
        {
          plantCode,
          assetId,
          recipeId,
          name,
          type,
          throughput,
          powerConsumption,
          minimumRuntime
        },
        {
          onError: () => reset(),
          onSuccess: () => {
            setNewOperationMode(undefined)
          }
        }
      )
      return
    }

    const {id: operationModeId} = operationMode
    const keys = Object.keys(dirtyFields).map((key) => key as keyof OperationModePayload)

    for (const key of keys) {
      editOperationMode(
        {
          plantCode,
          assetId,
          operationModeId,
          key,
          dto: {
            recipeId,
            isOptimized,
            name,
            type,
            throughput,
            powerConsumption,
            minimumRuntime
          }
        },
        {
          onError: () => reset(),
          onSuccess: (operationMode) => reset(operationMode)
        }
      )
    }
  })

  const handleDeleteOperationMode = (operationMode: OperationMode) => {
    deleteOperationMode({
      plantCode,
      assetId,
      operationModeId: operationMode.id
    })
  }

  const onDelete = (schedule: Schedule) => {
    if (operationMode.status === Status.New) {
      setNewOperationMode(undefined)
      return
    }
    const scheduleItems = Object.values(schedule.schedules).filter(
      (item) => operationMode.id === item.assetOperationModeId
    )
    openDialog({
      title: t('assetsSettings.deleteOperationMode'),
      testId: 'delete_operation_mode_confirm_dialog',
      mainAction: {
        text: t('common.delete'),
        color: 'error',
        icon: <DeleteOutlined />,
        onAction: () => handleDeleteOperationMode(operationMode)
      },
      additionalContent: (
        <OperationModeDeleteInfo operationMode={operationMode} scheduleItems={scheduleItems} />
      )
    })
  }

  return (
    <Stack spacing={2} {...dataTestId('operation_mode_form')}>
      <Stack direction={{xs: 'column', md: 'row'}} spacing={2} sx={{width: {xl: 0.8, lg: 1}}}>
        {/* 1st row */}
        <Stack alignItems="center" direction="row" spacing={2} sx={{width: 1}}>
          <NumberLetterSequenceIndicator numberIndex={index} />
          <Controller
            control={control}
            name="recipeId"
            render={({field: {ref, value, onChange}, fieldState: {error}}) => (
              <TextField
                sx={{width: 1}}
                label={t('materialsSettings.recipe')}
                select
                inputRef={ref}
                onChange={(e) => {
                  if (e.target.value === '') {
                    onChange(null)
                    setValue('throughput', null)
                    clearErrors('throughput')
                    return
                  }
                  onChange(e.target.value)
                  void trigger()
                }}
                value={value ?? ''}
                error={!!error}
                helperText={error?.message}
                disabled={isReadOnly}
                {...submitOnBlurAndEnterProps(onSubmit)}
                {...dataTestId('recipe_id_select')}
              >
                <MenuItem key="no_recipe" value="" {...dataTestId('recipe_id_select_item')}>
                  {t('assetsSettings.noRecipe')}
                </MenuItem>
                {recipesIdsWithNames.map((recipe) => (
                  <MenuItem
                    key={recipe.id}
                    value={recipe.id}
                    {...dataTestId('recipe_id_select_item')}
                  >
                    {recipe.name}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Stack>
        {/* 2nd row */}
        <Stack alignItems="center" direction="row" spacing={2} sx={{width: 1, pl: {xs: 4, md: 0}}}>
          <Controller
            control={control}
            name="name"
            rules={requiredValidator(t)}
            render={({field: {ref, value, onChange}, fieldState: {error}}) => (
              <TextField
                sx={{width: 1}}
                label={t('common.name')}
                inputRef={ref}
                value={value}
                onChange={onChange}
                disabled={isReadOnly}
                error={!!error}
                helperText={error?.message}
                {...submitOnBlurAndEnterProps(onSubmit)}
                {...dataTestId('name_input')}
              />
            )}
          />
          {schedule && (
            <DeleteButton
              disabled={isReadOnly || isMutating}
              onClick={() => onDelete(schedule)}
              {...dataTestId('delete_operation_mode_button')}
            />
          )}
        </Stack>
      </Stack>
      <Stack
        direction={{xs: 'column', md: 'row'}}
        sx={{width: {xl: 0.6, lg: 0.9}, pl: 4}}
        spacing={2}
      >
        {/* 3rd row */}
        <Stack alignItems="center" direction="row" spacing={2} sx={{width: 1}}>
          <Controller
            control={control}
            name="minimumRuntime"
            rules={{...requiredValidator(t), ...minValidator(t, 0)}}
            render={({field: {ref, value, onChange}, fieldState: {error}}) => (
              <NumericTextField
                sx={{width: 1}}
                label={t('assetsSettings.minRuntime')}
                inputRef={ref}
                onChange={({target: {value}}) => onChange(value === '' ? '' : parseInt(value, 10))}
                value={value}
                disabled={isReadOnly}
                error={!!error}
                helperText={error?.message}
                {...submitOnBlurAndEnterProps(onSubmit)}
                {...dataTestId('minimal_runtime_input')}
              />
            )}
          />
          <Controller
            control={control}
            name="powerConsumption"
            rules={requiredValidator(t)}
            render={({field: {ref, value, onChange}, fieldState: {error}}) => (
              <NumericTextField
                sx={{width: 1}}
                label={t('assetsSettings.powerConsumption')}
                inputRef={ref}
                inputProps={{step: 0.1}}
                onChange={({target: {value}}) => onChange(value === '' ? '' : Number(value))}
                value={value}
                disabled={isReadOnly}
                error={!!error}
                helperText={error?.message}
                {...submitOnBlurAndEnterProps(onSubmit)}
                {...dataTestId('power_consumption_input')}
              />
            )}
          />
        </Stack>
        {/* 4th row */}
        <Stack alignItems="center" direction="row" spacing={2} sx={{width: 1}}>
          <Controller
            control={control}
            name="throughput"
            rules={{
              validate: (value) => {
                if (currentRecipeId === null || value !== null) {
                  return true
                }
                // required should be only when creating a new operation mode
                if (isNewOperationMode) {
                  return t('error.required')
                }
              }
            }}
            render={({field: {ref, value, onChange}, fieldState: {error}}) => (
              <NumericTextField
                sx={{width: 1}}
                label={t('assetsSettings.throughput')}
                inputRef={ref}
                onChange={({target: {value}}) => onChange(value === '' ? null : Number(value))}
                value={value === null ? '' : value}
                disabled={isReadOnly || currentRecipeId === null}
                error={!!error}
                helperText={error?.message}
                {...submitOnBlurAndEnterProps(onSubmit)}
                {...dataTestId('throughput_input')}
              />
            )}
          />
          <Controller
            control={control}
            name="isOptimized"
            render={({field: {ref, value, onChange}}) => (
              <Switch
                label={
                  <Stack direction="row">
                    {t('assetsSettings.enableOptimizer')}
                    {operationModeConstraint && (
                      <OptimizerConstraintsInfo
                        {...dataTestId('constraint_info')}
                        constraintsMarkdown={operationModeConstraint}
                        ml={2}
                      />
                    )}
                  </Stack>
                }
                inputRef={ref}
                value={value}
                onChange={onChange}
                disabled={isReadOnly || isNewOperationMode}
                sx={{width: 1}}
                {...submitOnBlurAndEnterProps(onSubmit)}
                {...dataTestId('is_optimized_switch')}
              />
            )}
          />
        </Stack>
      </Stack>
    </Stack>
  )
}
