import {Asset, MaterialStorage} from '@hconnect/common'

import {TreeNode, UpmEntityType} from '../../types'

import {
  getDepartmentOrEquipmentType,
  getEnabledForPlanningItem,
  isDepartmentOrEquipmentNode
} from '@settings/modules/plant-setup/helpers'

export const getNodeLabelCode = (node: TreeNode): string => {
  switch (node.itemType) {
    case UpmEntityType.Department:
      return node.sourceSystemCode ?? node.type?.code ?? ''
    default:
      return node.type?.code ?? ''
  }
}

export const getNodeLabel = (node: TreeNode, codesPath: string): string => {
  switch (node.itemType) {
    case UpmEntityType.Plant:
      return `${node.plantId}-${node.plantName}`
    case UpmEntityType.Area:
      return `${node.type?.code}_${node.type?.name}`
    case UpmEntityType.Department:
      return `${codesPath}_${node.displayValue ?? node.type?.name}`
    case UpmEntityType.Equipment:
      return node.text ?? ''
    default:
      return ''
  }
}

export const getEnrichedPlantStructure = <T extends TreeNode>(
  node: T,
  assets: Asset[],
  materialStorages: MaterialStorage[],
  parentPath: string = ''
): T => {
  // Calculating the node path
  const nodeCode = node.type?.code
  const nodePath = parentPath + (nodeCode ? (parentPath ? '_' : '') + nodeCode : '')

  // Calculating the node label
  const nodeLabelCode = getNodeLabelCode(node)
  const nodeLabelPath = parentPath + (nodeLabelCode ? (parentPath ? '_' : '') + nodeLabelCode : '')
  const nodeLabel = getNodeLabel(node, nodeLabelPath)

  const children =
    node.children.map((child: TreeNode) =>
      getEnrichedPlantStructure(child, assets, materialStorages, nodePath)
    ) || null

  return {
    ...node,
    text: nodeLabel,
    entityCodesPath: nodePath,
    children,
    ...(isDepartmentOrEquipmentNode(node)
      ? {
          departmentOrEquipmentType: getDepartmentOrEquipmentType(node),
          enabledForPlanningItem: getEnabledForPlanningItem(node, assets, materialStorages)
        }
      : {})
  }
}
