import {useMemo} from 'react'

import {getUnmappedLimsMeasurementTypes, getExtendedMeasurementTypesCategories} from '../utils'

import {useGlobalMeasurementTypesCategoriesQuery} from './queries/useGlobalMeasurementTypesCategoriesQuery'
import {useGlobalMeasurementTypesQuery} from './queries/useGlobalMeasurementTypesQuery'
import {useLimsMeasurementTypesQuery} from './queries/useLimsMeasurementTypesQuery'
import {useMeasurementTypesMappingsQuery} from './queries/useMeasurementTypesMappingsQuery'

export function useExtendedMeasurementTypesCategories() {
  const {data: globalMeasurementTypesCategories} = useGlobalMeasurementTypesCategoriesQuery()
  const {data: globalMeasurementTypes} = useGlobalMeasurementTypesQuery()
  const {data: limsMeasurementTypes} = useLimsMeasurementTypesQuery()
  const {data: measurementTypesMappings} = useMeasurementTypesMappingsQuery()

  const extendedMeasurementTypesCategories = useMemo(
    () =>
      getExtendedMeasurementTypesCategories(
        globalMeasurementTypesCategories,
        globalMeasurementTypes,
        limsMeasurementTypes,
        measurementTypesMappings
      ),
    [
      globalMeasurementTypesCategories,
      globalMeasurementTypes,
      limsMeasurementTypes,
      measurementTypesMappings
    ]
  )

  const unmappedLimsMeasurementTypes = useMemo(
    () => getUnmappedLimsMeasurementTypes(limsMeasurementTypes, measurementTypesMappings),
    [limsMeasurementTypes, measurementTypesMappings]
  )

  const isPageLoading =
    globalMeasurementTypesCategories === undefined ||
    globalMeasurementTypes === undefined ||
    limsMeasurementTypes === undefined ||
    measurementTypesMappings === undefined

  return {
    extendedMeasurementTypesCategories,
    unmappedLimsMeasurementTypes,
    isPageLoading,
    limsMeasurementTypes
  }
}
