import {Asset} from '@hconnect/common'
import {DeleteOutline} from '@mui/icons-material'
import {useCallback} from 'react'
import {useTranslation} from 'react-i18next'

import {DeleteFromPlanningInfo} from '../DeleteFromPlanningInfo'

import {useDeleteAsset} from '@settings/modules/assets'
import {useConfirmDialog} from '@settings/modules/common/providers'
import {Schedule} from '@settings/modules/common/types'
import {useUrlParam} from '@settings/modules/routing'

export const useHandleDeleteFromPlanning = (asset?: Asset, schedule?: Schedule) => {
  const {t} = useTranslation()
  const {openDialog} = useConfirmDialog()
  const plantCode = useUrlParam('plantCode')

  const {mutate: deleteAsset, isLoading: isDeletingAsset} = useDeleteAsset()

  return {
    isDeletingAsset,
    handleDeleteFromPlanning: useCallback(() => {
      if (!asset || !schedule) throw new Error('Delete from planning is not allowed')

      const operationModes = asset.operationModes
      const scheduleItems = Object.values(schedule.schedules).filter(
        (item) => operationModes.find((mode) => mode.id === item.assetOperationModeId) !== undefined
      )

      openDialog({
        title: t('assetsSettings.deleteAsset'),
        mainAction: {
          color: 'error',
          text: t('common.delete'),
          icon: <DeleteOutline />,
          onAction: () => deleteAsset({plantCode, assetId: asset.id})
        },
        additionalContent: (
          <DeleteFromPlanningInfo
            asset={asset}
            operationModes={operationModes}
            scheduleItems={scheduleItems}
          />
        )
      })
    }, [asset, schedule, plantCode, t, openDialog, deleteAsset])
  }
}
