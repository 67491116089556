import {PermissionType} from '@hconnect/apiclient'
import {PlantStructureTabs} from '@hconnect/common/enums'

import {TreeNode, UpmEntityType} from '../../common/types'
import {departmentsWithConfig} from '../consts'
import {
  isDepartmentOrEquipmentNode,
  isAssetEnabledForPlanning,
  isStorageEnabledForPlanning
} from '../helpers'

export const upmNodeToTabsMap: Record<UpmEntityType, PlantStructureTabs[]> = {
  [UpmEntityType.Plant]: [
    PlantStructureTabs.General,
    PlantStructureTabs.Sensors,
    PlantStructureTabs.Kpis,
    PlantStructureTabs.BaseLoad
  ],
  [UpmEntityType.Area]: [PlantStructureTabs.Sensors, PlantStructureTabs.Kpis],
  [UpmEntityType.ProductionLine]: [PlantStructureTabs.Sensors, PlantStructureTabs.Kpis],
  [UpmEntityType.Department]: Object.values(PlantStructureTabs).filter(
    (tab) => tab !== PlantStructureTabs.BaseLoad
  ),
  [UpmEntityType.Equipment]: Object.values(PlantStructureTabs).filter(
    (tab) => tab !== PlantStructureTabs.BaseLoad
  )
}

export const tabToPermissionsMap: Record<PlantStructureTabs, PermissionType[]> = {
  [PlantStructureTabs.General]: [],
  [PlantStructureTabs.Configuration]: ['VIEW_MATERIALS', 'CREATE_MASTER_DATA'],
  [PlantStructureTabs.Layout]: ['CREATE_MASTER_DATA'],
  [PlantStructureTabs.Sensors]: [],
  [PlantStructureTabs.Kpis]: [],
  [PlantStructureTabs.BaseLoad]: [],
  [PlantStructureTabs.OperationModes]: ['VIEW_MACHINE_PLAN'],
  [PlantStructureTabs.StandardTimes]: ['VIEW_MACHINE_PLAN'],
  [PlantStructureTabs.Optimizer]: ['VIEW_MACHINE_PLAN'],
  [PlantStructureTabs.Connection]: ['VIEW_MATERIAL_STORAGE']
}

export const tabToCustomFilterMap: Record<PlantStructureTabs, (selectedItem: TreeNode) => boolean> =
  {
    [PlantStructureTabs.General]: () => true,
    [PlantStructureTabs.Configuration]: (selectedItem) =>
      selectedItem.itemType === UpmEntityType.Department &&
      departmentsWithConfig.some((department) => selectedItem.entityCodesPath === department),
    [PlantStructureTabs.Layout]: (selectedItem) => selectedItem.type?.name === 'Kiln',
    [PlantStructureTabs.Sensors]: () => true,
    [PlantStructureTabs.Kpis]: () => true,
    [PlantStructureTabs.BaseLoad]: () => true,
    [PlantStructureTabs.OperationModes]: (selectedItem) =>
      isDepartmentOrEquipmentNode(selectedItem) &&
      isAssetEnabledForPlanning(selectedItem.enabledForPlanningItem),
    [PlantStructureTabs.StandardTimes]: (selectedItem) =>
      isDepartmentOrEquipmentNode(selectedItem) &&
      isAssetEnabledForPlanning(selectedItem.enabledForPlanningItem),
    [PlantStructureTabs.Optimizer]: (selectedItem) =>
      isDepartmentOrEquipmentNode(selectedItem) &&
      isAssetEnabledForPlanning(selectedItem.enabledForPlanningItem),
    [PlantStructureTabs.Connection]: (selectedItem) =>
      isDepartmentOrEquipmentNode(selectedItem) &&
      isStorageEnabledForPlanning(selectedItem.enabledForPlanningItem)
  }
